



























































import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { BootstrapVueIcons } from 'bootstrap-vue';
import VueElementLoading from 'vue-element-loading';

Vue.use(BootstrapVueIcons);

interface IIncomeRow {
    kat?: number;
    clss?: number;
    pcl?: number;
    spf?: number;
    2022?: number;
    2023?: number;
    2024?: number;
    name_ru?: string;
    visible?: boolean;
    open?: boolean;
    is_leaf?: boolean;
}

@Component({
    components: {
        'loading': VueElementLoading
    }
})

export default class BudgetPreviewIncome extends Vue {
    private budgetIncomeData: IIncomeRow[] = [];
    private isBusy = false;

    public async openVariantData(variant: string) {
        try {
            this.isBusy = true;
            if (variant != '') {
                const response = await fetch('/api-py/get-income-variant/' + variant);
                this.budgetIncomeData = await response.json() as IIncomeRow[];
                for (const row of this.budgetIncomeData) {
                    this.$set(row, 'visible', row.clss == null);
                    this.$set(row, 'open', row.clss != null);
                    this.$set(row, 'is_leaf', row.spf != null);
                }
                this.$set(this.budgetIncomeData[0], 'open', true);
                this.$set(this.budgetIncomeData[0], 'is_leaf', false);
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки данных. Нет данных по варианту', 'Сообщение');
        } finally {
            this.isBusy = false;
        }
    }

    makeToast(variant: string, tostbody: string, title: string) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            autoHideDelay: 4000,
            solid: true
        });
    }

    private openChild(row: any, index: number) {
        this.$set(row, 'open', !row.open);
        const vis_status = this.budgetIncomeData[index].open;
        for (let i = index + 1; i < this.budgetIncomeData.length; i++) {
            if (row.clss == null) {
                if (this.budgetIncomeData[i].kat == row.kat) {
                    this.$set(this.budgetIncomeData[i], 'visible', vis_status);
                }
            }
            if (row.pcl == null) {
                if ((this.budgetIncomeData[i].clss == row.clss) && (this.budgetIncomeData[i].kat == row.kat)){
                    this.$set(this.budgetIncomeData[i], 'visible', vis_status);
                }
            }
            if (row.spf == null) {
                if ((this.budgetIncomeData[i].pcl == row.pcl) && (this.budgetIncomeData[i].clss == row.clss) && (this.budgetIncomeData[i].kat == row.kat)){
                    this.$set(this.budgetIncomeData[i], 'visible', vis_status);
                }
            }
            if (row.kat == null) {
                if (row.open) {
                    if (this.budgetIncomeData[i].clss == null) {
                        this.$set(this.budgetIncomeData[i], 'visible', true);
                        this.budgetIncomeData[i].open = false;
                    } else {
                        this.$set(this.budgetIncomeData[i], 'visible', false);
                    }
                } else {
                    this.$set(this.budgetIncomeData[i], 'visible', false);
                }
            }
        }
    }

    private rowClass(row: any) {
        if (row.kat == null) {
            return 'row-class-all';
        }
        if (row.clss == null) {
            return 'row-class-clss';
        }
        if (row.pcl == null) {
            return 'row-class-pcl';
        }
        if (row.spf != null) {
            return 'row-class-spf';
        }
    }
}


