<template>
  <div class="inner-container">
    <div class="section-title budget">
      <i class="icon icon-calculator-coins"></i
      ><span>Бюджетное планирование</span>
    </div>
    <div class="section-subtitle">
      <i class="icon icon-grid"></i>Формирование бюджета
    </div>
    <b-tabs v-model="tabIndex" v-if="listReg" @input="loadTab">
      <b-tab
          title="Оценка проектов"
          active
          v-if="[1, 2, 3].includes(gradeLevel)"
          @click="tabRef = 'bipgrade'"
      >
        <bip-grade
            ref="bipgrade"
            :location="location"
            :regionBudgetList="listReg"
            :versionList="listVariants"
            :tabIndex="tabIndex"
            @submitReport="submitReport"
        >
        </bip-grade>
      </b-tab>
      <b-tab
          :lazy="!tabs.includes(tabIndex)"
          title="Расходы развития"
          v-if="[1, 2, 3].includes(uebpLevel)"
          @click="tabRef = 'bipuebp'"
      >
        <template v-if="tabs.includes(tabIndex)">
          <bip-uebp
              ref="bipuebp"
              :location="location"
              :regionBudgetList="listReg"
              :versionList="listVariants"
              @chgData="chgData"
              @submitReport="submitReport"
              @submitReport68="submitReport68"
          >
          </bip-uebp>
        </template>
      </b-tab>
      <b-tab
          :lazy="!tabs.includes(tabIndex)"
          title="Текущие расходы"
          v-if="[1, 2, 3].includes(cdfLevel)"
          @click="tabRef = 'costdataform'"
      >
        <template v-if="tabs.includes(tabIndex)">
          <cost-data-form
              ref="costdataform"
              :location="location"
              :list-reg="listReg"
              :region-budget-list="listReg"
              :versionList="listVariants"
              @chgData="chgData"
          ></cost-data-form>
        </template>
      </b-tab>
      <b-tab :lazy="!tabs.includes(tabIndex)" title="Доходы">
        <template v-if="tabs.includes(tabIndex)">
          <income-form
              ref="incomeform"
              :year="parseInt(year)"
              :obl="obl"
              :reg="region"
              :list-reg="listReg"
              :list-variants-inc="listVariantsInc"
          ></income-form>
        </template>
      </b-tab>
      <b-tab
          v-if="false"
          :lazy="!tabs.includes(tabIndex)"
          title="Версия бюджета"
      >
        <budget-variants></budget-variants>
      </b-tab>
      <b-tab title="Заключение по БП">
        <c-budg-program
            :bpMode="bpMode"
            ref="budgProg"
            @setDownloadDisabled="setDownloadDisabled"
            @setBpInEditMode="setBpInEditMode"
        >
        </c-budg-program>
      </b-tab>
    </b-tabs>
    <div class="actions-tab in-tab">
      <b-dropdown
          v-if="[0, 1, 2, 4].includes(tabIndex) && !bpInEditMode"
          variant="link"
          right
          toggle-class="text-decoration-none"
          no-caret
          :disabled="tabIndex === 4 && isDownloadDisabled"
      >
        <template #button-content>
          <div class="btn-download">
            <div>
                            <span class="left">
                                <b-spinner
                                    v-if="isDownloadDisabled"
                                    variant="success"
                                    small
                                    label="Small Spinner"
                                ></b-spinner>
                                <i
                                    v-if="tabIndex === 4 && !isDownloadDisabled"
                                    class="icon icon-download"
                                ></i>
                                Скачать
                            </span>
              <i class="icon icon-keyboard"></i>
            </div>
          </div>
        </template>
        <b-dropdown-item v-if="tabIndex === 0" @click="reportPP"
        >Оценка приоритетности проектов
        </b-dropdown-item
        >
        <template v-if="tabRef === 'bipuebp'">
          <b-dropdown-item @click="downloadZProtocol"
          >Протокол расхождений
          </b-dropdown-item
          >
          <b-dropdown-item @click="reportDB">Проекты</b-dropdown-item>
          <b-dropdown-item @click="report68"
          >Приложение 68
          </b-dropdown-item
          >
        </template>
        <template v-if="tabRef === 'costdataform'">
          <b-dropdown-item @click="downloadCurExpenses"
          >Текущие расходы
          </b-dropdown-item
          >
          <b-dropdown-item @click="downloadStatuses"
          >Статусы согласования
          </b-dropdown-item
          >
          <b-dropdown-item @click="downloadDProtocol"
          >Протокол расхождений
          </b-dropdown-item
          >
        </template>
        <template v-if="tabIndex === 4">
          <b-dropdown-item
              @click="$refs.budgProg.bpReportGeneration(3, true)"
          >Заключение по АБП
          </b-dropdown-item
          >
          <b-dropdown-item
              @click="$refs.budgProg.bpReportGeneration(4, true)"
          >Заключение по АБП без РБ
          </b-dropdown-item
          >
        </template>
      </b-dropdown>
      <b-dropdown
          v-if="[0, 1, 2, 3].includes(tabIndex)"
          variant="link"
          class="info"
          right
          toggle-class="text-decoration-none"
          no-caret
      >
        <template #button-content>
          <div class="btn-download">
            <div>
                            <span class="left">
                                <i class="icon icon-info"></i>
                            </span>
              <i class="icon icon-keyboard"></i>
            </div>
          </div>
        </template>
        <b-dropdown-text>
          <div class="info-button">
                        <span v-if="tabIndex === 0" @click="infoCostForm(20)"
                        >Инструкция<i class="icon icon-folder"></i
                        ></span>
            <span v-if="tabIndex === 0" @click="infoCostForm(21)"
            >Методика оценки<i class="icon icon-folder"></i
            ></span>
            <span v-if="tabIndex === 1" @click="infoCostForm(24)"
            >Инструкция<i class="icon icon-folder"></i
            ></span>
            <span v-if="tabIndex === 2" @click="infoCostForm(1)"
            >Инструкция<i class="icon icon-folder"></i
            ></span>
            <span v-if="tabIndex === 3" @click="infoCostForm(2)"
            >Режим «Формирование бюджета»
                            <i class="icon icon-folder"></i
                            ></span>
          </div>
          <!-- <div class="info-button">
              <span>Видеоинструкция <i class="icon icon-video"></i></span>
          </div> -->
          <div class="info-text" v-if="tabIndex === 0">
            <p>
                            <span class="red bold"
                            >Раздел «Оценка роектов»</span
                            >
            </p>
            <p>
              Данный раздел разработан как инструмент анализа,
              помогающий принять правильное решение при
              согласовании инвестиционных проектов сотрудниками
              уполномоченных органов по бюджетному планированию с
              целью обеспечения эффективного распределения
              бюджетных средств на этапе планирования.
            </p>
            <p>
              В рамках данного раздела была разработана Методика
              оценки приоритетности проектов, которая стала
              основой для разработки алгоритмов расчета Оценки в
              Системе.
            </p>
          </div>
          <div class="info-text" v-if="tabIndex === 1">
            <p>
                            <span class="red bold"
                            >Раздел «Расходы развития»</span
                            >
            </p>
            <p>
              Данный раздел разработан как инструмент для
              рассмотрения и согласования бюджетных заявок
              Уполномоченным органом по бюджетному планированию в
              части финансирования расходов развития на этапе
              планирования.
            </p>
          </div>
          <div class="info-text" v-if="tabIndex === 2">
            <p>
                            <span class="red bold"
                            >Раздел «Текущие расходы»</span
                            >
            </p>
            <p>
              Данный раздел предназначен для рассмотрения и
              согласования бюджетных заявок уполномоченным органом
              по бюджетному планированию в части финансирования
              текущих расходов на этапе планирования.
            </p>
          </div>
          <div class="info-text" v-if="tabIndex === 3">
            <p>
              Данный режим предназначен для ввода, корректировки и
              просмотра данных по бюджету на очередной плановый
              период.
            </p>
            <p>
              Режим содержит данные до уровня бюджетных
              программ/подпрограмм по расходной части и специфик
              по поступлениям.
            </p>
            <p>Режим состоит из следующих разделов:</p>
            <p>
              <span class="red bold">Расход</span><br/>
              – формирование расходной части бюджета
            </p>
            <p>
              <span class="red bold">Доход</span><br/>
              – формирование доходной части бюджета
            </p>
            <p>
              <span class="red bold">Баланс</span><br/>
              – отображение данных по структуре бюджета
            </p>
          </div>
        </b-dropdown-text>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import store from "../../services/store";
import {Ax} from "@/utils";
import IncomeForm from "@/modules/budget/IncomeForm";
import CostDataForm from "@/modules/budget/CostDataForm";
import BipGrade from "@/modules/budget/bip/bip-grade.vue";
import BipUebp from "@/modules/budget/bip/bip-uebp.vue";
import BudgetVariants from "@/modules/budget/BudgetVariants";
import CBudgProgram from "@/modules/budget-request/budget-program.vue";
import {CUR_YEAR} from "../budget-request/components/js/budgetCurYear";
import {loadVariants} from "@/modules/budget/budgetVariantService";
import {makeToast} from "./bip/bip-types";

export default {
  name: "BudgetForm",
  components: {
    BipGrade,
    BipUebp,
    IncomeForm,
    CostDataForm,
    BudgetVariants,
    CBudgProgram,
  },
  $refs: {
    bipgrade: HTMLFormElement,
    bipuebp: HTMLFormElement,
  },
  data() {
    return {
      location: null,
      year: null,
      obl: null, // '45'
      region: null, // '450101',
      variant: "f1340fa7-e90d-42a2-92ae-a6055dc1d278",
      listReg: [],
      progress: 0,
      dataType: 1,
      selectedABP: {id: 0, child: []},
      selectedPRG: {id: 0, child: []},
      selectedPPR: {id: 0},
      tabIndex: 0,
      tabs: [],
      tabRef: [],
      repParams: null,
      repParams68: null,
      listVariants: [],
      bpMode: true,
      listVariantsInc: [],
      isDownloadDisabled: false,
      bpInEditMode: false,

      userUiid: null,
      gradeLevel: 0,
      uebpLevel: 0,
      cdfLevel: 0,
    };
  },

  async mounted() {
    this.year = CUR_YEAR;
    this.userUiid = store.state.user.sub;
    await this.getLocation();
    await this.getObl();
    await this.loadRegions();
    await this.loadUserLevels();
  },

  methods: {
    async getLocation() {
      const code = store.state._instanceCode;
      try {
        await fetch("/api-py/get-budget-obl/" + code)
            .then((response) => response.json())
            .then((json) => {
              this.location = json;
              console.log("location", this.location);
            });
      } catch (error) {
        this.makeToast(
            "danger",
            "Ошибка запроса getLocation",
            error.toString()
        );
      }
    }, // запрос локации по глобальному коду области

    async getObl() {
      try {
        await fetch(
            "/api-py/get-budget-obl/" + store.state._instanceCode
        )
            .then((response) => response.json())
            .then((json) => {
              this.obl = json.obl;
              this.region = json.region;
            });
        await loadVariants().then((data) => {
          this.listVariantsInc = data;
          this.listVariants = data.map((v) => {
            const str = v.status
                ? "утв" + (v.attribute ? ", акт" : "")
                : v.attribute
                    ? "акт"
                    : "";
            v.text =
                v.name_ru +
                (str.length > 0 ? " (" + str + ")" : "");
            return v;
          });
        });
      } catch (error) {
        this.makeToast(
            "danger",
            "Ошибка запроса getObl",
            error.toString()
        );
      }
    },

    async loadRegions() {
      try {
        const response = await fetch(
            "/api-py/get-user-regions-by-obl/" +
            this.obl +
            "/" +
            store.state.user.sub
        );
        this.listReg = await response.json();
      } catch (error) {
        this.makeToast(
            "danger",
            "Ошибка запроса регионов",
            error.toString()
        );
      }
    }, // справочник регионов

    async chgData(data) {
      try {
        const curHeader = data;
        let abp = 0;
        if (curHeader.variant == null) {
          curHeader.variant = this.variant;
        }
        if (
            curHeader !== undefined &&
            curHeader !== null &&
            curHeader.selABP !== undefined &&
            curHeader.selABP !== null
        ) {
          abp = curHeader.selABP.abp;
        }
        this.headers = {
          year: curHeader.year,
          dataType: curHeader.dataType,
          region: curHeader.region,
          version: curHeader.version,
          variant: curHeader.variant,
          view: curHeader.view,
          develop: curHeader.develop,
          abp: abp,
        };
      } catch (error) {
        this.makeToast(
            "danger",
            "Предупреждение",
            "Ошибка обновления параметров " + error.toString()
        );
      }
    }, // обновление параметров

    infoCostForm(info_id) {
      const that = this;
      Ax(
          {
            url: "/api-py/get-info/" + info_id, //  Тело файла
            method: "POST",
            data: null,
            responseType: "blob",
          },
          (data) => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;
            let file = "";
            if (info_id == 1) {
              file = "РП Формирование расходной части";
            }
            if (info_id == 2) {
              file = "РП Формирование доходной части";
            }
            if (info_id == 20) {
              file = "Инструкция Оценки";
            }
            if (info_id == 21) {
              file = "Методика Оценки";
            }
            if (info_id == 24) {
              file = "Инструкция Расходы развития";
            }
            link.setAttribute("download", file + ".pdf"); // or any other extension
            document.body.appendChild(link);
            link.click();
          },
          (error) => {
            that.error = error;
          }
      );
    },
    // формирование отчета
    downloadAgrExcel() {
      const that = this;
      Ax(
          {
            url:
                "/api-py/agr-form-download/" +
                that.region +
                "/" +
                that.variant +
                "/" +
                that.year +
                "/" +
                that.selectedABP.id +
                "/" +
                that.selectedPRG.id, //  Тело файла
            method: "POST",
            data: that.ajxParams,
            responseType: "blob",
          },
          (data) => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
                "download",
                "Бюджет в агрегированной форме.xls"
            ); // or any other extension
            document.body.appendChild(link);
            link.click();
          },
          (error) => {
            that.error = error;
          }
      );
    },
    // формирование отчета
    downloadExcel() {
      const that = this;
      Ax(
          {
            url:
                "/api-py/summary-form-download/" +
                that.region +
                "/" +
                that.variant +
                "/" +
                that.year +
                "/" +
                that.selectedABP.id +
                "/" +
                that.selectedPRG.id, //  Тело файла
            method: "POST",
            data: that.ajxParams,
            responseType: "blob",
          },
          (data) => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
                "download",
                "Бюджет на предстоящий период.xls"
            ); // or any other extension
            document.body.appendChild(link);
            link.click();
          },
          (error) => {
            that.error = error;
          }
      );
    },
    // формирование отчета
    downloadVedmExcel() {
      const that = this;
      Ax(
          {
            url:
                "/api-py/ved-form-download/" +
                that.region +
                "/" +
                that.variant +
                "/" +
                that.year +
                "/" +
                that.selectedABP.id +
                "/" +
                that.selectedPRG.id, //  Тело файла
            method: "POST",
            data: that.ajxParams,
            responseType: "blob",
          },
          (data) => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
                "download",
                "Бюджет по ведомственной классиф.xls"
            ); // or any other extension
            document.body.appendChild(link);
            link.click();
          },
          (error) => {
            that.error = error;
          }
      );
    },
    // формирование протокола расхождения (текущие расходы)
    async downloadDProtocol() {
      const that = this;
      this.progress = 5;
      Ax(
          {
            url: "/api-py/dp_count/" + JSON.stringify(that.headers),
            method: "GET",
          },
          (data) => {
            if (data > 0) {
              Ax(
                  {
                    url:
                        "/api-py/dprotocol-download/" +
                        JSON.stringify(that.headers), //  Тело файла
                    method: "POST",
                    data: that.ajxParams,
                    responseType: "blob",
                  },
                  (data) => {
                    this.progress = 25;
                    const url = window.URL.createObjectURL(
                        new Blob([data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                        "download",
                        "Протокол расхождений (текущие расходы).xls"
                    ); // or any other extension
                    document.body.appendChild(link);
                    link.click();
                    this.progress = 100;
                  },
                  (error) => {
                    that.error = error;
                    this.progress = 0;
                  }
              );
            } else {
              this.makeToast(
                  "warning",
                  "Сообщение",
                  "Расхождений между версиями АБП и УЭБП не обнаружено"
              );
            }
          },
          (error) => {
            this.error = error;
            this.progress = 0;
          }
      );
    },
    // формирование протокола расхождения (расходы развития)
    async downloadZProtocol() {
      const that = this;
      this.progress = 5;
      Ax(
          {
            url: "/api-py/zp_count/" + JSON.stringify(that.headers),
            method: "GET",
          },
          (data) => {
            if (data > 0) {
              Ax(
                  {
                    url:
                        "/api-py/zprotocol-download/" +
                        JSON.stringify(that.headers), //  Тело файла
                    method: "POST",
                    data: that.ajxParams,
                    responseType: "blob",
                  },
                  (data) => {
                    this.progress = 25;
                    const url = window.URL.createObjectURL(
                        new Blob([data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                        "download",
                        "Протокол расхождений (расходы развития).xls"
                    ); // or any other extension
                    document.body.appendChild(link);
                    link.click();
                    this.progress = 100;
                  },
                  (error) => {
                    that.error = error;
                    this.progress = 0;
                  }
              );
            } else {
              this.makeToast(
                  "warning",
                  "Сообщение",
                  "Расхождений между версиями АБП и УЭБП не обнаружено"
              );
            }
          },
          (error) => {
            this.error = error;
            this.progress = 0;
          }
      );
    },
    // формирование отчета (текущие расходы)
    async downloadCurExpenses() {
      const that = this;
      this.progress = 5;
      Ax(
          {
            url:
                "/api-py/curr-expenses-download/" +
                JSON.stringify(that.headers), //  Тело файла
            method: "POST",
            data: that.ajxParams,
            responseType: "blob",
          },
          (data) => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
                "download",
                "Текущие расходы_" +
                that.headers.year +
                "-" +
                parseInt(parseInt(that.headers.year) + 2) +
                ".xls"
            ); // or any other extension
            document.body.appendChild(link);
            link.click();
          },
          (error) => {
            that.error = error;
          }
      );
    },
    // формирование отчета (статусы согласования)
    async downloadStatuses() {
      const that = this;
      console.log("headers", that.headers);
      Ax(
          {
            url:
                "/api-py/report-status/" + JSON.stringify(that.headers), //  Тело файла
            method: "POST",
            data: "",
            responseType: "blob",
          },
          (data) => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
                "download",
                "Статусы согласования_" +
                that.headers.year +
                "-" +
                (parseInt(that.headers.year) + 2) +
                ".xls"
            );
            document.body.appendChild(link);
            link.click();
          },
          (error) => {
            that.error = error;
          }
      );
    },

    makeToast(variant, title, tostbody) {
      this.$bvToast.toast(tostbody, {
        title: title,
        variant: variant,
        toaster: "b-toaster-top-center",
        autoHideDelay: 5000,
        appendToast: true,
      });
    }, // сообщение

    loadTab() {
      if (!this.tabs.includes(this.tabIndex)) {
        this.tabs.push(this.tabIndex);
      }
      switch (this.tabIndex) {
        case 0:
          this.$refs.bipgrade.submitReport();
          break;
        case 1:
          if (this.$refs.bipuebp) {
            this.$refs.bipuebp.submitReport();
            this.$refs.bipuebp.submitReport68();
          }
          break;
        default:
          break;
      }
    }, // загрузка при открытии вкладки

    async loadUserLevels() {
      let response = [];
      if (this.userUiid !== null && this.userUiid.length > 0) {
        try {
          response = await fetch(
              "/api-py/user-modules/" + this.userUiid
          );
          response = await response.json();
          for (const row of response) {
            if (row.modules === "004.003.001") {
              this.gradeLevel = row.access_level;
              break;
            }
          }
        } catch (error) {
          makeToast(
              this,
              "danger",
              "Ошибка loadUserLevels",
              error.toString()
          );
        }
        try {
          response = await fetch(
              "/api-py/user-modules/" + this.userUiid
          );
          response = await response.json();
          for (const row of response) {
            if (row.modules === "004.003.002") {
              this.uebpLevel = row.access_level;
              break;
            }
          }
        } catch (error) {
          makeToast(
              this,
              "danger",
              "Ошибка loadUserLevels",
              error.toString()
          );
        }
        try {
          response = await fetch(
              "/api-py/user-modules/" + this.userUiid
          );
          response = await response.json();
          for (const row of response) {
            if (row.modules === "004.003.003") {
              this.cdfLevel = row.access_level;
              break;
            }
          }
        } catch (error) {
          makeToast(
              this,
              "danger",
              "Ошибка loadUserLevels",
              error.toString()
          );
        }
      }
    },

    report68() {
      if (
          this.repParams68.abp === null ||
          this.repParams68.prg === null
      ) {
        this.$bvModal
            .msgBoxOk("Необходимо выбрать АБП и Программу!", {
              title: "Подтверждение",
              size: "md",
              buttonSize: "sm",
              okVariant: "primary",
              okTitle: "OK",
              footerClass: "p-2",
              hideHeaderClose: false,
              centered: true,
            })
            .then((value) => {
              return;
            })
            .catch((error) => {
              this.makeToast(
                  "danger",
                  "Ошибка проверки фильтра",
                  error.toString()
              );
            });
      } else {
        if (
            this.repParams68.signers.length === 1 &&
            this.repParams68.signers[0].id_user.length === 0
        ) {
          this.makeToast(
              "warning",
              "Внимание",
              "Отсутствуют Подписант(ы) по БИПам"
          );
        }
        Ax(
            {
              url: "/api-py/bip-application-68/",
              method: "POST",
              data: this.repParams68,
              responseType: "blob",
            },
            (data) => {
              if (data) {
                const url = window.URL.createObjectURL(
                    new Blob([data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                    "download",
                    "Приложение68_" +
                    this.repParams68.abp.abp +
                    this.repParams68.prg.prg +
                    "_" +
                    this.repParams68.dataType +
                    "_" +
                    this.repParams68.year +
                    ".xls"
                );
                document.body.appendChild(link);
                link.click();
              }
            },
            (error) => {
              this.makeToast(
                  "danger",
                  "Ошибка запроса report68()",
                  error.toString()
              );
            }
        );
      }
    },

    reportDB() {
      Ax(
          {
            url: "/api-py/bip-application-db/",
            method: "POST",
            data: this.repParams,
            responseType: "blob",
          },
          (data) => {
            if (data) {
              const url = window.URL.createObjectURL(
                  new Blob([data])
              );
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute(
                  "download",
                  "Расходы развития " +
                  this.repParams.planPeriod.name +
                  ".xls"
              );
              document.body.appendChild(link);
              link.click();
            }
          },
          (error) => {
            this.makeToast(
                "danger",
                "Ошибка запроса reportDB()",
                error.toString()
            );
          }
      );
    },

    reportPP() {
      console.log("reportPP", this.repParams);
      Ax(
          {
            url: "/api-py/bip-application-pp/",
            method: "POST",
            data: this.repParams,
            responseType: "blob",
          },
          (data) => {
            if (data) {
              const url = window.URL.createObjectURL(
                  new Blob([data])
              );
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute(
                  "download",
                  "Оценка приоритетности проектов_" +
                  this.repParams.year +
                  ".xls"
              );
              document.body.appendChild(link);
              link.click();
            }
          },
          (error) => {
            this.makeToast(
                "danger",
                "Ошибка запроса reportPP()",
                error.toString()
            );
          }
      );
    },

    submitReport(data) {
      this.repParams = data;
    },

    submitReport68(data) {
      this.repParams68 = data;
    },

    setDownloadDisabled(val) {
      this.isDownloadDisabled = val;
    },

    setBpInEditMode(val) {
      console.log("VAL", val);
      this.bpInEditMode = val;
    },
  },
};
</script>
<style>
.is-hidden {
  display: none !important;
}
</style>
