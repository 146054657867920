






































































































































































































































































































































































































































































































































































































































































































































































































































import CBudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import { setNameLang } from "@/modules/budget-request/components/js/some-functions";
import BudgetHeaderBip from '@/modules/budget/bip/budget-header-bip.vue';
import FileLoad from '@/modules/budget/bip/file-load.vue';
import { Ax } from "@/utils";
import 'bootstrap-vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { DateTimeFormatOptions } from "vue-i18n";
import Multiselect from 'vue-multiselect';
import { Prop } from "vue-property-decorator";
import DatePicker from "vue2-datepicker";
import {
BipProjectDataClass,
CriteriaIndicators,
DataType,
RowNewFinanceData,
RowOldFinanceData,
findItem, getNumber,
getRowKey,
makeToast,
sortByField
} from './bip-types';

// декоратор @Component указывает, что класс — это компонент Vue
@Component({
    name: 'bip-card',
    components: {
        DatePicker,
        'multiselect': Multiselect,
        'c-budg-form-lst': CBudgetFormsList,
        BudgetHeaderBip,
        FileLoad
    }
})


export default class BipForm extends Vue {
    $refs!: {
        budgetHeader: HTMLFormElement;
        bipForm: HTMLFormElement;
        refHistModal: HTMLFormElement;
    };
    @Prop({
        required: false,
        default: false
    })
    private showForm: boolean | undefined;

    @Prop({
        required: false,
        default: false
    })
    private backList: boolean | undefined;

    @Prop({
        required: false,
        default: false
    })
    private saving: boolean | undefined;

    @Prop({
        required: false,
        default: false
    })
    private updateMark: boolean | undefined;

    @Prop({
        required: false,
        default: true
    })
    private reading: boolean | undefined;

    @Prop({
        required: false,
        default: false
    })
    private editSection4: boolean | undefined;

    @Prop({
        required: true,
        default: {}
    })
    private location: any;

    @Prop({
        required: true,
        default: []
    })
    private regionList: any | undefined;

    @Prop({
        required: false,
        default: () => {
            return []
        }
    })
    private prevBipList: any[] | undefined;

    @Prop({
        required: false,
        default: () => {
            return []
        }
    })
    private dataTypeList: any[] | undefined;

    @Prop({
        required: true,
        default: undefined
    })
    private bip: BipProjectDataClass | undefined;

    @Prop({
        required: false,
        default: null
    })
    private filterBox: any | null;

    @Prop({
        required: false,
        default: null
    })
    private selData: any | null;

    private agrObj: any = null;

    private bipYear = 0;
    private begYear = 0;
    private endYear = 0;

    private bipProjectData: BipProjectDataClass | null = null;
    private selectedBip: BipProjectDataClass | null = null;
    private selDirection: any = {};
    private selObject: any = {};
    private selObjectType: string | undefined = '';

    private listYear = [
        { value: 2010, text: '2010' },
        { value: 2011, text: '2011' },
        { value: 2012, text: '2012' },
        { value: 2013, text: '2013' },
        { value: 2014, text: '2014' },
        { value: 2015, text: '2015' },
        { value: 2016, text: '2016' },
        { value: 2017, text: '2017' },
        { value: 2018, text: '2018' },
        { value: 2019, text: '2019' },
        { value: 2020, text: '2020' },
        { value: 2021, text: '2021' },
        { value: 2022, text: '2022' },
        { value: 2023, text: '2023' },
        { value: 2024, text: '2024' },
        { value: 2025, text: '2025' },
        { value: 2026, text: '2026' },
        { value: 2027, text: '2027' },
        { value: 2028, text: '2028' },
        { value: 2029, text: '2029' },
        { value: 2030, text: '2030' },
        { value: 2031, text: '2031' }
    ];
    private prjList = [
        { value: 0, text: 'Переходящие проекты' },
        { value: 1, text: 'Новые инициативы' }
    ];
    private journalFields: any [] = [
        {
            label: 'Дата',
            key: 'updateDate'
        },
        {
            label: 'Пользователь',
            key: 'userName'
        },
        {
            label: 'Статус',
            key: 'name'
        },
        {
            label: 'Комментарии',
            key: 'commentTxt'
        }
    ];
    private oldFinaceFields: any [] = [
        {
            label: 'Программа',
            key: 'prg'
        },
        {
            label: 'Подпрограмма',
            key: 'ppr'
        },
        {
            label: 'Специфика',
            key: 'spf'
        },
        {
            label: 'Год',
            key: 'year'
        },
        {
            label: 'План',
            key: 'plan'
        },
        {
            label: 'Подрядчик',
            key: 'podrad'
        },
        {
            label: 'Дата заключения договора',
            key: 'date'
        },
        {
            label: 'Общая стоимость (отчёт)',
            key: 'totalCoast'
        },
        {
            label: 'СМР',
            key: 'smp'
        },
        {
            label: 'Авторский контроль',
            key: 'control'
        },
        {
            label: 'Технический надзор',
            key: 'nadzor'
        },
        {
            label: '...',
            key: 'more'
        }
    ];
    private criteriaFields: any [] = [
        {
            label: 'Код',
            key: 'code'
        },
        {
            label: 'Наименование',
            key: 'name_ru'
        },
        {
            label: 'Значение',
            key: 'value'
        },
        {
            label: 'Вес',
            key: 'weight'
        },
        {
            label: 'Max',
            key: 'max'
        },
        {
            label: 'Min',
            key: 'min'
        },
        {
            label: 'Балл',
            key: 'calc'
        }
    ];
    private radioOptions: any [] = [
        { text: '-1', value: -1 },
        { text: '0', value: 0 },
        { text: '1', value: 1 }
    ];
    private calcFlds: any [] = [
        'value',
        'weight',
        'max',
        'min'
    ];

    private histBase: any[] = [];

    private showPrograms = false

    public async created() {
        document.addEventListener('click', this.closeOpenMore, true)
        this.loadDirectionList();
        this.loadGpList();
        this.loadCifList();

        this.$watch('showForm', (value) => {
            if (value && this.bip) {
                this.openBip();
            }
        });
        this.$watch('backList', (value) => {
            if (value) {
                this.updateBip();
            }
        });
        this.$watch('saving', (value) => {
            if (value) {
                this.updateBip();
            } else {
                if (this.bipProjectData) {
                    // this.bip = this.bipProjectData;
                    this.$set(this, 'bip', this.bipProjectData);
                }
            }
        });
        this.$watch('updateMark', (value) => {
            if (value) {
                this.updateCriterias();
            }
        });
    }

    public beforeUnmount() {
        document.removeEventListener('click', this.closeOpenMore)
    }

    private closeOpenMore() {
        this.bipProjectData && this.bipProjectData.newFinace.forEach(el => el.openMore = false)
    }

    private get bipBall(): number {
        let res = 0;
        if (this.bipProjectData) {
            if (this.bipProjectData.calcResult) {
                for (const [key, value] of Object.entries(this.bipProjectData.calcResult)) {
                    if (key !== 'totalResult') {
                        if (value) {
                            if (typeof (value) === 'string') {
                                res = res + parseFloat(value);
                            }
                            if (typeof (value) === 'number') {
                                res = res + value;
                            }
                        }
                    }
                }
            }
        }
        if (this.bipProjectData) {
            if (this.bipProjectData.calcResult) {
                if (this.bipProjectData.calcResult['dateStateExamination-result'] === 0) {
                    res = 0;
                }
            }
        }
        return Math.round(res * 1000) / 1000;
    }

    private get calcIndicators() {
        const result = this.bipLinkCriterias.filter(row =>
            parseInt(row.begin_year) <= this.bipYear
            && (row.end_year === null || (row.end_year !== null && parseInt(row.end_year) >= this.bipYear))
            && row.project_type === this.bipProjectData?.projectType.toString()
            && row.object_type === this.bipProjectData?.object.toString()
            && (this.bipProjectData
                && this.bipProjectData.localityObject
                && this.bipProjectData.localityObject?.type_of_settlement !== null
                && row.place === parseInt(this.bipProjectData.localityObject?.type_of_settlement))
        );
        result.sort(sortByField('code'));

        return result;
    }

    private get curGpList(): any [] {
        const result = this.gpList.filter(row => row.code_kato !== null
            && (row.code_kato.startsWith('00') || row.code_kato.startsWith(this.location.code))
            && new Date(row.start_date).getFullYear() <= this.bipYear
            && new Date(row.end_date).getFullYear() >= this.bipYear);

        result.sort((a: any, b: any) => a.name_ru > b.name_ru ? 1 : -1);
        return result;
    }

    private get enterIndicators() {
        const result = this.bipLinkCriterias.filter(row =>
            parseInt(row.begin_year) <= this.bipYear
            && (row.end_year === null || (row.end_year !== null && parseInt(row.end_year) >= this.bipYear))
            && row.source_type === 'W'
            && row.operator === 'АБП'
            && row.project_type === this.bipProjectData?.projectType.toString()
            && row.object_type === this.bipProjectData?.object.toString()
            && (this.bipProjectData
                && this.bipProjectData.localityObject
                && this.bipProjectData.localityObject?.type_of_settlement !== null
                && row.place === parseInt(this.bipProjectData.localityObject?.type_of_settlement))
        );
        result.sort(sortByField('code'));

        return result;
    }

    private get finaceFields(): any [] {
        const res = [
            {
                label: 'Программа',
                key: 'prg'
            },
            {
                label: 'Подпрограмма',
                key: 'ppr'
            },
            {
                label: 'Специфика',
                key: 'spf'
            }
        ];
        if (this.bipProjectData) {
            for (let i = this.bipProjectData.period.begYear; i <= this.bipProjectData.period.endYear; i++) {
                res.push({
                    label: i.toString(),
                    key: i.toString()
                });
            }
            res.push(
                {
                    label: '...',
                    key: 'more'
                }
            );
        }
        const fields: any [] = [];
        Object.assign(fields, res);
        return res;
    }

    private get histLst() {
        const result: any[] = [];
        if (!this.agrObj) { return []; }
        const options: DateTimeFormatOptions = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        }
        for (const el of this.histBase) {
            const tmp = Object.assign({}, el);
            tmp.updateDate = new Date(tmp.updateDate).toLocaleDateString('ru-RU', options);
            result.push(setNameLang(this.$i18n.locale, tmp));
        }
        if (this.agrObj.userId) {
            const tmp = Object.assign(this.agrObj.status_lang, this.agrObj);
            tmp.updateDate = new Date(tmp.updateDate).toLocaleDateString('ru-RU', options);
            result.push(setNameLang(this.$i18n.locale, tmp));
        }
        return result;
    }

    private async loadBipHist(id: number) {
        try {
            const response: any = await fetch(`/api-py/get-bip-agree-hist/${id}`);
            const result = await response.json();
            await this.setUserNames(result);
            this.histBase = result;
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка loadBipHist', (error as Error).toString());
        }
    }

    private get oldYearList() {
        let res: any [] = [];
        if (this.bipProjectData) {
            res = this.listYear.filter(year => year.value < parseInt(this.bipProjectData?.header.year));
        }
        return res;
    }

    private addNewFin(finalAbp: number) {
        if (this.bipProjectData?.period) {
            const row = new RowNewFinanceData(this.bipProjectData?.period);
            if (finalAbp === 1) {
                if (this.editSection4) {
                    this.bipProjectData.newFinace.push(row);
                }
            } else {
                if (!this.reading) {
                    if (!this.bipProjectData.newFinaceRegional) {
                        this.$set(this.bipProjectData, 'newFinaceRegional', []);
                    }
                    this.bipProjectData.newFinaceRegional.push(row);
                }
            }
        }
    }

    private addOldFinace() {
        if (this.bipProjectData && !this.reading) {
            const row = new RowOldFinanceData(null, null, null, null, null, null, this.bipYear, 0);
            console.log(`Row: ${row}`);
            this.bipProjectData.oldFinace.push(row);
        }
    }

    private changeBipYear() {
        if (this.bipProjectData) {
            this.$set(this.bipProjectData.header, 'year', this.bipYear);
        }
    }

    private changeCode() {
        if (this.selectedBip) {
            this.bipProjectData = BipProjectDataClass.transit(this.filterBox, this.selectedBip);
            this.begYear = this.bipProjectData.period.begYear;
            this.endYear = this.bipProjectData.period.endYear;
            this.selDirection = findItem(this.bipProjectData?.projectType, 'code', this.directionList);
            this.selObjectType = this.bipProjectData.object;
            this.getCurKatoRegion();

            if (this.selectedBip.files && this.selectedBip.files.length > 0) {
                this.transitFiles(this.selectedBip.header.year, this.selectedBip.code, this.bipYear.toString());
            }
        }
    } // при выборе кода БИПа с переходящих проектов

    private curHeader: any;
    private async changeHeader(data: any) {
        this.curHeader = data;
        if (this.curHeader && this.bipProjectData) {
            this.setHeader(this.bipProjectData);
        }
        await this.loadPrg();
        await this.loadRegPrg();
        await this.loadSpf();

    }// фиксирует изменение параметров шапки

    private changeObjectType() {
        if (this.bipProjectData) {
            if (this.selObjectType !== this.bipProjectData.object
                && ['29'].includes(this.bipProjectData.object)
                && this.bipProjectData.kazPercent > 0) {
                this.$bvModal.msgBoxConfirm(
                    'Данные по Объему планируемого казахстанского содержания будут удалены',
                    {
                        title: 'Подтверждение',
                        size: 'md',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'OK',
                        cancelTitle: 'Отмена',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true,
                        modalClass: 'del-item-modal-script'
                    })
                    .then(value => {
                        if (value) {
                            if (this.bipProjectData) {
                                delete this.bipProjectData?.kazPercent;
                                delete this.bipProjectData['_kazPercent'];
                                this.$set(this.bipProjectData, '_kazPercent', 0);
                                this.selObjectType = this.bipProjectData.object;
                            }
                        } else {
                            if (this.bipProjectData) {
                                this.$set(this.bipProjectData, 'object', this.selObject);
                            }
                        }
                    })
                    .catch(error => {
                        makeToast(this, 'danger', 'Ошибка проверки типа объекта', error.toString());
                    });
            }

            if (this.selObject.code !== this.bipProjectData.object
                && ['29'].includes(this.bipProjectData.object)
                && ((this.bipProjectData.numStateExpertise !== undefined
                    && this.bipProjectData.numStateExpertise?.length > 0)
                || this.bipProjectData.dateStateExamination !== null)) {
                this.$bvModal.msgBoxConfirm(
                    'Дата и номер гос.экспетизы будут удалены',
                    {
                        title: 'Подтверждение',
                        size: 'md',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'OK',
                        cancelTitle: 'Отмена',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true,
                        modalClass: 'del-item-modal-script'
                    })
                    .then(value => {
                        if (value) {
                            if (this.bipProjectData) {
                                this.$set(this.bipProjectData, 'numStateExpertise', '');
                                this.$set(this.bipProjectData, '_dateStateExamination', null);
                                this.$set(this.bipProjectData, 'dateStateExamination', null);
                                this.selObjectType = this.bipProjectData.object;
                            }
                        } else {
                            if (this.bipProjectData) {
                                this.$set(this.bipProjectData, 'object', this.selObject);
                            }
                        }
                    })
                    .catch(error => {
                        makeToast(this, 'danger', 'Ошибка проверки типа объекта', error.toString());
                    });
            }
        }
    }

    private changePeriod() {
        if (this.bipProjectData) {
            this.$set(this.bipProjectData.period, '_begYear', this.begYear);
            this.$set(this.bipProjectData.period, '_endYear', this.endYear);
            this.begYear = this.bipProjectData.period.begYear;
            this.endYear = this.bipProjectData.period.endYear;
        }
    }

    private changeProjectType() {
        if (this.bipProjectData) {
            if (this.selDirection.code !== this.bipProjectData.projectType
                && ['1', '2', '5', '6'].includes(this.selDirection.code)
                && ((this.bipProjectData.numStateExpertise !== undefined
                    && this.bipProjectData.numStateExpertise?.length > 0)
                || (this.bipProjectData.dateStateExamination !== null))) {
                this.$bvModal.msgBoxConfirm(
                    'Дата и номер гос.экспетизы будут удалены',
                    {
                        title: 'Подтверждение',
                        size: 'md',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'OK',
                        cancelTitle: 'Отмена',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true,
                        modalClass: 'del-item-modal-script'
                    })
                    .then(value => {
                        if (value) {
                            if (this.bipProjectData) {
                                this.$set(this.bipProjectData, 'numStateExpertise', '');
                                this.$set(this.bipProjectData, '_dateStateExamination', null);
                                this.$set(this.bipProjectData, 'dateStateExamination', null);
                                this.$set(this.bipProjectData, 'projectType', this.selDirection.code);
                            }
                        } else {
                            if (this.bipProjectData) {
                                this.selDirection = findItem(this.bipProjectData?.projectType, 'code', this.directionList);
                            }
                        }
                    })
                    .catch(error => {
                        makeToast(this, 'danger', 'Ошибка проверки направления проекта', error.toString());
                    });
            }

            if (this.selDirection.code !== this.bipProjectData.projectType
                && ['1', '2'].includes(this.selDirection.code)
                && this.bipProjectData.kazPercent > 0) {
                this.$bvModal.msgBoxConfirm(
                    'Данные по Объему планируемого казахстанского содержания будут удалены',
                    {
                        title: 'Подтверждение',
                        size: 'md',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'OK',
                        cancelTitle: 'Отмена',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true,
                        modalClass: 'del-item-modal-script'
                    })
                    .then(value => {
                        if (value) {
                            if (this.bipProjectData) {
                                delete this.bipProjectData?.kazPercent;
                                delete this.bipProjectData['_kazPercent'];
                                this.$set(this.bipProjectData, 'kazPercent', 0);
                                this.$set(this.bipProjectData, 'projectType', this.selDirection.code);
                            }
                        } else {
                            if (this.bipProjectData) {
                                this.selDirection = findItem(this.bipProjectData?.projectType, 'code', this.directionList);
                            }
                        }
                    })
                    .catch(error => {
                        makeToast(this, 'danger', 'Ошибка проверки', error.toString());
                    });
            }
            this.$set(this.bipProjectData, 'projectType', this.selDirection.code);
        }
    }

    private changeRegion() {
        if (this.bipProjectData) {
            this.bipProjectData.region = this.curKatoRegion.code;
            this.bipProjectData.localityObject = null;
        }
    }

    private changeConsideration() {
        if (this.bipProjectData
            && !this.bipProjectData.isConsiderationCentralGovernment
            && this.bipProjectData?.considerationGA > 0) {
            this.$bvModal.msgBoxConfirm(
                'Данные "Сумма запроса ЦТ из РБ" будут удалены',
                {
                    title: 'Подтверждение',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'OK',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true,
                    modalClass: 'del-item-modal-script'
                })
                .then(value => {
                    if (value) {
                        if (this.bipProjectData) {
                            this.$set(this.bipProjectData, 'considerationGA', 0);
                        }
                    } else {
                        if (this.bipProjectData) {
                            this.$set(this.bipProjectData, 'isConsiderationCentralGovernment', true);
                        }
                    }
                })
                .catch(error => {
                    makeToast(this, 'danger', 'Ошибка проверки', error.toString());
                });
        }
    }

    private changeDirectCash() {
        if (this.bipProjectData
            && !this.bipProjectData.isDirectCash
            && this.bipProjectData?.directCash > 0) {
            this.$bvModal.msgBoxConfirm(
                'Данные "Экономическая внутренняя норма доходности (EIRR)" будут удалены',
                {
                    title: 'Подтверждение',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'OK',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true,
                    modalClass: 'del-item-modal-script'
                })
                .then(value => {
                    if (value) {
                        if (this.bipProjectData) {
                            this.$set(this.bipProjectData, 'directCash', 0);
                        }
                    } else {
                        if (this.bipProjectData) {
                            this.$set(this.bipProjectData, 'isDirectCash', true);
                        }
                    }
                })
                .catch(error => {
                    makeToast(this, 'danger', 'Ошибка проверки', error.toString());
                });
        }
    }

    private deleteItem(item: RowOldFinanceData) {
        let i = 0;
        if (this.bipProjectData?.oldFinace) {
            for (const it of this.bipProjectData.oldFinace) {
                if (it === item) {
                    this.bipProjectData.oldFinace.splice(i, 1);
                    return;
                }
                i++;
            }
        }
    }

    private deleteNewFin(finalAbp: number, item: RowNewFinanceData) {
        const arr = (finalAbp === 1 ? this.bipProjectData?.newFinace : this.bipProjectData?.newFinaceRegional);
        let i = 0;
        if (arr) {
            for (const row of arr) {
                if (row === item) {
                    arr.splice(i, 1);
                    return;
                }
                i++;
            }
        }
    }

    private curKatoRegion: any = {localityList: [{code: ''}], code: ''};
    private getCurKatoRegion(): any {
        this.curKatoRegion = {localityList: [{code: ''}], code: ''};

        if (this.bipProjectData) {
            for (const region of this.regionList) {
                if (this.bipProjectData.region === region.code) {
                    this.curKatoRegion = region;
                }
            }
        }
    } // определяет элемент(выбранного БИПа) по коду като (region)

    private getItem(code: any, field: string, list: any []) {
        return findItem(code, field, list);
    } // возвращает объект по коду с заданного списка

    private async getFiles(data: any) {
        if (this.bipProjectData) {
            this.bipProjectData.files = data;
        }
    } // получает обновленный список файлов

    private getNumber(value: number | string | boolean | undefined | null, digit: number = 1): number {
        return getNumber(value, digit);
    } // возращает число

    private inputFixed(item: any, field: any, value: any, digit: any) {
        value = value.toString().replace(/\s/g, '');
        this.$set(item, field, parseFloat(parseFloat(value).toFixed(digit)));
    } // форматирует введенное значение до digit цифр после запятой

    private keyPress(event: any, pattern: any) {
        const regex = new RegExp(pattern);
        const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
            event.preventDefault();
            return false;
        }
        return true;
    }

    private bipCifList: any [] = [];
    private async loadBipCifList() {
        try {
            const response = await fetch('/api-py/dictionary/bip_cif_list');
            this.bipCifList = await response.json();
            this.bipCifList.sort(sortByField('code'));
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка запроса loadBipCifList', error.toString());
        }
    }

    private criteriaValues: Map<any, any> = new Map();
    private async loadBipCriteriaValues() {
        try {
            const response = await fetch('/api-py/dictionary/bip_criteria_values');
            const list = await response.json();

            for (const row of list) {
                this.criteriaValues.set(getRowKey(row, ['criteria', 'link', 'year']), row);
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка запроса loadBipCriteriaValues', error.toString());
        }
    }

    private bipLinkCriterias: any [] = [];
    private async loadBipLinkCriterias() {
        try {
            const response = await fetch('/api-py/dictionary/bip_link_criterias');
            this.bipLinkCriterias = await response.json();
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка запроса loadBipLinkCriterias', error.toString());
        }
    }

    private bipLinkTypes: any [] = [];
    private async loadBipLinkTypes() {
        try {
            const response = await fetch('/api-py/dictionary/bip_link_types');
            this.bipLinkTypes = await response.json();
            this.bipLinkTypes.sort(sortByField('id'));

        } catch (error) {
            makeToast(this, 'danger', 'Ошибка запроса loadBipLinkTypes', error.toString());
        }
    }

    private async loadCifList() {
        await this.loadUnits();
        await this.loadBipCifList();
        await this.loadBipLinkTypes();
        await this.loadBipLinkCriterias();
        await this.loadBipCriteriaValues();
        for (const blc of this.bipLinkCriterias) {
            try {
                const cif = this.bipCifList.filter(row => row.code === blc.criteria);
                if (cif.length > 0) {
                    for (const [key, value] of Object.entries(cif[0])) {
                        if (!['id', 'begin_date', 'end_date'].includes(key)) {
                            this.$set(blc, key, value);
                        }
                    }
                    this.$set(blc, 'unit_item', findItem(blc.unit, 'national_symbol', this.dictUnit));
                }

                const blt = this.bipLinkTypes.filter(row => row.id === blc.link);
                if (blt.length > 0) {
                    for (const [key, value] of Object.entries(blt[0])) {
                        if (key !== 'id') {
                            this.$set(blc, key, value);
                        }
                    }

                    this.$set(blc, 'begin_year', new Date(blt[0].begin_date).getFullYear());
                    if (blt[0].end_date !== null) {
                        this.$set(blc, 'end_year', new Date(blt[0].end_date).getFullYear());
                    } else {
                        this.$set(blc, 'end_year', null);
                    }
                }
            } catch (error) {
                console.log('criteria', blc.id, error.toString());
            }
        }
    }

    private dictUnit: any [] = [];
    private async loadUnits() {
        try {
            const response = await fetch('/api-py/dictionary/unit');
            this.dictUnit = await response.json();
            this.dictUnit.sort(sortByField('code'));
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка запроса loadUnits', error.toString());
        }
    }

    private directionList: any[] = [];
    private async loadDirectionList() {
        try {
            this.directionList = await fetch('/api-py/dictionary/bip_project_type_list/')
                .then(response => response.json());
            this.directionList.sort(sortByField('name_ru'));
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка загрузки loadDirectionList', error.toString());
        }
    }

    private prgList: any [] = [];
    private async loadPrg() {
        this.prgList = [];
        if (this.curHeader && this.curHeader.selAbp && this.curHeader.selAbp !== null) {
            try {
                const response = await fetch('/api-py/actual-prg-by-abp/' + this.curHeader?.selAbp.abp);
                this.prgList = await response.json();
                this.prgList = this.prgList.filter(row => row.develop_type === 1);
            } catch (error) {
                makeToast(this, 'danger', 'Ошибка загрузки loadPrg', error.toString());
            }
        }
    }

    private gpList: any [] = [];
    private async loadGpList() {
        this.gpList = [];
        try {
            this.gpList = await fetch('/api-py/dictionary/program/')
                .then(response => response.json());
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка загрузки вида данных', error.toString());
        }
    }

    private regPrgList: any [] = [];
    private async loadRegPrg() {
        this.regPrgList = [];
        if (this.curHeader && this.curHeader.finalAbp == 0 && this.curHeader.regAbp !== null) {
            try {
                const response = await fetch('/api-py/actual-prg-by-abp/' + this.curHeader.regAbp.abp);
                this.regPrgList = await response.json();
                this.regPrgList = this.regPrgList.filter(row => row.develop_type === 1);
            } catch (error) {
                makeToast(this, 'danger', 'Ошибка загрузки loadRegPrg', error.toString());
            }
        }
    }

    private spfList: any [] = [];
    private async loadSpf() {
        let response: any = [];
        try {
            response = await fetch('/api-py/dict-ebk-spf/');
            response = await response.json();
            response.sort((a: any, b: any) => (a.spf - b.spf > 0) ? 1 : -1);
            this.spfList = response;
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка загрузки подпрограмм', error.toString());
        }
    }

    private async openBip() {
        this.histBase = [];
        if (this.showForm && this.bip) {
            this.bipProjectData = BipProjectDataClass.fromJSON(this.bip);
            if (this.bipProjectData) {
                if (this.bipProjectData.id === 0) {
                    this.bipProjectData.header.finalAbp = 1;
                    this.$set(this.bipProjectData.period, '_begYear', this.bipProjectData.header.year);
                    this.$set(this.bipProjectData.period, '_endYear', this.bipProjectData.period.begYear + 1);
                } else {
                    if (this.bipProjectData && this.bipProjectData.newProject === 0 && this.prevBipList) {
                        this.selectedBip = this.prevBipList.filter(row => row.code === this.bipProjectData?.code)[0];
                    }
                    if (this.bipProjectData && this.bipProjectData.addDatas.status) {
                        this.agrObj = this.bipProjectData.addDatas.status;
                        await this.loadBipHist(this.agrObj.id);
                    }
                }
                this.setCurParams();
            }
        }
    }

    private reWriteBip() {
        if (this.bipProjectData?.newProject === 1) {
            if (this.bipProjectData.id === 0) {
                this.bipProjectData = new BipProjectDataClass();
                this.bipProjectData.header.year = this.bipYear;
                this.bipProjectData.header.dataType = 1;
                this.bipProjectData.header.finalAbp = 1;
                this.$set(this.bipProjectData.period, '_begYear', this.bipProjectData.header.year);
                this.$set(this.bipProjectData.period, '_endYear', this.bipProjectData.period.begYear + 1);
                this.setCurParams();
            }
        } else {
            this.selectedBip = null;
        }
    } // при изменении характера расходов

    private setCurParams() {
        if (this.bipProjectData) {
            this.bipYear = this.bipProjectData.header.year;
            this.begYear = this.bipProjectData.period.begYear;
            this.endYear = this.bipProjectData.period.endYear;
            this.selDirection = findItem(this.bipProjectData?.projectType, 'code', this.directionList);
            this.selObjectType = this.bipProjectData.object;
            this.getCurKatoRegion();
        }
    }

    // фиксация header
    private setHeader(form: BipProjectDataClass) {
        let editing = false;
        if (this.curHeader) {
            const head: any = {
                year: parseInt(form.header.year),
                dataType: form.header.dataType,
                abp: (this.curHeader.selAbp != null ? this.curHeader.selAbp.abp : 0),
                gu: this.curHeader.selGu,
                finalAbp: this.curHeader.finalAbp,
                editing: form.header?.editing
            };

            if (head.abp !== form.header.abp) {
                editing = true;
                if (form.newFinace) {
                    for (const fin of form.newFinace) {
                        if (this.curHeader.selAbp !== null) {
                            this.$set(fin, 'gr', this.curHeader.selAbp.gr);
                            this.$set(fin, 'abp', this.curHeader.selAbp.abp);
                        }
                        this.$set(fin, 'prg', null);
                        this.$set(fin, 'ppr', null);
                    }
                }
            }

            if (head.finalAbp === 1) {
                form.newFinaceRegional = [];
                this.$set(form, 'district_budget', '');
                this.$set(form, 'variant_recipient', '');
            } else {
                const regional: any = {
                    abp: (this.curHeader.regAbp != null ? this.curHeader.regAbp.abp : 0),
                    gu: this.curHeader.regGu,
                    dataType: (form.id === 0 || !form.header.regional ? parseInt(this.filterBox.dataType.code) : parseInt(form.header.regional.dataType))
                }
                this.$set(head, 'regional', regional);

                if (head.regional && form.header.regional
                    && head.regional.abp !== form.header.regional.abp) {
                    editing = true;
                    if (form.newFinaceRegional) {
                        for (const fin of form.newFinaceRegional) {
                            if (this.curHeader.regAbp !== null) {
                                this.$set(fin, 'gr', this.curHeader.regAbp.gr);
                                this.$set(fin, 'abp', this.curHeader.regAbp.abp);
                            }
                            this.$set(fin, 'prg', null);
                            this.$set(fin, 'ppr', null);
                        }
                    }
                }
            }

            this.$set(form, 'header', head);
            if (editing) {
                if ((form.newFinace && form.newFinace.length > 0)
                    || (form.newFinaceRegional && form.newFinaceRegional.length > 0)) {
                    makeToast(this, 'warning', 'Внимание!',
                        'АБП был изменен. Необходимо перевыбрать Программу/Подпрограмму в разделе 4!');
                }
                this.$set(form.header, 'editing', true);
            }
        }
        console.log('setHeader', form.header)
    }

    private setNewFinace(item: any, code: string, list: any) {
        const prgItem = findItem(code, 'prg', list);
        this.$set(item, 'gr', prgItem.gr);
        this.$set(item, 'pgr', prgItem.pgr);
        if (prgItem.pprList.length === 0) {
            this.$set(item, 'ppr', null);
        } else {
            const pprItem = findItem(item.ppr, 'ppr', prgItem.pprList);
            if (pprItem['ppr'] === null) {
                this.$set(item, 'ppr', null);
            }
        }
    }

    private setParams(form: BipProjectDataClass) {
        // определение области бюджета
        if (form?.header?.gu && ['02'].includes(form?.header?.gu.id_budget_type)) {
            this.$set(form, 'region_budget', this.location.region);
        }
        if (form?.header?.gu && ['03', '06'].includes(form?.header?.gu.id_budget_type)) {
            this.$set(form, 'region_budget', form?.header?.gu.id_region);
        }

        // определение региона бюджета
        if (form.header.finalAbp == 0 && form?.header?.regional) {
            if (form?.header?.regional?.gu && ['02'].includes(form?.header?.regional?.gu.id_budget_type)) {
                this.$set(form, 'district_budget', this.location.region);
            }
            if (form?.header?.regional?.gu && ['03', '06'].includes(form?.header?.regional?.gu.id_budget_type)) {
                this.$set(form, 'district_budget', form?.header?.regional?.gu.id_region);
            }
        } else {
            this.$set(form, 'district_budget', '');
            this.$set(form, 'variant_recipient', '');
        }
        // удаление лишних записей в json
        for (const obj of form?.newFinace) {
            for (const [key, value] of Object.entries(obj)) {
                if (!(['gr', 'pgr', 'prg', 'ppr', 'spf', 'openMore'].includes(key)
                    || (parseInt(key) >= form.period.begYear
                        && parseInt(key) <= form.period.endYear))) {
                    delete obj[key];
                }
            }
        }

        // Районный (конечный)
        if (form.header?.finalAbp === 0
            && form?.newFinaceRegional) {
            for (const obj of form?.newFinaceRegional) {
                for (const [key, value] of Object.entries(obj)) {
                    if (!(['gr', 'pgr', 'prg', 'ppr', 'spf', 'openMore'].includes(key)
                        || (parseInt(key) >= form.period.begYear
                            && parseInt(key) <= form.period.endYear))) {
                        delete obj[key];
                    }
                }
            }
        }
    }

    private async setUserNames(histBase: any[]) {
        const nameMap = new Map();

        if (this.agrObj.userId) { nameMap.set(this.agrObj.userId, ''); }
        for (const el of histBase) {
            let result: any = null;
            try {
                const response: any = await fetch(`/api-py/get-realm-user/${encodeURI(el.userId)}`);
                result = await response.json();
                if (result !== null) {
                    nameMap.set(el.userId, (result.firstName ? result.firstName : '') + ' ' + (result.lastName ? result.lastName : ''));
                }
            } catch (error) {
                makeToast(this, 'danger', 'Ошибка get-realm-user ' + el.userId, (error as Error).toString());
            }
        }

        this.agrObj.userName = nameMap.get(this.agrObj.userId);
        for (const el of histBase) {
            el.userName = nameMap.get(el.userId);
        }
    }

    private transitFiles(year: string, code: string, new_year: string) {
        Ax(
            {
                url: '/api-py/file-transit/' + year + '/' + code + '/' + new_year,
                method: 'POST',
                data: null,
                responseType: 'blob'
            },
            (data: any) => {
                if (data.result === 'success') {
                    // makeToast(this, 'success', 'Удаление', 'Запись удалена');
                    console.log('transit success');
                }
            },
            (error) => {
                makeToast(this, 'danger', 'Ошибка скачивания', error.toString());
            }
        );
    }

    private async updateBip() {
        if (this.bipProjectData) {
            await this.setHeader(this.bipProjectData);
            await this.setParams(this.bipProjectData);

            let section = 0;

            if (this.bipProjectData?.newFinace) {
                for (const fin of this.bipProjectData?.newFinace) {
                    if (fin.prg === null || fin.spf === null || (fin.ppr === null && this.getItem(fin.prg, 'prg', this.prgList).pprList.length > 0)) {
                        section = 4;
                    }
                }
            }
            if (this.bipProjectData?.newFinaceRegional) {
                for (const fin of this.bipProjectData?.newFinaceRegional) {
                    if (fin.prg === null || fin.spf === null || (fin.ppr === null && this.getItem(fin.prg, 'prg', this.prgList).pprList.length > 0)) {
                        section = 4;
                    }
                }
            }
            // проверка исторических данных
            if (this.bipProjectData?.oldFinace) {
                for (const fin of this.bipProjectData?.oldFinace) {
                    if (fin.prg === null || fin.ppr === null || fin.spf === null) {
                        section = 3;
                    }
                }
            }

            const item = {
                equal: (this.reading || !this.editSection4 ? true : BipProjectDataClass.isEqual(this.bip, this.bipProjectData)),
                curHeader: this.curHeader,
                form: this.bipProjectData,
                section: section
            };
            setTimeout(() => {
                this.$emit('updateBip', item);
            }, 100);
        }
    }

    private async updateCriterias() {
        for (const row of this.calcIndicators) {
            const item = Object.assign(new CriteriaIndicators(), row);
            const value = this.criteriaValues.get(getRowKey(item, ['code', 'link', 'begin_year']));
            if (value) {
                this.calcFlds.forEach((field) => {
                    if (field === 'value') {
                        if (this.bipProjectData
                            && this.bipProjectData.criteriaIndicators
                            && item.operator === 'CSI') {
                            this.$set(this.bipProjectData.criteriaIndicators, item?.code, this.getNumber(value[field], 2));
                        }
                    } else {
                        if (this.bipProjectData
                            && this.bipProjectData.criteriaIndicators) {
                            this.$set(this.bipProjectData.criteriaIndicators, item?.code + field, this.getNumber(value[field], 2));
                        }
                    }
                });
            }
            if (item.code === '41') {
                let x = 0;
                let y = 0;
                if (this.bipProjectData && this.bipProjectData.newFinace) {
                    for (const obj of this.bipProjectData.newFinace) {
                        for (const [key, value] of Object.entries(obj)) {
                            if (parseInt(key) === parseInt(this.bipProjectData?.header?.year)) {
                                if (['15', '28'].includes(String(obj?.ppr))) {
                                    x += parseFloat(value);
                                }
                                y += parseFloat(value);
                            }
                        }
                    }
                    this.$set(this.bipProjectData.criteriaIndicators, item?.code, this.getNumber((x/y)*100, 2));
                }
            }
            if (item.code === '43') {
                if (this.bipProjectData && this.bipProjectData.criteriaIndicators) {

                    if ((['27'].includes(this.bipProjectData.object) && ['1', '3'].includes(this.bipProjectData.projectType))
                        || ['7', '8'].includes(this.bipProjectData.projectType)
                        || (['42', '43'].includes(this.bipProjectData.object) && ['1', '3'].includes(this.bipProjectData.projectType)))
                    {
                        this.$set(this.bipProjectData.criteriaIndicators, item?.code,
                            this.getNumber(this.bipProjectData?.costWithout)/this.getNumber(this.bipProjectData.criteriaIndicators['137'])/1000);
                    }

                    if (['27', '42', '43'].includes(this.bipProjectData.object) && ['2', '4'].includes(this.bipProjectData.projectType)) {
                        this.$set(this.bipProjectData.criteriaIndicators, item?.code,
                            this.getNumber(this.bipProjectData?.costWithout)/this.getNumber(this.bipProjectData.criteriaIndicators['56'])/1000);
                    }

                    if (['7', '8'].includes(this.bipProjectData.projectType)) {
                        this.$set(this.bipProjectData.criteriaIndicators, item?.code,
                            this.getNumber(this.bipProjectData?.costWithout)/this.getNumber(this.bipProjectData.criteriaIndicators['137'])/1000);
                    }
                }
            }
            if (item.code === '46') {
                if (this.bipProjectData && this.bipProjectData.criteriaIndicators) {
                    this.$set(this.bipProjectData.criteriaIndicators, item?.code,
                        100*this.getNumber(this.bipProjectData.criteriaIndicators['137'])/this.getNumber(this.bipProjectData.criteriaIndicators['148']));

                }
            }
            if (item.code === '73') {
                if (this.bipProjectData
                    && this.bipProjectData.criteriaIndicators) {
                    this.$set(this.bipProjectData.criteriaIndicators, item?.code,
                        this.getNumber(this.bipProjectData.criteriaIndicators['147'])*this.getNumber(this.bipProjectData.criteriaIndicators['107'])/1000);

                }
            }
            if (item.code === '50') {
                if (this.bipProjectData && this.bipProjectData.criteriaIndicators) {
                    if (!['17', '18', '31', '33'].includes(this.bipProjectData.object) && ['3'].includes(this.bipProjectData.projectType))
                    {
                        this.$set(this.bipProjectData.criteriaIndicators, item?.code,
                            this.getNumber(this.bipProjectData?.costWithout)/this.getNumber(this.bipProjectData.criteriaIndicators['63']));
                    }
                    if (!['17'].includes(this.bipProjectData.object) && ['4'].includes(this.bipProjectData.projectType))
                    {
                        this.$set(this.bipProjectData.criteriaIndicators, item?.code,
                            this.getNumber(this.bipProjectData?.costWithout)/this.getNumber(this.bipProjectData.criteriaIndicators['153']));
                    }
                    if (['17'].includes(this.bipProjectData.object))
                    {
                        this.$set(this.bipProjectData.criteriaIndicators, item?.code,
                            this.getNumber(this.bipProjectData?.costWithout)/this.getNumber(this.bipProjectData.criteriaIndicators['49']));
                    }
                    if (['18', '31', '33'].includes(this.bipProjectData.object) && ['3'].includes(this.bipProjectData.projectType))
                    {
                        this.$set(this.bipProjectData.criteriaIndicators, item?.code,
                            this.getNumber(this.bipProjectData?.costWithout)/this.getNumber(this.bipProjectData.criteriaIndicators['146']));
                    }

                }
            }
            if (item.code === '65') {
                if (this.bipProjectData
                    && this.bipProjectData.criteriaIndicators) {
                    this.$set(this.bipProjectData.criteriaIndicators, item?.code,
                        this.getNumber(this.bipProjectData.costWithout)/this.getNumber(this.bipProjectData.criteriaIndicators['64']));

                }
            }
            if (item.code === '77') {
                if (this.bipProjectData
                    && this.bipProjectData.criteriaIndicators) {

                    if (['1', '3'].includes(this.bipProjectData.projectType))
                    {
                        this.$set(this.bipProjectData.criteriaIndicators, item?.code,
                            100*this.getNumber(this.bipProjectData.criteriaIndicators['63'])/this.getNumber(this.bipProjectData.criteriaIndicators['73']));
                    }
                    if (['2', '4'].includes(this.bipProjectData.projectType))
                    {
                        this.$set(this.bipProjectData.criteriaIndicators, item?.code,
                            100*this.getNumber(this.bipProjectData.criteriaIndicators['153'])/this.getNumber(this.bipProjectData.criteriaIndicators['73']));
                    }
                }
            }
            if (item.code === '87') {
                if (this.bipProjectData
                    && this.bipProjectData.criteriaIndicators) {

                    if (['3'].includes(this.bipProjectData.projectType))
                    {
                        this.$set(this.bipProjectData.criteriaIndicators, item?.code,
                            this.getNumber(this.bipProjectData.costWithout)/this.getNumber(this.bipProjectData.criteriaIndicators['150']));
                    }
                    if (['4'].includes(this.bipProjectData.projectType))
                    {
                        this.$set(this.bipProjectData.criteriaIndicators, item?.code,
                            this.getNumber(this.bipProjectData.costWithout)/this.getNumber(this.bipProjectData.criteriaIndicators['56']));
                    }

                }
            }
            if (['89', '90'].includes(item.code)) {
                if (this.bipProjectData
                    && this.bipProjectData.criteriaIndicators) {

                    this.$set(this.bipProjectData.criteriaIndicators, item?.code,
                        this.getNumber(this.bipProjectData.criteriaIndicators['64'])/this.getNumber(this.bipProjectData.criteriaIndicators['145']));
                }
            }
            if (item.code === '118') {
                if (this.bipProjectData
                    && this.bipProjectData.criteriaIndicators) {

                    if (['3'].includes(this.bipProjectData.projectType))
                    {
                        this.$set(this.bipProjectData.criteriaIndicators, item?.code,
                            this.getNumber(this.bipProjectData.criteriaIndicators['146']));
                    }
                }
            }
        }
    }
}
