

























































import { Component, Vue, Prop } from 'vue-property-decorator';
import { makeToast } from '@/modules/budget-request/components/js/some-functions';
import { calcProgn } from '@/modules/budget-request/components/js/budgetCalcProgn';
import { formLst } from '@/modules/budget-request/components/js/budgetFormsLst';

@Component({name: 'c-guelevel-modal'})
export default class CBudgetSumCalcGuLevelModal extends Vue {
    private showModal = false;

    private dataArr: any[] = [];
    private collapseAll = true;
    private titleYear: string = '';

    private progress = 0;

    private totalSum = 0;

    private showEvent(params: any) {
        this.loadData(params);
        this.showModal = true;
    }

    private async loadData(params: any) {
        this.dataArr = [];
        this.progress = 45;
        this.totalSum =0;
        this.titleYear = `${params.year} г.`;
        let result = null;
         try {
            result = await fetch(encodeURI(`/api-py/get-budget-calc-gulvl/${JSON.stringify(params)}`));
            this.progress = 75;
            if (result.status === 200) {
                result = await result.json();
                const dataArr: any[] = [];
                result.sort((a: any, b: any) => (a.gu >= b.gu) ? 1 : -1);

                for (const el of result) {
                    if (el.value && calcProgn(new Map([[el.form, el]]), params.spf)!==null) {
                        if (!dataArr.length || dataArr[dataArr.length - 1].gu !== el.gu) {
                            this.sumVal(dataArr);
                            dataArr.push({gu: el.gu, guName: el.guName, formLst: []});
                        }
                        const url = this.getUrl(params, el);
                        dataArr[dataArr.length - 1].formLst.push({form: el.form, bipCode: el.bip_code, value: Math.round(el.value), url: url});
                    }
                }
                this.sumVal(dataArr);
                this.dataArr = dataArr;
            }  else {
                return;
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка загрузки операций пользователя', (error as Error).toString());
            return;
        } finally {
            this.progress = 100;
        }
    }

    private sumVal(dataArr: any[]) {
        if (dataArr.length) {
            if (!dataArr[dataArr.length -1].formLst.length) {
                dataArr.splice(dataArr.length -1, 1);
                return;
             }
            let sum = 0;
            for (const f of dataArr[dataArr.length -1].formLst) {
                sum += f.value;
            }
            dataArr[dataArr.length - 1].sum = Math.round(sum);
            this.totalSum += Math.round(sum);
            dataArr[dataArr.length - 1].collapse = this.collapseAll;
        }
    }

    private clkCollapse(indx: number | null) {
        if (indx === null) {
            this.collapseAll = !this.collapseAll;
            for (const el of this.dataArr) {
                el.collapse = this.collapseAll;
            }
            return;
        }
        this.dataArr[indx].collapse = !this.dataArr[indx].collapse;
    }


    private getUrl(obj: any, el: any): string | null {
        if (el.value_source_link) {
            return `#/${el.value_source_link}`;
        }
        if (el.form!==null) {
            let href: string | null = null;
            for (let i=0; i<formLst.length; i++) {
                if (formLst[i].code === el.form) {
                    href = formLst[i].href;
                    break;
                }
            }
            if (href === null) { href = `/form${el.form}`; }
            const params = `#${href}/${obj.curYear}/${obj.year}/${obj.dataType}/${el.gr}/${obj.abp}/${obj.prg}/${obj.ppr}/${el.gu}/${obj.variant}/${obj.spf}/no/gu/true/end`;
            return params;
        } else if (el.bip_code) {
            const params = `#/bip/bip-card?${encodeURI(`bip_code=${el.bip_code}&variant=${obj.variant}`)}`;
            return params;
        }
        return null;
    }

}
