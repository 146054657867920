<template>
    <div class="h-100">
        <div v-show="!showForm" class="h-100">
            <!--            фильтр-->
            <div class="filter-container">
                <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                    <template #button-content>
                        <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i
                        class="icon icon-keyboard"></i></span>
                    </template>
                    <div>
                        <div class="top-content">
                            <span>Параметры фильтра</span>
                            <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                        </div>
                        <!--------------- Плановый период ------------------->
                        <div class="filter-block">
                            <b-form-group label="Плановый период">
                                <multiselect ref="bcPlanYear"
                                             v-model="planPeriod"
                                             track-by="name"
                                             label="name"
                                             :options="years"
                                             :searchable="false"
                                             :allow-empty="false"
                                             :show-labels="false">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Область/район бюджета ------------------->
                        <div class="filter-block">
                            <b-form-group label="Область/район бюджета">
                                <multiselect ref="bcRegionBudget"
                                             v-model="selRegionBudget"
                                             track-by="name_ru"
                                             label="name_ru"
                                             :options="regionBudgetList"
                                             :searchable="true"
                                             :allow-empty="false"
                                             :show-labels="false">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Вид данных ------------------->
                        <div class="filter-block">
                            <b-form-group label="Вид данных">
                                <multiselect ref="bcDataType"
                                             v-model="selDataType"
                                             track-by="name_ru"
                                             label="name_ru"
                                             placeholder="Все"
                                             :options="dataTypeList"
                                             :searchable="true"
                                             :allow-empty="false"
                                             :show-labels="false">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Версия бюджета ------------------->
                        <div class="filter-block">
                            <b-form-group label="Версия бюджета">
                                <multiselect ref="bcVersion"
                                             v-model="selVersion"
                                             track-by="text"
                                             label="text"
                                             :options="filterVersion"
                                             :searchable="true"
                                             :allow-empty="false"
                                             :show-labels="false">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Месторасположение проекта ------------------->
                        <div class="filter-block">
                            <b-form-group label="Месторасположение проекта">
                                <multiselect ref="bcRegion"
                                             v-model="selRegion"
                                             track-by="name_ru"
                                             label="name_ru"
                                             placeholder="Все"
                                             :options="regionFilter"
                                             :searchable="true"
                                             :show-labels="false"
                                             @input="changeRegion">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Направление ------------------->
                        <div class="filter-block">
                            <b-form-group label="Направление">
                                <multiselect ref="bcDirection"
                                             v-if="directionFilter"
                                             v-model="selDirection"
                                             track-by="name_ru"
                                             label="name_ru"
                                             placeholder="Все"
                                             :options="directionFilter"
                                             :searchable="true"
                                             :show-labels="false"
                                             @input="filter">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Тип объекта ------------------->
                        <div class="filter-block">
                            <b-form-group label="Тип объекта">
                                <multiselect ref="bcObject"
                                             v-if="objectFilter"
                                             v-model="selObject"
                                             track-by="name_ru"
                                             label="name_ru"
                                             placeholder="Все"
                                             :options="objectFilter"
                                             :searchable="true"
                                             :show-labels="false"
                                             @input="filter">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Статус ------------------->
                        <div class="filter-block">
                            <b-form-group label="Статус">
                                <multiselect ref="bcStatus"
                                             v-if="statusFilter"
                                             v-model="selStatus"
                                             track-by="name_ru"
                                             label="name_ru"
                                             placeholder="Все"
                                             :options="statusFilter"
                                             :searchable="true"
                                             :show-labels="false"
                                             @input="filter">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- АБП ------------------->
                        <div class="filter-block">
                            <b-form-group label="АБП">
                                <multiselect ref="bcAbp"
                                             v-model="selAbp"
                                             track-by="text"
                                             label="text"
                                             placeholder="Все"
                                             :options="abpFilter"
                                             :searchable="true"
                                             :show-labels="false"
                                             @input="filter">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- ПРГ ------------------->
                        <div class="filter-block">
                            <b-form-group label="Бюджетная программа">
                                <multiselect ref="bcPrg"
                                             v-model="selPrg"
                                             track-by="text"
                                             label="text"
                                             placeholder="Все"
                                             :options="prgFilter"
                                             :searchable="true"
                                             :show-labels="false"
                                             @input="filter">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Гос.программа ------------------->
                        <div class="filter-block">
                            <b-form-group label="Гос.программа">
                                <multiselect ref="bcGp"
                                             v-model="selGp"
                                             track-by="name_ru"
                                             label="name_ru"
                                             placeholder="Все"
                                             :options="gpFilter"
                                             :searchable="true"
                                             :show-labels="false"
                                             @input="filter">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Характер расходов ------------------->
                        <div class="filter-block">
                            <b-form-group label="Характер расходов">
                                <multiselect ref="bcRadio"
                                             v-model="radio"
                                             track-by="text"
                                             label="text"
                                             placeholder="Все"
                                             :options="radioList"
                                             :searchable="true"
                                             :show-labels="false"
                                             @input="filter">
                                </multiselect>
                            </b-form-group>
                        </div>
                    </div>
                </b-dropdown>
                <div class="filter-actions" v-if="editing">
                    <b-button v-if="operationCode.length > 0"
                              variant="success"
                              @click="agreementEvent">Действия
                    </b-button>
                    <b-button variant="success" @click="btnSaveForms">Сохранить</b-button>
                </div>
            </div>
            <!--        хлебные крошки-->
            <div class="filter-breadcrumb">
                <span class="item-breadcrumb" v-if="!openFilterWindow && planPeriod"
                      @click="openFilterByRef('bcPlanYear')">
                    {{ planPeriod.name }}
                </span>
                <span class="item-breadcrumb" v-if="!openFilterWindow && selRegionBudget"
                      @click="openFilterByRef('bcRegionBudget')">
                    {{ selRegionBudget.name_ru }}
                </span>
                <span class="item-breadcrumb" v-if="!openFilterWindow && selDataType"
                      @click="openFilterByRef('bcDataType')">
                    {{ selDataType.name_ru }}
                </span>
                <span class="item-breadcrumb" v-if="!openFilterWindow && selVersion"
                      @click="openFilterByRef('bcVersion')">
                    {{ selVersion.name_ru }}
                </span>
                <span class="item-breadcrumb" v-if="!openFilterWindow && selRegion"
                      @click="openFilterByRef('bcRegion')">
                    {{ selRegion.name_ru }}
                </span>
                <span class="item-breadcrumb" v-if="!openFilterWindow && selDirection"
                      @click="openFilterByRef('bcDirection')">
                    {{ selDirection.name_ru }}
                </span>
                <span class="item-breadcrumb" v-if="!openFilterWindow && selObject"
                      @click="openFilterByRef('bcObject')">
                    {{ selObject.name_ru }}
                </span>
                <span class="item-breadcrumb" v-if="!openFilterWindow && selStatus"
                      @click="openFilterByRef('bcStatus')">
                    {{ selStatus.name_ru }}
                </span>
                <span class="item-breadcrumb" v-if="!openFilterWindow && selAbp" @click="openFilterByRef('bcAbp')">
                    {{ selAbp.abp }}-АБП
                </span>
                <span class="item-breadcrumb" v-if="!openFilterWindow && selPrg" @click="openFilterByRef('bcPrg')">
                    {{ padLeadingZeros(selPrg.prg, 2) }}-БП>
                </span>
                <span class="item-breadcrumb" v-if="!openFilterWindow && selGp" @click="openFilterByRef('bcGp')">
                    {{ selGp.name_ru }}
                </span>
                <span class="item-breadcrumb" v-if="!openFilterWindow && radio" @click="openFilterByRef('bcRadio')">
                    {{ radio.text }}
                </span>
            </div>
            <b-progress variant="success" v-show="pBar < 100" height="10px" :value="pBar" striped animated></b-progress>
            <div class="table-container">
                <template v-if="showBipList.length > 0">
                    <b-table ref="bipList"
                             :fields="tableFields"
                             :items="showBipList"
                             :striped="true"
                             responsive="true"
                             bordered
                             head-variant="light"
                             sticky-header="true"
                             no-border-collapse>
                        <template #head(check)="scope">
                            <b-form-checkbox v-model="check"
                                             v-if="editing && operationCode.length > 0">
                            </b-form-checkbox>
                        </template>
                        <template #head(support)="scope">
                            <div class="green">{{ scope.label }}</div>
                        </template>
                        <template #head(not_support)="scope">
                            <div class="red">{{ scope.label }}</div>
                        </template>
                        <template #head(plan1)="scope">
                            <div>{{ 'в т.ч. на ' + parseInt(planPeriod.year) + ' год, тыс. тенге' }}</div>
                        </template>
                        <template #head(plan2)="scope">
                            <div>{{ 'в т.ч. на ' + (parseInt(planPeriod.year) + 1) + ' год, тыс. тенге' }}</div>
                        </template>
                        <template #head(plan3)="scope">
                            <div>{{ 'в т.ч. на ' + (parseInt(planPeriod.year) + 2) + ' год, тыс. тенге' }}</div>
                        </template>
                        <template #cell(check)="data">
                            <template v-if="editing && operationCode.length > 0 && checkAccess(data.item)">
                                <b-form-checkbox v-model="data.item.check">
                                </b-form-checkbox>
                            </template>
                        </template>
                        <template #cell(code)="data">
                            <div class="small-text bold">{{ data.value }}</div>
                        </template>
                        <template #cell(nameRu)="data">
                            <div class="text-left"><a href="#" @click="openBip(data.item)">{{ data.value }}</a></div>
                            <!--                    :href="data.item.addDatas.link" target="_blank"-->
                        </template>
                        <template #cell()="data">
                            <div class="text-right">{{ $n(getNumber(data.value)) }}</div>
                        </template>
                        <template #cell(status)="data">
                            {{
                                data.item.addDatas.status ? data.item.addDatas.status.name_ru : findItem(1, 'code', statusList).name_ru
                            }}
                        </template>
                        <template #cell(abp)="data">
                            <div>{{ data.item.addDatas.abp.text }}</div>
                        </template>
                        <template #cell(prg)="data">
                            <div class="in-table-grid-item-text" v-for="(fin, index) in data.item.addDatas.finace"
                                 :key="'prg_'+index+data.item.id+fin.prg+fin.spf">{{ padLeadingZeros(fin.prg, 3) }}
                            </div>
                        </template>
                        <template #cell(ppr)="data">
                            <div class="in-table-grid-item-text" v-for="(fin, index) in data.item.addDatas.finace"
                                 :key="'ppr_'+index+data.item.id+fin.ppr+fin.spf">
                                {{ fin.ppr === null ? '_' : padLeadingZeros(fin.ppr, 3) }}
                            </div>
                        </template>
                        <template #cell(ppr_cost)="data">
                            <div class="in-table-grid-item-text" v-for="(fin, index) in data.item.addDatas.finace"
                                 :key="'cost_'+index+data.item.id+fin.ppr+fin.spf">
                                {{ $n(fin.ppr_cost) }}
                            </div>
                        </template>
                        <template #cell(plan1)="data">
                            <div class="in-table-grid-item-input">
                                <div class="text">
                                    <span v-for="(fin, index) in data.item.addDatas.finace"
                                          :key="'div_'+index+data.item.id+fin.ppr+fin.spf">
                                        {{ $n(fin.fact1) }}
                                    </span>
                                </div>
                                <div class="inputs">
                                    <b-form-input
                                        v-for="(fin, ind) in data.item.addDatas.finace"
                                        :key="ind+'inp_'+data.item.id+fin.prg+fin.ppr+fin.spf"
                                        :disabled="!editing || !isNextStep(data.item)"
                                        :value="$n(getNumber(fin.plan1))"
                                        @change="v => fin.plan1 = v"
                                        @keyup.enter.exact="keyup13"
                                        @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                        @blur="inputFixed(data.item, fin, 'plan1', fin.plan1)">
                                    </b-form-input>
                                </div>
                            </div>
                        </template>
                        <template #cell(plan2)="data">
                            <div class="in-table-grid-item-input">
                                <div class="text">
                                    <span v-for="(fin, index) in data.item.addDatas.finace"
                                          :key="'div2_'+index+data.item.id+fin.ppr+fin.spf">
                                        {{ $n(fin.fact2) }}
                                    </span>
                                </div>
                                <div class="inputs">
                                    <b-form-input v-for="(fin) in data.item.addDatas.finace"
                                                  :key="'inp2_'+data.item.id+fin.prg+fin.ppr+fin.spf"
                                                  :disabled="!editing || !isNextStep(data.item)"
                                                  :value="$n(getNumber(fin.plan2))"
                                                  @change="v => fin.plan2 = v"
                                                  @keyup.enter.exact="keyup13"
                                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                                  @blur="inputFixed(data.item, fin, 'plan2', fin.plan2)">
                                    </b-form-input>
                                </div>
                            </div>
                        </template>
                        <template #cell(plan3)="data">
                            <div class="in-table-grid-item-input">
                                <div class="text">
                                    <span v-for="(fin, index) in data.item.addDatas.finace"
                                          :key="'div3_'+index+data.item.id+fin.ppr+fin.spf">
                                        {{ $n(fin.fact3) }}
                                    </span>
                                </div>
                                <div class="inputs">
                                    <!--                                    (data.item.addDatas.status && data.item.addDatas.status.code === 52)-->
                                    <b-form-input v-for="(fin) in data.item.addDatas.finace"
                                                  :key="'inp3_'+data.item.id+fin.prg+fin.ppr+fin.spf"
                                                  :disabled="!editing || !isNextStep(data.item)"

                                                  :value="$n(getNumber(fin.plan3))"
                                                  @change="v => fin.plan3 = v"
                                                  @keyup.enter.exact="keyup13"
                                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                                  @blur="inputFixed(data.item, fin, 'plan3', fin.plan3)">
                                    </b-form-input>
                                </div>
                            </div>
                        </template>
                        <template #cell(total)="data">
                            <div class="text-bold text-center" style="font-size: 1.1rem"
                                 :class="{ 'text-danger': parseFloat(data.value) < 4,
                                 'text-warning': parseFloat(data.value) >= 4 && parseFloat(data.value) < 7,
                                 'text-success': parseFloat(data.value) >= 7 }">
                                {{ data.value }}
                            </div>
                        </template>
                        <template #cell(more)="data">
                            <b-dropdown dropleft class="more">
                                <template v-slot:button-content>
                                    <i class="icon icon-more"></i>
                                </template>
                                <b-dropdown-item v-if="data.item.addDatas.status"
                                                 @click="commentClk(data.item.addDatas.status)">
                                    Журнал изменений
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                        <template #bottom-row="data">
                            <td></td>
                            <td>
                                <div class="text-bold">{{ showBipList.length }}</div>
                            </td>
                            <td colspan="2">
                                <div class="text-right text-bold">ИТОГО, ТЫС.ТГ</div>
                            </td>
                            <td>
                                <div class="text-right text-bold">{{ $n(total('totalCost')) }}</div>
                            </td>
                            <td colspan="4"></td>
                            <td>
                                <div class="text-right text-bold">{{ $n(totalFinans) }}</div>
                            </td>
                            <td>
                                <div class="text-right text-bold">{{ $n(_totalFinans('plan1')) }}</div>
                            </td>
                            <td>
                                <div class="text-right text-bold">{{ $n(_totalFinans('plan2')) }}</div>
                            </td>
                            <td>
                                <div class="text-right text-bold">{{ $n(_totalFinans('plan3')) }}</div>
                            </td>
                            <td></td>
                        </template>
                    </b-table>
                </template>
            </div>
        </div>
        <div v-show="showForm">
            <!--     кнопки     -->
            <div class="filter-container">
                <div class="left-content">
                </div>
                <div class="right-content">
                    <div class="filter-actions">
                        <b-button class="ml-auto" variant="light" @click="showForm = !showForm">назад к списку
                        </b-button>
                    </div>
                </div>
            </div>
            <bip-card :showForm="showForm"
                      :location="location"
                      :regionList="regionList"
                      :bip="bipProjectData"
                      :filterBox="filterBox"
                      :dataTypeList="dataTypeList"
            >
            </bip-card>
        </div>
        <!--Модальные окна-->
        <c-budg-agr-modal ref="refAgrModal" :agrBtnLst="agrBtnLst" @agrClk="agrClick"></c-budg-agr-modal>
        <c-budg-agr-hist-modal ref="refHistModal"></c-budg-agr-hist-modal>
        <loading
            :active="loading"
            is-full-screen
            spinner="bar-fade-scale"
            color="#6495ED"
        />
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueElementLoading from "vue-element-loading";
import store from '@/services/store';
import CBudgetAgreementModal from '@/modules/budget-request/components/budget-sum-calc/budget-agreement-modal.vue';
import CBudgetAgreementHistModal
    from '@/modules/budget-request/components/budget-sum-calc/budget-agreement-hist-modal.vue';
import BipCard from '@/modules/budget/bip/bip-card.vue';
import {
    BipProjectDataClass,
    sortByField, makeToast, years, doRowKey, padLeadingZeros, findItem, getNumber, checkAccess,
    findBipVariantNull, findBipVariantRecNull, IGu, IPpr, IFallbackFindItemResponse, RowNewFinanceData
} from './bip-types';
import {CUR_YEAR} from '@/modules/budget-request/components/js/budgetCurYear';
import i18nService from "@/services/i18n";

export default {
    name: 'bip-uebp',
    components: {
        'loading': VueElementLoading,
        'c-budg-agr-modal': CBudgetAgreementModal,
        'c-budg-agr-hist-modal': CBudgetAgreementHistModal,
        BipCard
    },
    props: {
        location: undefined,
        regionBudgetList: undefined,
        versionList: undefined
    },
    data() {
        return {
            tableFields: [
                {
                    key: 'check',
                    label: ' '
                },
                {
                    key: 'code',
                    label: 'Код',
                    sortable: true
                },
                {
                    key: 'nameRu',
                    label: 'Наименование проекта',
                    sortable: true
                },
                {
                    key: 'total',
                    label: 'Оценка, Балл',
                    sortable: true
                },
                {
                    key: 'totalCost',
                    label: 'Стоимость проекта, тыс.тг',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Статус'
                },
                {
                    key: 'abp',
                    label: 'АБП'
                },
                {
                    key: 'prg',
                    label: 'Программа'
                },
                {
                    key: 'ppr',
                    label: 'Подпрограмма',
                    variant: 'warning'
                },
                {
                    key: 'ppr_cost',
                    label: 'Стоимость в разрезе подпрограмм, тыс.тг'
                },
                {
                    key: 'plan1',
                    label: '',
                    variant: 'warning'
                },
                {
                    key: 'plan2',
                    label: ''
                },
                {
                    key: 'plan3',
                    label: '',
                    variant: 'warning'
                },
                {
                    key: 'more',
                    label: ''
                }
            ],
            detailFields: [
                {
                    key: 'nomer',
                    label: '№'
                },
                {
                    key: 'name_ru',
                    label: 'Проект'
                }
            ],

            bipList: [],
            bipCifList: [],
            costsMap: null,

            agrMap: new Map(),

            selRegionBudget: null,

            regionList: [],
            regionFilter: [],
            selRegion: null, // текущий регион

            dataTypeList: [],
            selDataType: null,

            selVersion: undefined,

            katos: new Map(),
            selKato: null, // текущий НП

            directionList: null,
            directionFilter: null,
            selDirection: null,

            objectList: null,
            objectFilter: null,
            selObject: null, // текущий тип объекта

            statusList: null,
            statusFilter: null,
            selStatus: null, // текущий статус

            radioList: [
                {value: 0, text: 'Переходящие проекты'},
                {value: 1, text: 'Новые инициативы'}
            ],
            radio: null,

            years: [],
            planPeriod: null,

            abpList: [],
            abpFilter: [],
            selAbp: null,

            prgFilter: [],
            selPrg: null,
            selPpr: null,

            gpList: [],
            gpFilter: [],
            selGp: null,

            userAbps: [],
            bipProjectData: null,
            userUiid: null,
            userLevel: 0,
            operationCode: [],
            agrBtnLst: {back: [], forward: []},
            agreeList: [],


            filterBox: null,
            mode_code: 'bip',
            open: false,
            check: false,
            showForm: false,
            openFilterWindow: false,
            loading: false,
            pBar: 0,
            mountLoad: 0,
            findDB: 0
        };
    },

    async created() {
        this.$watch('planPeriod', () => {
            if (this.mountLoad > 0) {
                this.updateVersionByYR();
            }
        });
        this.$watch('selRegionBudget', () => {
            if (this.mountLoad > 0) {
                this.updateVersionByYR();
            }
        });
        this.$watch('selDataType', () => {
            if (this.mountLoad > 0) {
                this.updateVersion();
            }
        });
        this.$watch('selVersion', () => {
            this.selRegion = null;
            this.selDirection = null;
            this.selObject = null;
            this.selStatus = null;
            this.selAbp = null;
            this.selGp = null;
            this.selPrg = null;
            if (this.selVersion) {
                this.loadBipAgreement();
                setTimeout(() => {
                    if (!this.loading && this.mountLoad > 0) {
                        this.loadBipList();
                    }
                }, 2000);
            }
        });
        this.$watch('selAbp', () => {
            this.selPrg = null;
        });
        this.$watch('check', (value) => {
            for (const row of this.showBipList) {
                if (checkAccess(row, 'uebp_agree', 'oebp_agree_recipient', this.selRegionBudget.code)) {
                    this.$set(row, 'check', value);
                }
            }
        });
        this.$watch('showForm', (value) => {
            this.filterBox = {
                planPeriod: this.planPeriod,
                dataType: this.selDataType,
                variant: this.selVersion
            }
        });
    },

    async mounted() {
        await this.loadDataTypeList();
        await this.loadUserAbps();
        await this.loadOperations();
        await this.loadRegionList();
        await this.loadDirectionList();
        await this.loadObjectList();
        await this.loadStatusList();
        await this.loadBipCifList();
        await this.loadDetailFields();

        this.userUiid = store.state.user.sub;
        this.selRegionBudget = this.regionBudgetList[0];
        await years(this, this.years);

        setTimeout(() => {
            if (this.selRegionBudget && this.selRegionBudget !== null) {
                let result = this.versionList.filter(row => (row.is_deleted === null || !row.is_deleted)
                    && row.attribute
                    && row.region_code === this.selRegionBudget.code);
                result = result.sort(sortByField('date_start', 'desc'));

                if (result.length > 0) {
                    this.selVersion = result[0];
                    this.selDataType = findItem(this.selVersion.data_type, 'code', this.dataTypeList);
                    for (const pp of this.years) {
                        if (pp.year === this.selVersion.year) {
                            this.planPeriod = pp;
                            break;
                        }
                    }
                }
            }
            if (!this.loading) {
                this.loadBipList();
                this.mountLoad++;
            }
        }, 2000);
    },

    beforeUpdate() {
        for (const row of this.showBipList) {
            if (row._showDetails) {
                row._rowVariant = 'info';
            }
        }
    },

    computed: {
        selectedLang() {
            return i18nService.locale;
        },

        editing() {
            return ([2, 3].includes(this.userLevel) && this.selVersion && this.selVersion !== null && this.selVersion.attribute);
        },

        totalFinans() {
            let sum = 0;
            for (const row of this.showBipList) {
                for (const fin of row?.addDatas.finace) {
                    sum += this.pprFinans(fin, row.period);
                }
            }
            return sum;
        }, // итого по заданному полю

        filterVersion() {
            let result = [];
            if (this.planPeriod && this.planPeriod !== null
                && this.selRegionBudget && this.selRegionBudget !== null
                && this.selDataType && this.selDataType !== null) {

                result = this.versionList.filter(row => (row.is_deleted === null || !row.is_deleted)
                    && row.year === this.planPeriod.year
                    && row.region_code === this.selRegionBudget.code
                    && row.data_type === parseInt(this.selDataType.code));
                result = result.sort(sortByField('date_start', 'desc'));
            }
            return result;
        }, // список для фильтра по версиям

        showBipList() {
            let result = this.bipList;

            if (this.selVersion) {
                result = result.filter(row => row.variant === this.selVersion.variant_uuid
                    || row.variant_recipient === this.selVersion.variant_uuid);
            }

            if (this.radio !== null) {
                switch (this.radio.value) {
                    case 0:
                        result = result.filter(row =>
                            (row.hasOwnProperty('newProject') && row.newProject === 0));
                        break;
                    case 1:
                        result = result.filter(row =>
                            (!row.hasOwnProperty('newProject')
                                || (row.hasOwnProperty('newProject') && row.newProject === 1)));
                        break;
                    default:
                        break;
                }
            }

            if (this.selRegion) {
                result = result.filter(row => row?.region === this.selRegion.code);
            }

            if (this.selKato) {
                result = this.result.filter(row => (row?.localityObject !== undefined
                        && row?.localityObject.code === this.selKato.code)
                    || (row?.locality === this.selKato.code));
            }

            if (this.selDirection) {
                result = result.filter(row => parseInt(row?.projectType) === parseInt(this.selDirection.code));
            }

            if (this.selObject !== null) {
                result = result.filter(row => parseInt(row?.object) === parseInt(this.selObject.code));
            }

            if (this.selStatus !== null) {
                result = result.filter(row => parseInt(row?.addDatas.status.code) === parseInt(this.selStatus.code));
            }

            if (this.selAbp) {
                result = result.filter(row => (parseInt(row.addDatas.abp.abp) === parseInt(this.selAbp.abp)));
            }

            const prgs = [];
            if (this.selPrg) {
                for (const bip of result) {
                    for (const fin of bip?.addDatas.finace) {
                        if (parseInt(this.selPrg.prg) === parseInt(fin.prg)) {
                            prgs.push(bip.id);
                        }
                    }
                }
                result = result.filter(row => prgs.includes(row.id));
            }

            const gps = [];
            if (this.selGp) {
                for (const bip of result) {
                    for (const gp of bip?.governmentProgram) {
                        if (this.selGp.code === gp.code) {
                            gps.push(bip.id);
                        }
                    }
                }
                result = result.filter(row => gps.includes(row.id));
            }

            return result;
        },
    },

    methods: {
        addNewStatus(old, data, bip_code, abp, gu, year, data_type, region, variant, comment_txt) {
            data.bip_code = bip_code,
                data.abp = abp;
            data.gu = gu;
            data.cur_year = year;
            data.region = region;
            data.data_type = data_type;
            data.variant = variant;
            data.comment_txt = comment_txt;
            data.user_id = this.userUiid;
            return Object.assign({old: old}, data);
        },

        async agreementEvent() {
            let firstStatus = null;
            let firstHeader = null;
            this.agreeList = [];
            this.agrBtnLst = {back: [], forward: []};
            for (const row of this.showBipList) {
                if (row.check && row.addDatas.status) {
                    if (firstStatus === null) {
                        firstStatus = row.addDatas.status.code;
                        firstHeader = row.header.hasOwnProperty('regional');
                    }
                    if (firstStatus !== row.addDatas.status.code) {
                        makeToast(this, 'danger', 'Согласование', 'Выберите БИПы с идентичным статусом!');
                        return;
                    }
                    if (firstHeader !== row.header.hasOwnProperty('regional')) {
                        makeToast(this, 'danger', 'Согласование', 'Выберите БИПы только с наличием или без Получателя!');
                        return;
                    }
                    this.agreeList.push(row.addDatas.status);
                }
            }
            if (this.agreeList.length === 0) {
                makeToast(this, 'warning', 'Согласование', 'Не выбрано ни один проект!');
                return;
            }

            const params = {modeCode: this.mode_code, operationCode: this.operationCode, agrCode: firstStatus};
            let result = null;
            try {
                const response = await fetch(`/api-py/get-step-next-back/${encodeURI(JSON.stringify(params))}`);
                result = await response.json();
            } catch (error) {
                makeToast(this, 'danger', 'Ошибка get-agreement-step-next-back', error.toString());
                return;
            }
            if (result.length === 0) {
                makeToast(this, 'warning', 'Согласование', 'Нет доступных шагов согласования!');
                return;
            }
            const back = [];
            const forward = [];
            for (const el of result) {
                if (el.stepType === 1) {
                    back.push(el);
                } else {
                    forward.push(el);
                }
            }
            this.$set(this.agrBtnLst, 'back', back);
            this.$set(this.agrBtnLst, 'forward', forward);

            this.$refs.refAgrModal.showEvent();
        },

        async agrClick(data) {
            const newAgree = [];
            const checkList = this.showBipList.filter(row => row.check && row.addDatas.status);
            for (const bip of checkList) {
                if (bip.header?.finalAbp === 1) {
                    newAgree.push(this.addNewStatus(bip.addDatas.status, data,
                        bip.code, bip.header.abp, bip.header.gu.code,
                        this.selVersion.year, this.selVersion.data_type,
                        this.selVersion.region_code, this.selVersion.variant_uuid,
                        data.commentTxt));
                } else {
                    if (this.selRegionBudget.code === bip.region_budget) { // сижу под Инициатором
                        console.log('сижу под Инициатором')
                        let versions_rec = [];
                        if ((bip.variant_recipient !== null) && (bip.variant_recipient.length > 0)) {
                            versions_rec = this.versionList.filter(row => (row.is_deleted === null || !row.is_deleted)
                                && row.attribute && row.variant_uuid === bip.variant_recipient);
                            if (versions_rec.length === 0) {
                                makeToast(this, 'warning', 'Внимание',
                                    'Версия бюджета (' + bip.variant_recipient + ') по Получателю не актуальна:'
                                    + ' Период - ' + this.planPeriod.name
                                    + ', Вид данных - ' + bip.header.regional.dataType
                                    + ', Регион бюджета - ' + bip?.district_budget);
                                return;
                            }
                        }
                        if (bip.variant_recipient === null || (bip.variant_recipient !== null && bip.variant_recipient.length === 0)) {
                            if ([3, 4].includes(parseInt(bip.header.dataType))) {
                                versions_rec = this.versionList.filter(row => (row.is_deleted === null || !row.is_deleted)
                                    && row.attribute
                                    && row.year === this.planPeriod.year
                                    && [3, 4].includes(row.data_type)
                                    && bip?.district_budget
                                    && row.region_code === bip?.district_budget);
                            } else {
                                versions_rec = this.versionList.filter(row => (row.is_deleted === null || !row.is_deleted)
                                    && row.attribute
                                    && row.year === this.planPeriod.year
                                    && row.data_type === parseInt(bip.header.dataType)
                                    && bip?.district_budget
                                    && row.region_code === bip?.district_budget);
                            }
                            if (versions_rec.length === 0) {
                                makeToast(this, 'danger', 'Внимание',
                                    'Отсутствует актуальная версия по Получателю:'
                                    + ' Период - ' + this.planPeriod.name
                                    + ', Вид данных - ' + bip.header.dataType
                                    + ', Регион бюджета - ' + bip?.district_budget);
                                return;
                            } else {
                                await findBipVariantNull(this, bip, bip.variant, versions_rec[0].variant_uuid);
                                if (this.findDB > 0) {
                                    return;
                                }
                            }
                        }
                        if ((versions_rec[0].data_type === bip.header.dataType)
                            || (versions_rec[0].data_type === 4 && bip.header.dataType === 3)
                            || (versions_rec[0].data_type === 3 && bip.header.dataType === 4)) {
                            this.$set(bip.header.regional, 'dataType', versions_rec[0].data_type);
                        }
                        this.$set(bip.header, 'dataType', this.selVersion.data_type);
                        this.$set(bip.header.regional, 'dataType', versions_rec[0].data_type);
                        // Статус для инициатора
                        newAgree.push(this.addNewStatus(bip.addDatas.status, data,
                            bip.code, bip.header.abp, bip.header.gu.code,
                            this.selVersion.year, this.selVersion.data_type,
                            this.selVersion.region_code, this.selVersion.variant_uuid,
                            data.commentTxt));
                        newAgree.push(this.addNewStatus(bip.addDatas.status, data,
                            bip.code, bip.header.regional.abp, bip.header.regional.gu.code,
                            versions_rec[0].year, versions_rec[0].data_type,
                            versions_rec[0].region_code, versions_rec[0].variant_uuid,
                            data.commentTxt));
                        await this.saveForm(bip, this.selVersion.variant_uuid, versions_rec[0].variant_uuid);
                    }
                    if (this.selRegionBudget.code === bip.district_budget) {
                        // сижу под Получателем
                        console.log('сижу под Получателем')
                        let versions = [];
                        if ((bip.variant !== null) && (bip.variant.length > 0)) {
                            versions = this.versionList.filter(row => (row.is_deleted === null || !row.is_deleted)
                                && row.attribute && row.variant_uuid === bip.variant);
                            if (versions.length === 0) {
                                makeToast(this, 'danger', 'Внимание',
                                    'Версия бюджета (' + bip.variant + ') по Инициатору не актуальна:'
                                    + ' Период - ' + this.planPeriod.name
                                    + ', Вид данных - ' + bip.header.dataType
                                    + ', Регион бюджета - ' + bip?.region_budget);
                                return;
                            }
                        }
                        if (bip.variant === null || (bip.variant !== null && bip.variant.length === 0)) {
                            if ([3, 4].includes(parseInt(bip.header.regional.dataType))) {
                                versions = this.versionList.filter(row => (row.is_deleted === null || !row.is_deleted)
                                    && row.attribute
                                    && row.year === this.planPeriod.year
                                    && [3, 4].includes(row.data_type)
                                    && bip?.region_budget
                                    && row.region_code === bip?.region_budget);
                            } else {
                                versions = this.versionList.filter(row => (row.is_deleted === null || !row.is_deleted)
                                    && row.attribute
                                    && row.year === this.planPeriod.year
                                    && row.data_type === parseInt(bip.header.regional.dataType)
                                    && bip?.region_budget
                                    && row.region_code === bip?.region_budget);
                            }
                            if (versions.length === 0) {
                                makeToast(this, 'warning', 'Внимание',
                                    'Отсутствует актуальная версия по Инициатору:'
                                    + ' Период - ' + this.planPeriod.name
                                    + ', Вид данных - ' + bip.header.regional.dataType
                                    + ', Регион бюджета - ' + bip?.region_budget);
                                return;
                            } else {
                                await findBipVariantRecNull(this, bip, versions[0].variant_uuid, bip.variant_recipient);
                                if (this.findDB > 0) {
                                    return;
                                }
                            }
                            if ((versions[0].data_type === bip.header.regional.dataType)
                                || (versions[0].data_type === 3 && bip.header.regional.dataType === 4)
                                || (versions[0].data_type === 4 && bip.header.regional.dataType === 3)) {
                                this.$set(bip.header, 'dataType', versions[0].data_type);
                            }
                        }
                        this.$set(bip.header, 'dataType', versions[0].data_type);
                        this.$set(bip.header.regional, 'dataType', this.selVersion.data_type);
                        // Статус для инициатора
                        newAgree.push(this.addNewStatus(bip.addDatas.status, data,
                            bip.code, bip.header.abp, bip.header.gu.code,
                            versions[0].year, versions[0].data_type,
                            versions[0].region_code, versions[0].variant_uuid,
                            data.commentTxt));
                        newAgree.push(this.addNewStatus(bip.addDatas.status, data,
                            bip.code, bip.header.regional.abp, bip.header.regional.gu.code,
                            this.selVersion.year, this.selVersion.data_type,
                            this.selVersion.region_code, this.selVersion.variant_uuid,
                            data.commentTxt));
                        this.saveForm(bip, versions[0].variant_uuid, this.selVersion.variant_uuid);
                    }
                }
            }
            await this.saveCosts(checkList);
            if (this.findDB === 0) {
                await this.postData('/api-py/set_bip_agreement_step', newAgree).then((response) => {
                    const list = response;
                    this.loadBipAgreement();
                    for (const res of list) {
                        if (res.result === 'error') {
                            makeToast(this, 'danger', 'Ошибка сохранения статуса', `${res.errTxt}`);
                        } else {
                            if (res.bip_denied) {
                                for (const row of this.showBipList) {
                                    if (row.code === res.code) {
                                        for (const fin of row.addDatas.finace) {
                                            this.$set(fin, 'plan1', 0);
                                            this.$set(fin, 'plan2', 0);
                                            this.$set(fin, 'plan3', 0);
                                        }
                                    }
                                }
                            }
                            makeToast(this, 'success', 'Сохранение статуса', `Успешно!`);
                        }
                    }
                });
                await setTimeout(() => {
                    this.updateAgreement();
                }, 3000);
            }
            for (const row of this.showBipList) {
                this.$set(row, 'check', false);
            }
        },

        async btnSaveForms() {
            try {
                const editList = this.showBipList.filter(row => row.addDatas.edited);
                await this.saveCosts(editList);
            } catch {
                makeToast(this, 'danger', 'Предупреждение', 'Ошибка сохранения данных - 3');
            }
        }, // кнопка сохранить

        chgData() {
            if (this.selVersion) {
                const data = {
                    year: this.planPeriod.year,
                    region: this.selRegionBudget.code,
                    dataType: parseInt(this.selDataType.code),
                    variant: this.selVersion.variant_uuid
                };
                this.$emit('chgData', data);
            }
        },

        async changeRegion() {
            await this.filter();
            if (this.selRegion) {
                this.selRegion.localityList = this.selRegion.localityList.filter(
                    row => Array.from(this.katos.keys()).includes(row.code));
                this.selKato = null;
            }
        },

        checkAccess(bip) {
            return checkAccess(bip, 'uebp_agree', 'oebp_agree_recipient', this.selRegionBudget.code);
        },

        commentClk(agrObj) {
            if (agrObj !== null) {
                this.$set(agrObj, 'mode_code', 'bip');
                this.$refs.refHistModal.showEvent(agrObj);
            }
        },

        createDetail(item) {
            const details = {};
            this.$set(details, 'table', []);
            const row3Fields = [];
            const mapBudget = new Map(); // Бюджетные
            const mapImpact = new Map(); // Влияния
            const mapNeeds = new Map(); // Потребность

            // first row of details - weights
            let weight = 0;
            const row1 = {name_ru: 'Вес индикатора'};
            if (item.hasOwnProperty('criteriaIndicators')) {
                for (const [key, value] of Object.entries(item?.criteriaIndicators)) {
                    if (key.includes('weight') && !isNaN(value) && value.length > 0) {
                        const fieldName = key.substr(0, key.length - 6); // наименование поля
                        const field = this.detailFields.filter(function (f) {
                            if (f.key === fieldName) {
                                return f;
                            }
                            return [];
                        }); // находим наим.поля из заданного перечня
                        if (field.length > 0 && ['Бюджетные', 'Влияние на ЦИ', 'Потребность'].includes(field[0]?.criteria_type)) {
                            const val = getNumber(value); // значение поля
                            this.$set(row1, fieldName, val); // запись в первую строку
                            weight += val; // общая сумма баллов по весу

                            switch (field[0].criteria_type) {
                                case 'Бюджетные':
                                    mapBudget.set(field[0], {weight: val});
                                    break;
                                case 'Влияние на ЦИ':
                                    mapImpact.set(field[0], {weight: val});
                                    break;
                                case 'Потребность':
                                    mapNeeds.set(field[0], {weight: val});
                                    break;
                                default:
                                    break;
                            }

                            row3Fields.push(fieldName); // ???
                        } else {
                            // console.log(item.id, key, fieldName); // поле, не вошедшее в перечень
                        }
                    }
                }
            }
            this.$set(row1, 'total', Math.round(weight));

            // second row of details
            let mark = 0; // баллы по проекту
            const row2 = {name_ru: item?.nameRu};
            if (item.hasOwnProperty('calcResult')) {
                for (const [key, value] of Object.entries(item?.calcResult)) {
                    if (key.includes('result')) {
                        const fieldName = key.substr(0, key.length - 7);
                        const field = this.detailFields.filter(function (f) {
                            if (f.key === fieldName) {
                                return f;
                            }
                            return [];
                        });
                        if (field.length > 0 && ['Бюджетные', 'Влияние на ЦИ', 'Потребность'].includes(field[0]?.criteria_type)) {
                            const val = getNumber(value);
                            this.$set(row2, fieldName, val);
                            mark += val; // общая сумма баллов по проекту

                            switch (field[0].criteria_type) {
                                case 'Бюджетные':
                                    const budget = mapBudget.get(field[0]);
                                    if (budget !== undefined) {
                                        this.$set(budget, 'mark', val);
                                    } else {
                                        mapBudget.set(field[0], {mark: val});
                                    }
                                    item.addDatas.budget += val;
                                    break;
                                case 'Влияние на ЦИ':
                                    const impact = mapImpact.get(field[0]);
                                    if (impact !== undefined) {
                                        this.$set(impact, 'mark', val);
                                    } else {
                                        mapImpact.set(field[0], {mark: val});
                                    }
                                    item.addDatas.impact += val;
                                    break;
                                case 'Потребность':
                                    const needs = mapNeeds.get(field[0]);
                                    if (needs !== undefined) {
                                        this.$set(needs, 'mark', val);
                                    } else {
                                        mapNeeds.set(field[0], {mark: val});
                                    }
                                    item.addDatas.needs += val;
                                    break;
                                default:
                                    break;
                            }
                        } else {
                            // console.log(item.id, key, fieldName);
                        }
                    }

                    if (key === 'totalResult') {
                        const val = getNumber(value);
                        this.$set(item.addDatas, 'total', val);
                        this.$set(row2, 'total', val);
                    }
                }
            }

            // third row of details
            const row3 = {name_ru: ''};
            if (item.hasOwnProperty('criteriaIndicators')) {
                for (const [key, value] of Object.entries(item?.criteriaIndicators)) {
                    if (row3Fields.includes(key)) {
                        this.$set(row3, key, value);
                    }
                }
            }
            this.$set(row3, 'dateStateExamination', item?._dateStateExamination);
            // заполняем вложенную таблицу
            details.table.push(row1);
            details.table.push(row2);
            details.table.push(row3);

            // формируем перечень полей для отображения
            this.$set(details, 'mapBudget', mapBudget);
            this.$set(details, 'mapImpact', mapImpact);
            this.$set(details, 'mapNeeds', mapNeeds);
            // формируем перечень полей для вложенной таблицы объекта
            const detfields = [{
                key: 'name_ru',
                label: 'Проект'
            }];
            for (const key of mapBudget.keys()) {
                detfields.push(key);
            }
            for (const key of mapImpact.keys()) {
                detfields.push(key);
            }
            for (const key of mapNeeds.keys()) {
                detfields.push(key);
            }
            detfields.push(
                {
                    key: 'total',
                    label: 'Балл'
                });
            this.$set(details, 'fields', detfields);

            // округляем итоговые данные и передаем во внешнюю таблицу
            this.$set(item, 'budget', this.getFieldValue(item.addDatas, 'budget', 'float'));
            this.$set(item, 'impact', this.getFieldValue(item.addDatas, 'impact', 'float'));
            this.$set(item, 'needs', this.getFieldValue(item.addDatas, 'needs', 'float'));
            this.$set(item, 'total', this.getFieldValue(item.addDatas, 'total', 'float'));
            this.$set(item.addDatas, 'details', details);
        },

        defineVariant() {
            const vers = this.versionList.filter(row => (row.is_deleted === null || !row.is_deleted)
                && row.year === this.planPeriod.year
                && row.region_code === this.selRegionBudget.code
                && row.data_type === 1)
            // vers.sort(sortByField('date_start', 'asc'));
            const versStart = this.versionList.filter(row => (row.is_deleted === null || !row.is_deleted)
                && row.year === this.planPeriod.year
                && row.region_code === this.selRegionBudget.code
                && row.data_type === 1
                && row.start_date === vers[0].start_date)
            // versStart.sort(sortByField('id', 'asc'));
            if (versStart.length() > 0) {
                if (versStart[0].variant_uuid === this.selVersion.variant_uuid) {
                    return true;
                }
            }
            return false;
        }, // определяет соответсвует ли данная версия для удаления

        async filter() {
            this.filterUpdate();
            this.submitReport();
            await this.submitReport68();
            this.chgData();
        },

        filterUpdate() {
            const regionsBudget = new Map();
            const regions = new Map();
            const projects = new Map();
            const objects = new Map();
            const statuses = new Map();
            const abps = new Map();
            const prgs = new Map();
            const gps = new Map();
            this.katos = new Map();

            this.loading = false;

            for (const row of this.showBipList) {
                regionsBudget.set(row.addDatas.region, row.addDatas.region);
                regions.set(row.region, row.region);
                if (row.hasOwnProperty('localityObject') && row.localityObject !== null) {
                    this.katos.set(row.localityObject.code, row.localityObject.code);
                }
                if (row.locality) {
                    this.katos.set(row.locality, row.locality);
                }
                projects.set(parseInt(row.projectType), row.projectType);
                objects.set(parseInt(row.object), row.object);
                if (row.addDatas.status) {
                    statuses.set(parseInt(row.addDatas.status.code), row.addDatas.status.code);
                }
                abps.set(parseInt(row.addDatas.abp.abp), row.addDatas.abp.abp);
                for (const fin of row.addDatas.finace) {
                    prgs.set(parseInt(fin.prg), fin.prg);
                }
                for (const gp of row.governmentProgram) {
                    gps.set(gp.code, gp.code);
                }
            }
            this.regionFilter = this.regionList.filter(row => Array.from(regions.keys()).includes(row.code));
            this.directionFilter = this.directionList.filter(row => Array.from(projects.keys()).includes(parseInt(row.code)));
            this.objectFilter = this.objectList.filter(row => Array.from(objects.keys()).includes(parseInt(row.code)));
            this.statusFilter = this.statusList.filter(row => Array.from(statuses.keys()).includes(parseInt(row.code)));
            this.abpFilter = this.abpList.filter(row => Array.from(abps.keys()).includes(parseInt(row.abp)));
            if (this.selAbp) {
                this.prgFilter = this.selAbp.prgList.filter(row => Array.from(prgs.keys()).includes(parseInt(row.prg)))
            }
            this.gpFilter = this.gpList.filter(row => Array.from(gps.keys()).includes(row.code));
        },

        findItem(code, field, list) {
            return findItem(code, field, list);
        },

        async getCostData(variant) {
            try {
                let response = await fetch('/api-py/get-cost-data-by-var/' + variant);
                response = await response.json();
                this.costsMap = await new Map(Object.entries(response));
            } catch (error) {
                makeToast(this, 'danger', 'Ошибка запроса getCostData', error.toString());
            }
        },

        getFieldValue(item, field, type, fix = 1) {
            if (item !== undefined && item !== null && item[field] !== undefined) {
                switch (type) {
                    case 'string':
                        return item[field];
                    case 'int':
                        return parseInt(item[field]);
                    case 'float':
                        return parseFloat(parseFloat(item[field]).toFixed(fix));
                    default:
                        break;
                }
            } else {
                switch (type) {
                    case 'string':
                        return '';
                    case 'int':
                        return 0;
                    case 'float':
                        return 0;
                    default:
                        break;
                }
            }
            return null;
        },

        getNumber(value, digit = 1) {
            return getNumber(value, digit);
        },

        async getSigners(params) {
            try {
                const response = await fetch('/api-py/get_signatories_data_by_budget_variants/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8'
                    },
                    body: JSON.stringify(params)
                });
                return await response.json();
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка запроса подписантов');
            }
        }, // список подписантов

        inputFixed(elem, item, field, value, digit = 1) {
            this.$set(item, field, getNumber(value, digit));
            this.$set(elem.addDatas, 'edited', true);
        }, // форматирует введенное значение до digit цифр после запятой

        isNextStep(bip) {
            const result = [];
            if (bip && bip.addDatas.status && bip.addDatas.status.steps) {
                for (const step of bip.addDatas.status.steps) {
                    if (step.step_type === 2) {
                        result.push(step);
                    }
                }
            }
            return result.length > 0;
        },

        keyPress: function (event, pattern) {
            // const regex = new RegExp('^[0-9]+$');
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            // const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
            return true;
        }, // вводит по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadBipAgreement() {
            if (this.selVersion) {
                const params = JSON.stringify({
                    variant: this.selVersion.variant_uuid,
                    mode_code: this.mode_code,
                    operation_code: this.operationCode
                });
                try {
                    if (this.operationCode.length > 0) {
                        this.agrMap = new Map();
                        let response = await fetch(`/api-py/get-bip-agreement-by-params/${encodeURI(params)}`);
                        response = await response.json();
                        this.agrMap = await new Map(Object.entries(response));
                    }
                } catch (error) {
                    makeToast(this, 'danger', 'Ошибка get-bip-agreement', error.toString());
                }
            }
        },

        async loadBipList() {
            let response = [];
            let serverList = [];
            this.bipList = [];
            try {
                if (this.selVersion) {
                    this.pBar = 0;
                    this.loading = true;
                    await this.getCostData(this.selVersion.variant_uuid);
                    response = await fetch('/api-py/bip-forms/' + this.selVersion.variant_uuid);
                    response = await response.json();
                    serverList = await response.bipList;

                    await this.loadFilters(new Map(Object.entries(response.abpMap)),
                        new Map(Object.entries(response.gpMap)));
                    this.pBar = 10;
                } else {
                    this.loading = false;
                    makeToast(this, 'warning', 'Внимание!', 'По выбранному фильтру нет актуальных версий!');
                }
            } catch (error) {
                makeToast(this, 'danger', 'Ошибка загрузки serverList', error.toString());
            }

            setTimeout(async () => {
                for (const bip of serverList) {
                    try {
                        const bipObject = BipProjectDataClass.fromJSON(bip);
                        if (!bipObject?.isDeleted && ![35, 36, 37, 38].includes(parseInt(bipObject.object))) {
                            this.$set(bipObject, 'addDatas', {});
                            this.$set(bipObject, 'check', false);
                            this.$set(bipObject.addDatas, 'edited', false);
                            this.$set(bipObject.addDatas, 'link', '/#/bip/bip-form?bip_id=' + bipObject.id);
                            this.$set(bipObject.addDatas, 'abp', {abp: null, text: ''});

                            // createDetail
                            try {
                                this.createDetail(bipObject);
                            } catch (error) {
                                console.log('err(createDetail) =>', bipObject.id, error);
                            }
                            // region/locality
                            try {
                                const reg = findItem(bipObject?.region, 'code', this.regionList);
                                const loc = (bipObject?.localityObject && bipObject?.localityObject !== null ?
                                    bipObject?.localityObject :
                                    findItem(bipObject?.locality, 'code', reg.localityList));
                                this.$set(bipObject.addDatas, 'reg_loc', reg.name_ru + '/ ' + loc.name_ru);
                            } catch (error) {
                                console.log('err(reg_loc) =>', bipObject.id, bipObject?.region, bipObject?.locality, bipObject?.localityObject, error);
                            }
                            // project type/object
                            try {
                                const project = findItem(bipObject?.projectType, 'code', this.directionList);
                                const object = findItem(bipObject?.object, 'code', this.objectList);
                                this.$set(bipObject.addDatas, 'type_obj', project.name_ru + '/ ' + object.name_ru);
                            } catch (error) {
                                console.log('err(type_obj) =>', bipObject?.projectType, bipObject?.object, error);
                            }
                            // governmentProgram
                            try {
                                const govProgram = Object.assign({}, bipObject?.governmentProgram);
                                let str = '';
                                // if (govProgram !== null) {
                                if (typeof (govProgram) === 'string') {
                                    const gpEl = findItem(govProgram, 'code', this.gpList);
                                    console.log('gpEl (bip-uebp):', gpEl)
                                    this.$set(bipObject, 'governmentProgram', []);
                                    bipObject.governmentProgram.push(gpEl);
                                }
                                for (const gp of bipObject?.governmentProgram) {
                                    if (str.length === 0) {
                                        str = gp.name_ru;
                                    } else {
                                        str = str + ', ' + gp.name_ru;
                                    }
                                }
                                // }
                                this.$set(bipObject.addDatas, 'program', str);
                            } catch (error) {
                                console.log('err(gos_program) =>', bipObject?.id, error);
                            }
                            // newProject
                            try {
                                this.$set(bipObject.addDatas, 'newProject_txt', '');
                                if (bipObject.hasOwnProperty('newProject')) {
                                    const np = findItem(bipObject?.newProject, 'value', this.radioList);
                                    if (!np.hasOwnProperty('code')) {
                                        this.$set(bipObject.addDatas, 'newProject_txt', np.text);
                                    }
                                }
                            } catch (error) {
                                console.log('err(newProject) =>', bipObject?.id, error);
                            }
                            // addDatas
                            try {
                                this.$set(bipObject.addDatas, 'finace', []);
                                // инициатор
                                if (bipObject.variant === this.selVersion.variant_uuid) {
                                    this.$set(bipObject.addDatas, 'region', bipObject.region_budget);
                                    if (bipObject?.header?.abp) {
                                        const abp = findItem(bipObject?.header?.abp, 'abp', this.abpList);
                                        this.$set(bipObject.addDatas, 'abp', abp);
                                        const finace = [];
                                        bipObject?.newFinace.sort(sortByField('ppr'));
                                        for (const fin of bipObject?.newFinace) {
                                            if (bipObject.code
                                                && bipObject?.header.abp && fin.prg
                                                && bipObject.variant) {

                                                const prg = findItem(fin.prg, 'prg', abp.prgList);
                                                if (!prg.hasOwnProperty('code')) {
                                                    this.$set(fin, 'pgr', prg.pgr);
                                                }

                                                const newCost = {
                                                    'gr': fin.gr,
                                                    'pgr': fin.pgr,
                                                    'abp': bipObject?.header?.abp,
                                                    'prg': fin.prg,
                                                    'ppr': fin.ppr,
                                                    'spf': fin.spf
                                                };

                                                const plan1 = this.costsMap.get(doRowKey(this,
                                                    [bipObject.code,
                                                        bipObject?.header.abp, fin.prg, fin.ppr, fin.spf,
                                                        this.planPeriod.year, bipObject.variant]));
                                                this.$set(newCost, 'plan1', plan1 ? plan1.value : 0);
                                                this.$set(newCost, 'fact1', getNumber(fin[this.planPeriod.year]));

                                                const plan2 = this.costsMap.get(doRowKey(this,
                                                    [bipObject.code,
                                                        bipObject?.header.abp, fin.prg, fin.ppr, fin.spf,
                                                        this.planPeriod.year + 1, bipObject.variant]));
                                                this.$set(newCost, 'plan2', plan2 ? plan2.value : 0);
                                                this.$set(newCost, 'fact2', getNumber(fin[this.planPeriod.year + 1]));

                                                const plan3 = this.costsMap.get(doRowKey(this,
                                                    [bipObject.code,
                                                        bipObject?.header.abp, fin.prg, fin.ppr, fin.spf,
                                                        this.planPeriod.year + 2, bipObject.variant]));
                                                this.$set(newCost, 'plan3', plan3 ? plan3.value : 0);
                                                this.$set(newCost, 'fact3', getNumber(fin[this.planPeriod.year + 2]));

                                                this.$set(newCost, 'ppr_cost', this.pprFinans(fin, bipObject?.period));

                                                finace.push(newCost);
                                            }
                                        }
                                        this.$set(bipObject.addDatas, 'finace', finace);
                                    }
                                }
                                // получатель
                                if (bipObject.variant_recipient === this.selVersion.variant_uuid) {
                                    this.$set(bipObject.addDatas, 'region', bipObject.district_budget);
                                    if (bipObject?.header?.regional.abp) {
                                        const abp = findItem(bipObject?.header?.regional.abp, 'abp', this.abpList);
                                        this.$set(bipObject.addDatas, 'abp', abp);

                                        const finace = [];
                                        if (bipObject?.newFinaceRegional.length > 0) {
                                            bipObject?.newFinaceRegional.sort(sortByField('ppr'));
                                            for (const fin of bipObject?.newFinaceRegional) {
                                                if (bipObject?.header?.regional.abp && fin.prg
                                                    && bipObject.code && bipObject.variant_recipient) {

                                                    const prg = findItem(fin.prg, 'prg', abp.prgList);
                                                    if (!prg.hasOwnProperty('code')) {
                                                        this.$set(fin, 'pgr', prg.pgr);
                                                    }

                                                    const newCost = {
                                                        'gr': fin.gr,
                                                        'pgr': fin.pgr,
                                                        'abp': bipObject?.header?.regional.abp,
                                                        'prg': fin.prg,
                                                        'ppr': fin.ppr,
                                                        'spf': fin.spf
                                                    };

                                                    const plan1 = this.costsMap.get(doRowKey(this,
                                                        [bipObject.code,
                                                            bipObject?.header?.regional.abp, fin.prg, fin.ppr, fin.spf,
                                                            this.planPeriod.year, bipObject.variant_recipient]));
                                                    this.$set(newCost, 'plan1', plan1 ? plan1.value : 0);
                                                    this.$set(newCost, 'fact1', getNumber(fin[this.planPeriod.year]));

                                                    const plan2 = this.costsMap.get(doRowKey(this,
                                                        [bipObject.code,
                                                            bipObject?.header?.regional.abp, fin.prg, fin.ppr, fin.spf,
                                                            this.planPeriod.year + 1, bipObject.variant_recipient]));
                                                    this.$set(newCost, 'plan2', plan2 ? plan2.value : 0);
                                                    this.$set(newCost, 'fact2', getNumber(fin[this.planPeriod.year + 1]));

                                                    const plan3 = this.costsMap.get(doRowKey(this,
                                                        [bipObject.code,
                                                            bipObject?.header?.regional.abp, fin.prg, fin.ppr, fin.spf,
                                                            this.planPeriod.year + 2, bipObject.variant_recipient]));
                                                    this.$set(newCost, 'plan3', plan3 ? plan3.value : 0);
                                                    this.$set(newCost, 'fact3', getNumber(fin[this.planPeriod.year + 2]));

                                                    this.$set(newCost, 'ppr_cost', this.pprFinans(fin, bipObject?.period));

                                                    finace.push(newCost);
                                                }
                                            }
                                            this.$set(bipObject.addDatas, 'finace', finace);
                                        }
                                    }
                                }

                                this.$set(bipObject['addDatas'], 'status', this.agrMap.get(bipObject.code));

                            } catch (error) {
                                console.log('err(finace) =>', bipObject?.id, error);
                            }
                            // добавление БИПов в массив
                            this.bipList.push(bipObject);
                            this.pBar++;
                        }
                    } catch (error) {
                        console.log(bip.code + ':' + bip.id + error.toString());
                    }
                }
                console.log('BIP UEBP => ', this.selVersion.variant_uuid, this.bipList.length);

                await this.filter();
                this.pBar = 100;
            }, 3000);
        }, // загрузка списка БИПов

        async loadBipCifList() {
            try {
                const response = await fetch('/api-py/dictionary/bip_cif_list');
                this.bipCifList = await response.json();
                this.bipCifList.sort(sortByField('code'));
            } catch (error) {
                makeToast(this, 'danger', 'Ошибка запроса loadBipCifList', error.toString());
            }
        },

        async loadDataTypeList() {
            try {
                this.dataTypeList = await fetch('/api-py/dictionary/budget_data_types/')
                    .then(response => response.json());
                this.dataTypeList = this.dataTypeList.filter(row => ['1', '2', '3', '4'].includes(row.code));
                // this.selDataType = this.dataTypeList[0];
            } catch (error) {
                this.dataTypeList = [];
                makeToast(this, 'danger', 'Ошибка загрузки loadDataTypeList', error.toString());
            }
        },

        loadDetailFields() {
            for (const item of this.bipCifList) {
                const el = {};
                this.$set(el, 'key', item?.code);
                this.$set(el, 'label', item?.name_ru);
                this.$set(el, 'criteria_type', item?.criteria_type);
                this.detailFields.push(el);
            }
            this.detailFields.push({
                key: 'total',
                label: 'Балл'
            });
        },

        async loadDirectionList() {
            try {
                this.directionList = await fetch('/api-py/dictionary/bip_project_type_list/')
                    .then(response => response.json());
            } catch (error) {
                makeToast(this, 'danger', 'Ошибка загрузки loadDirectionList', error.toString());
            }
        },

        async loadFilters(abpMap, gpMap) {
            try {
                // фильтр АБП
                this.abpList = [];
                for (const [key, value] of abpMap.entries()) {
                    if (value.prgList !== null) {
                        value.prgList.sort(sortByField('prg'));
                    }
                    this.abpList.push(value);
                }
                this.abpList.sort(sortByField('abp'));

                // фильтр гос.программ
                this.gpList = [];
                for (const [key, value] of gpMap.entries()) {
                    this.gpList.push(value);
                }
                this.gpList.sort(sortByField('code'));
            } catch (error) {
                makeToast(this, 'danger', 'Ошибка выполнения loadFilters', error.toString());
            }
        }, // загрузка фильтров АБП, ГП

        async loadObjectList() {
            try {
                this.objectList = await fetch('/api-py/dictionary/bip_project_object_list/')
                    .then(response => response.json());
            } catch (error) {
                makeToast(this, 'danger', 'Ошибка загрузки loadObjectList', error.toString());
            }
        },

        async loadOperations() {
            let result = null;
            try {
                result = await fetch(encodeURI(`/api/um/module/link?user=${store.state.user.sub}&modulecode=004.003.002`))
                    .then(response => response.json());

                if (result.operations) {
                    this.operationCode = result.operations;
                }
                if (result.accessLevel) {
                    this.userLevel = result.accessLevel;
                }
            } catch (error) {
                makeToast(this, 'danger', 'Ошибка загрузки операций пользователя', error.toString());
                return;
            }
            if (result === null) {
                await this.loadOperations();
            }
        },

        async loadRegionList() {
            if (this.location) {
                let response = await fetch('/api-py/get-kato-regions/' + this.location.code);
                response = await response.json();
                for (const region of response) {
                    if (region.code.substring(2, 3) !== '0') {
                        try {
                            let locals = await fetch('/api-py/get-kato-localities/' + region.code.substring(0, 4));
                            locals = await locals.json();
                            locals.sort(sortByField('name_ru'));
                            this.$set(region, 'localityList', locals);
                            this.regionList.push(region);
                        } catch (error) {
                            makeToast(this, 'danger', 'Ошибка запроса loadRegionList', error.toString());
                        }
                    }
                }
                this.regionList.sort(sortByField('name_ru'));
            }
        }, // загрузка районов по области

        async loadStatusList() {
            try {
                this.statusList = await fetch('/api-py/dictionary/agreement_status/')
                    .then(response => response.json());
                this.statusList.sort(sortByField('name_ru'));
            } catch (error) {
                makeToast(this, 'danger', 'Ошибка загрузки loadStatusList', error.toString());
            }
        },

        async loadUserAbps() {
            this.userAbps = [];
            try {
                let response = await fetch('/api-py/user-abp/' + this.userUiid);
                response = await response.json();
                for (const abp of response) {
                    this.userAbps.push(abp.abp);
                }
            } catch (error) {
                makeToast(this, 'danger', 'Ошибка загрузки ABP пользователя', error.toString());
            }
        }, // перечень доступных АБП пользователя

        newCost(gr, pgr, abp, prg, ppr, spf, value, data_type, year, region, bip_code, cur_year, variant) {
            const item = {
                gr: gr,
                pgr: pgr,
                abp: abp,
                prg: prg,
                ppr: ppr,
                spf: spf,
                value: value,
                data_type: data_type,
                year: year,
                region: region,
                bip_code: bip_code,
                cur_year: cur_year,
                variant: variant,
                user_name: this.userUiid
            };
            return item;
        }, // формирует элемент для сохранение в БД

        openBip(item) {
            this.bipProjectData = item;
            this.showForm = true;
        },

        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            const refItem = this.$refs[refName];
            setTimeout(() => refItem.$el.focus(), 100);
        },

        padLeadingZeros(num, size) {
            return padLeadingZeros(num, size);
        }, // добавляет 0-ли перед num до size-значного размера

        async postData(url = '', data = {}) {
            // Default options are marked with *
            const response = await fetch(url, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *client
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            });
            return await response.json(); // parses JSON response into native JavaScript objects
        },

        pprFinans(fin, period) {
            let sum = 0;
            for (const [key, value] of Object.entries(fin)) {
                const y = parseInt(key);
                if ((typeof (y) === 'number')
                    && (period._begYear <= y && y <= period._endYear)) {
                    sum += getNumber(value);
                }
            }
            return sum;
        }, // итого суммы по newFinace

        async saveCosts(list) {
            const costs = [];
            for (const row of list) {
                for (const fin of row.addDatas.finace) {
                    costs.push(this.newCost(fin.gr, fin.pgr, fin.abp, fin.prg, fin.ppr, fin.spf,
                        fin.plan1, this.selVersion.data_type, (this.planPeriod.year),
                        row.addDatas.region, row.code, this.planPeriod.year, this.selVersion.variant_uuid));
                    costs.push(this.newCost(fin.gr, fin.pgr, fin.abp, fin.prg, fin.ppr, fin.spf,
                        fin.plan2, this.selVersion.data_type, (this.planPeriod.year + 1),
                        row.addDatas.region, row.code, this.planPeriod.year, this.selVersion.variant_uuid));
                    costs.push(this.newCost(fin.gr, fin.pgr, fin.abp, fin.prg, fin.ppr, fin.spf,
                        fin.plan3, this.selVersion.data_type, (this.planPeriod.year + 2),
                        row.addDatas.region, row.code, this.planPeriod.year, this.selVersion.variant_uuid));
                }
            }
            if (costs.length > 0) {
                await this.saveVariant(costs);
            }
        }, // list for saving

        async saveForm(form, versions, versions_rec) {
            this.$set(form, 'variant', versions);
            this.$set(form, 'variant_recipient', versions_rec);
            if (form.versions) {
                form.versions.push(form.variant);
                form.versions.push(form.variant_recipient);
            }

            const newElem = {
                form: form,
                variant: form.variant,
                variant_recipient: form.variant_recipient,
                user_name: this.userUiid
            };
            await this.postData('/api-py/save-bip-form', newElem).then((data) => {
                if (data.result) {
                    makeToast(this, 'success', 'Сохранение', form.code + ' сохранен');
                } else {
                    makeToast(this, 'danger', 'Ошибка сохранения', form.code + ' => ' + data.error);
                }
            });
        },

        async saveVariant(values) {
            try {
                const response = await fetch('/api-py/save-cost-budget-data/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();

                if (result.result === 'success') {
                    makeToast(this, 'success', 'Сообщение', 'Данные сохранены');
                }
            } catch {
                makeToast(this, 'danger', 'Предупреждение', 'Ошибка сохранения данных - 4');
            }
        }, // сохранение записей в БД

        showDetails(row) {
            row._showDetails = !row._showDetails;
            if (row._showDetails) {
                row._rowVariant = 'info';
            } else {
                row._rowVariant = '';
            }
        },

        setMapPpr(
            finance,
            map
        ) {
            for (const obj of finance) {
                if (obj.prg === this.selPrg?.prg) {
                    let pprItem;
                    if (obj.ppr) {
                        pprItem = findItem(
                            obj.ppr,
                            "ppr",
                            this.selPrg?.pprList
                        );
                    } else {
                        pprItem = {
                            gr: obj.gr,
                            pgr: obj.pgr,
                            abp: this.selAbp.abp,
                            prg: obj.prg,
                            ppr: -1,
                            type: 5,
                            develop_type: 1,
                            name_ru: "",
                            name_kk: "",
                            budget_level_id: [7],
                        };
                    }

                    switch (obj.ppr ?? -1) {
                        case -1:
                            this.$set(pprItem, "source", "");
                            break;
                        case 5:
                            this.$set(pprItem, "source", "ВЗ");
                            break;
                        case 11:
                            this.$set(pprItem, "source", "РБ");
                            break;
                        case 15:
                            this.$set(pprItem, "source", "МБ");
                            break;
                        case 32:
                            this.$set(pprItem, "source", "НФ");
                            break;
                        default:
                            this.$set(
                                pprItem,
                                "source",
                                String(obj.ppr || 9).padStart(3, "0")
                            );
                            break;
                    }
                    map.set(obj.ppr ?? -1, pprItem);
                }
            }
        },

        setFinances(
            finance,
            ppr,
            finances
        ) {
            for (const obj of finance) {
                if (obj.prg === this.selPrg?.prg) {
                    for (const [key, value] of Object.entries(obj)) {
                        if (
                            (obj.ppr === ppr || (obj.ppr === null && ppr === -1)) &&
                            parseInt(key) === this.planPeriod.year - 3
                        ) {
                            finances.plan_3 = getNumber(parseFloat(value));
                        }
                        if (
                            (obj.ppr === ppr || (obj.ppr === null && ppr === -1)) &&
                            parseInt(key) === this.planPeriod.year - 2
                        ) {
                            finances.plan_2 = getNumber(parseFloat(value));
                        }
                        if (
                            (obj.ppr === ppr || (obj.ppr === null && ppr === -1)) &&
                            parseInt(key) === this.planPeriod.year - 1
                        ) {
                            finances.plan_1 = getNumber(parseFloat(value));
                        }
                        if (
                            (obj.ppr === ppr || (obj.ppr === null && ppr === -1)) &&
                            parseInt(key) === this.planPeriod.year
                        ) {
                            finances.fin_0 = getNumber(parseFloat(value));
                        }
                        if (
                            (obj.ppr === ppr || (obj.ppr === null && ppr === -1)) &&
                            parseInt(key) === this.planPeriod.year + 1
                        ) {
                            finances.fin_1 = getNumber(parseFloat(value));
                        }
                        if (
                            (obj.ppr === ppr || (obj.ppr === null && ppr === -1)) &&
                            parseInt(key) === this.planPeriod.year + 2
                        ) {
                            finances.fin_2 = getNumber(parseFloat(value));
                        }
                        if (
                            (obj.ppr === ppr || (obj.ppr === null && ppr === -1)) &&
                            parseInt(key) > this.planPeriod.year + 2
                        ) {
                            finances.fin_3 += getNumber(parseFloat(value));
                        }
                    }
                }
            }
        },

        submitReport() {
            const dataList = [];
            for (const bip of this.showBipList) {
                bip.total = (bip.total ? bip.total : 0);
                const finList = this.setFinList(bip.addDatas.finace);
                const item = {
                    code: bip.code,
                    reg_loc: bip.addDatas.reg_loc,
                    nameRu: bip.nameRu,
                    newProject_txt: bip.addDatas.newProject_txt,
                    type_obj: bip.addDatas.type_obj,
                    program: bip.addDatas.program ?? "",
                    total: bip.total,
                    totalCost: bip.totalCost,
                    statusItem: (bip.addDatas.status ? bip.addDatas.status.name_ru : findItem(1, 'code', this.statusList).name_ru),
                    abp: bip.addDatas.abp.abp,
                    dataList: finList
                };
                dataList.push(item);
            }


            // объект для передачи в отчет
            const params = {
                planPeriod: this.planPeriod,
                year: this.planPeriod !== null ? this.planPeriod.year : 0,
                selRegionBudget: this.selRegionBudget,
                selDataType: this.selDataType,
                selVersion: this.selVersion,
                selRegion: this.selRegion,
                selProject: this.selDirection,
                selObject: this.selObject,
                selStatus: this.selStatus,
                selAbp: this.selAbp,
                selPrg: this.selPrg ?? null,
                selPpr: this.selPpr,
                selGp: this.selGp,
                radio: this.radio,
                datas: dataList
            }
            this.$emit('submitReport', params);
        },

        setFinList(finace) {
            const datas = [];
            const mapPrg = new Map();
            for (const fin of finace) {
                let curFin = undefined;
                if (this.selPrg) {
                    if (fin.prg === this.selPrg.prg) {
                        if (this.selPpr) {
                            if (fin.ppr === this.selPpr.ppr) {
                                curFin = fin;
                            }
                        } else {
                            curFin = fin;
                        }
                    }
                } else {
                    curFin = fin;
                }
                if (curFin) {
                    const item = {};
                    this.$set(item, 'prg', padLeadingZeros(curFin.prg, 3));
                    this.$set(item, 'ppr', padLeadingZeros(curFin.ppr, 3));
                    this.$set(item, 'spf', padLeadingZeros(curFin.spf, 3));
                    this.$set(item, 'pprCost', curFin.ppr_cost);
                    this.$set(item, 'fact1', curFin.fact1);
                    this.$set(item, 'fact2', curFin.fact2);
                    this.$set(item, 'fact3', curFin.fact3);
                    this.$set(item, 'plan1', curFin.plan1);
                    this.$set(item, 'plan2', curFin.plan2);
                    this.$set(item, 'plan3', curFin.plan3);
                    datas.push(item);
                }
            }
            return datas;
        },

        async submitReport68() {
            if (this.selAbp !== null && this.selPrg !== null) {
                const datasMap = new Map();
                let gu = null;
                const guMap = new Map();
                const pprMap = new Map();
                for (const bip of this.showBipList) {
                    try {
                        /** Сбор всевозможных подпрограмм (ppr) из newFinace/newFinaceRegional */
                        if (bip.variant === this.selVersion.variant_uuid) {
                            if (this.selAbp.abp === bip.header.abp) {
                                gu = bip.header.gu;
                            }

                            this.setMapPpr(bip.newFinace, pprMap);
                        }

                        if (
                            bip.variant_recipient === this.selVersion.variant_uuid
                        ) {
                            if (this.selAbp.abp === bip.header.regional.abp) {
                                gu = bip.header.regional.gu;
                            }

                            this.setMapPpr(bip.newFinaceRegional, pprMap);
                        }

                        for (const tab of pprMap.values()) {
                            const pprTab = tab;

                            /** Вычислить сумму финансирование до начала планового периода (oldFinace) */
                            let coast_3 = 0;
                            let coast_2 = 0;
                            let coast_1 = 0;
                            for (const obj of bip?.oldFinace) {
                                if (obj.prg === this.selPrg.prg) {
                                    if (
                                        (obj.ppr === pprTab.ppr ||
                                            (obj.ppr === null &&
                                                pprTab.ppr === -1)) &&
                                        obj.year <= this.planPeriod.year - 3
                                    ) {
                                        coast_3 += getNumber(obj.totalCoast);
                                    }
                                    if (
                                        (obj.ppr === pprTab.ppr ||
                                            (obj.ppr === null &&
                                                pprTab.ppr === -1)) &&
                                        obj.year === this.planPeriod.year - 2
                                    ) {
                                        coast_2 += getNumber(obj.totalCoast);
                                    }
                                    if (
                                        (obj.ppr === pprTab.ppr ||
                                            (obj.ppr === null &&
                                                pprTab.ppr === -1)) &&
                                        obj.year === this.planPeriod.year - 1
                                    ) {
                                        coast_1 += getNumber(obj.totalCoast);
                                    }
                                }
                            }

                            /** Вычислить сумму финансирование в период планового периода (newFinace) */
                            const finances = {
                                plan_1: 0,
                                plan_2: 0,
                                plan_3: 0,
                                fin_0: 0,
                                fin_1: 0,
                                fin_2: 0,
                                fin_3: 0,
                            };
                            if (bip.variant === this.selVersion.variant_uuid) {
                                this.setFinances(
                                    bip.newFinace,
                                    pprTab.ppr,
                                    finances
                                );
                            }
                            if (
                                bip.variant_recipient ===
                                this.selVersion.variant_uuid
                            ) {
                                this.setFinances(
                                    bip.newFinaceRegional,
                                    pprTab.ppr,
                                    finances
                                );
                            }

                            /** Перечень прикрепленных документов */
                            let note = "";
                            for (const f of bip.files) {
                                if (note.length === 0) {
                                    note += f.text;
                                } else {
                                    note += "; " + f.text;
                                }
                            }

                            const item = {
                                object: bip.object,
                                name: "",
                                period:
                                    bip.period.begYear + " - " + bip.period.endYear,
                                source: pprTab.source,
                                totalCost: bip.totalCost,
                                plan_3: finances.plan_3,
                                coast_3: coast_3,
                                plan_2: finances.plan_2,
                                coast_2: coast_2,
                                plan_1: finances.plan_1,
                                coast_1: coast_1,
                                fin: finances.fin_0,
                                fin_1: finances.fin_1,
                                fin_2: finances.fin_2,
                                fin_3: finances.fin_3,
                                note: note,
                            };

                            item.name =
                                this.selectedLang === "ru"
                                    ? bip.nameRu
                                    : this.selectedLang === "kk"
                                        ? bip.nameKk
                                        : bip.nameRu;

                            let list = datasMap.get(pprTab.ppr);
                            if (!list) list = [];

                            let mapGu = guMap.get(pprTab.ppr);
                            if (!mapGu) mapGu = new Map();

                            if (
                                Math.round(
                                    item.plan_3 +
                                    item.plan_2 +
                                    item.plan_1 +
                                    item.coast_3 +
                                    item.coast_2 +
                                    item.coast_1 +
                                    item.fin +
                                    item.fin_1 +
                                    item.fin_2 +
                                    item.fin_3
                                ) > 0
                            ) {
                                list.push(item);

                                if (bip.header.regional && bip.header.regional.gu) {
                                    mapGu.set(
                                        bip?.header?.regional.gu.code,
                                        bip?.header?.regional.gu
                                    );
                                } else {
                                    mapGu.set(
                                        bip?.header?.gu.code,
                                        bip?.header?.gu
                                    );
                                }
                            }
                            datasMap.set(pprTab.ppr, list);
                            guMap.set(pprTab.ppr, mapGu);
                        }
                    } catch (e) {
                        console.log(bip.id, bip.code, e.toString());
                    }
                }

                /** Переносим все возможные ППР в список */
                const pprList = [];
                for (const ppr of pprMap.values()) {
                    pprList.push(ppr);
                }

                /** Объект для передачи в отчет */
                const params = {
                    year: this.planPeriod.year,
                    dataType: null,
                    selVersion: this.selVersion,
                    abp: this.selAbp,
                    prg: this.selPrg,
                    gu: gu,
                    pprList,
                    lang: this.selectedLang,
                };

                params.dataType =
                    this.selectedLang === "ru"
                        ? this.selDataType.name_ru
                        : this.selectedLang === "kk"
                            ? this.selDataType.name_kk
                            : this.selDataType.name_ru;

                /** Данные таблиц */
                for (const [key, value] of datasMap.entries()) {
                    params[key] = value;
                }

                /** Список ГУ */
                for (const tab of guMap.keys()) {
                    const listGu = [];
                    const mapGu = guMap.get(tab);
                    if (mapGu) {
                        for (const gu of mapGu.values()) {
                            listGu.push(gu);
                        }
                    }
                    params[`${tab}_gu`] = listGu;
                }

                if (pprList.length === 0) {
                    makeToast(this, 'danger', 'Приложение 68', 'Не заполнена информация по финансированию планового периода (вкладка 4)');
                    return;
                }

                const signParams = {
                    code_modules: "004.002.007",
                    abp: this.selAbp.abp,
                    code_prg: this.selPrg.prg,
                    code_gu: gu?.code,
                    code_forms: "",
                    budget_variants: this.selVersion.variant_uuid,
                };
                const signers = await this.getSigners(signParams);
                if (signers.sign_code.length > 0)
                    signers.sign_code.sort(sortByField("order_num"));
                params.signers = signers.sign_code;
                this.$emit('submitReport68', params);
            }
        },

        total(field, add = 0) {
            let sum = 0;
            for (const row of this.showBipList) {
                sum += getNumber(add === 0 ? row[field] : row.addDatas[field]);
            }
            return sum;
        }, // итого по заданному полю

        _totalFinans(field) {
            let sum = 0;
            for (const row of this.showBipList) {
                if (row.hasOwnProperty('addDatas') && row.addDatas.hasOwnProperty('finace')) {
                    for (const fin of row?.addDatas?.finace) {
                        if (fin.hasOwnProperty(field)) {
                            sum += getNumber(fin[field]);
                        }
                    }
                }
            }
            return sum;
        }, // итого по заданному полю

        updateAgreement() {
            if (this.agrMap) {
                for (const [key, value] of this.agrMap.entries()) {
                    const bip = this.bipList.filter(row => row.code === key);
                    if (bip.length > 0) {
                        const item = bip[0];
                        this.$set(item['addDatas'], 'status', this.agrMap.get(key));
                    }
                }
            }
        },

        async updateVersionByYR() {
            let vers = this.versionList.filter(row => (row.is_deleted === null || !row.is_deleted)
                && this.planPeriod && row.year === this.planPeriod.year
                && this.selRegionBudget && true
                && row.region_code === this.selRegionBudget.code);
            vers = vers.sort(sortByField('date_ueb', 'desc'));

            for (const ver of vers) {
                if (ver.attribute) {
                    this.selVersion = ver;
                    this.selDataType = findItem(this.selVersion.data_type, 'code', this.dataTypeList);
                    break;
                }
            }

            if (!this.filterVersion.includes(this.selVersion)) {
                this.selVersion = this.filterVersion[0];
            }
        },

        async updateVersion() {
            for (const ver of this.filterVersion) {
                if (ver.attribute) {
                    this.selVersion = ver;
                    break;
                }
            }
            if (!this.filterVersion.includes(this.selVersion)) {
                this.selVersion = this.filterVersion[0];
            }
        } // обновление версии в зависимости региона и dataType
    }
};
</script>