



























































import {Component, Prop, Vue, Emit} from 'vue-property-decorator';
import {BootstrapVueIcons} from 'bootstrap-vue';
import VueElementLoading from 'vue-element-loading';

Vue.use(BootstrapVueIcons);

interface ICostRow {
    abp?: number;
    prg?: number;
    ppr?: number;
    2022?: number;
    2023?: number;
    2024?: number;
    name_ru?: string;
    visible?: boolean;
    open?: boolean;
    is_leaf?: boolean;
}

@Component({
    components: {
        'loading': VueElementLoading
    }
})

export default class BudgetPreviewCost extends Vue {
    private budgetCostData: ICostRow[] = [];
    private isBusy = false;

    public async openVariantData(variant: string) {
        try {
            this.isBusy = true;
            if (variant != '') {
                const response = await fetch('/api-py/get-coast-variant/' + variant);
                this.budgetCostData = await response.json() as ICostRow[];
                for (const row of this.budgetCostData) {
                    this.$set(row, 'visible', row.prg == null);
                    this.$set(row, 'open', row.prg != null);
                    this.$set(row, 'is_leaf', row.ppr != null);
                }
                this.$set(this.budgetCostData[0], 'open', true);
                this.$set(this.budgetCostData[0], 'is_leaf', false);
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки данных. Нет данных по варианту', 'Сообщение');
        } finally {
            this.isBusy = false;
        }
    }

    makeToast(variant: string, tostbody: string, title: string) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            autoHideDelay: 4000,
            solid: true
        });
    }

    private openChild(row: any, index: number) {
        this.$set(row, 'open', !row.open);
        const vis_status = this.budgetCostData[index].open;
        for (let i = index + 1; i < this.budgetCostData.length; i++) {
            if (row.ppr == null) {
                if ((this.budgetCostData[i].abp == row.abp) && (this.budgetCostData[i].prg == row.prg)) {
                    this.$set(this.budgetCostData[i], 'visible', vis_status);
                }
            }
            if (row.prg == null) {
                if (this.budgetCostData[i].abp == row.abp) {
                    this.$set(this.budgetCostData[i], 'visible', vis_status);
                }
            }
            if (row.abp == null) {
                if (row.open) {
                    if (this.budgetCostData[i].prg == null) {
                        this.$set(this.budgetCostData[i], 'visible', true);
                        this.budgetCostData[i].open = false;
                    } else {
                        this.$set(this.budgetCostData[i], 'visible', false);
                    }
                } else {
                    this.$set(this.budgetCostData[i], 'visible', false);
                }
            }
        }
    }

    private rowClass(row: any) {
        if (row.abp == null) {
            return 'row-class-all';
        }
        if (row.prg == null) {
            return 'row-class-abp';
        }
        if (row.ppr == null) {
            return 'row-class-prg';
        }
    }
}


