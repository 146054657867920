<template>
    <div class="table-container">
        <b-table
            :fields="fileFields"
            :items="files"
            responsive="true"
            bordered
            head-variant="light"
            sticky-header="true"
            no-border-collapse
        >
            <template #thead-top="data">
                <b-tr class="text-center">
                    <b-th colspan="4">Прикрепляемые документы</b-th>
                </b-tr>
            </template>
            <template #cell(type)="data">
                <div></div>
            </template>
            <template #cell(name)="data">
                <b-link target="_blank" href="#" @click="downloadFile(data.item.name)">{{ data.value }}</b-link>
            </template>
            <template #cell(text)="data">
                <b-form-textarea
                    id="textarea"
                    v-model="data.item.text"
                    placeholder="Добавить описание (название документа, номер, дата)..."
                    rows="5"
                    @blur="getFiles"
                    @input="getFiles"
                    :disabled="reading">
                </b-form-textarea>
            </template>
            <template #cell(more)="data">
                <b-button :disabled="reading" @click="delFile(data.index, data.item.name)">
                    <i class="icon icon-close"></i>
                </b-button>
            </template>
            <template #bottom-row="data">
                <td></td>
                <td>
                    <b-form-file size="sm"
                                 id="file"
                                 ref="file-input"
                                 accept="*.*"
                                 v-model="file"
                                 :disabled="reading"
                                 @input="uploadFile">
                    </b-form-file>
                </td>
                <td>
                    <a href="#" class="nav-link download-all" @click="downloadAllFile(false)">Скачать все
                        <span>({{(files && files.length > 0) ? files.length : 0}})</span></a>
                </td>
            </template>
        </b-table>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { Ax } from '@/utils';
import store from "@/services/store";
import axios from "axios";

export default {
    name: 'FileLoad',
    props: {
        reading: null,
        year: null,
        code: null,
        files: []
    },
    data() {
        return {
            fileFields: [
                {
                    key: 'type',
                    label: ''
                },
                {
                    key: 'name',
                    label: ''
                },
                {
                    key: 'text',
                    label: ''
                },
                {
                    key: 'more',
                    label: ''
                }
            ],
            file: null,
            text: ''
        }
    },

    methods: {
        async delFile(index, file_name) {
            this.$bvModal.msgBoxConfirm(
                'Удалить выбранный файл?',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Удалить',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        this.deleteFile(index, file_name);
                    }
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка удаления', error.toString());
                });

        }, // удаление файла

        async deleteFile(index, file_name) {
            try {
                Ax(
                    {
                        url: '/api-py/file-delete/' + this.year + '/' + this.code + '/' + file_name + '/',
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'multipart/form-data'
                        },
                        data: {},
                        responseType: 'json'
                    },
                    (data) => {
                        this.files.splice(index, 1);
                        this.getFiles();
                        this.makeToast('success', 'Сообщение', 'Файл удален');
                    },
                    (error) => {
                        this.makeToast('danger', 'Предупреждение', error.toString());
                    }
                );

                // const response = await fetch('/api-py/file-delete/' + this.year + '/' + this.code + '/',
                //     {
                //         method: 'DELETE',
                //         headers: {
                //             'Content-Type': 'application/json;charset=utf-8'
                //         }
                // });
                // const result = await response.json();
                // if ((response.status === 200) && (result.result === 'success')) {
                //     that.files.splice(index, 1);
                //     that.getFiles();
                //     that.makeToast('success', 'Сообщение', 'Файл удален');
                // }
            } catch {
                that.makeToast('danger', 'Предупреждение', 'Ошибка удаления файла');
            }
        }, // удаляет файл с хранилища

        downloadFile(filename) {
            const that = this;
            Ax(
                {
                    url: '/api-py/file-download/' + this.year + '/' + this.code + '/' + filename,
                    method: 'POST',
                    data: null,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.makeToast('danger', 'Ошибка скачивания', error.toString());
                }
            );
        }, // скачать файл

        downloadAllFile(filesArr = false) {
            const that = this;
            const allFileNames = [];
            const files = filesArr || that.files;
            files.forEach(file => {
                allFileNames.push(file.name);
            });

            const usrId = store.state.user.sub;

            if (allFileNames && allFileNames.length > 0) {
                Ax(
                    {
                        url: '/api-py/files-download/' + this.year + '/' + this.code,
                        method: 'POST',
                        data: allFileNames,
                        responseType: 'blob'
                    },
                    async (data) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', this.code + '_' + this.year + '.zip');// or any other extension
                        document.body.appendChild(link);
                        link.click();

                        await axios.post('/api-py/delete-zip-by-path/' + this.year + '/' + this.code); // запрос чтобы удалить сгенерированный архив
                    },
                    async (error) => {
                        that.makeToast('danger', 'Ошибка скачивания', error.toString());
                        await axios.post('/api-py/delete-zip-by-path/' + this.year + '/' + this.code); // запрос чтобы удалить сгенерированный архив
                    }
                );
            }
        }, // скачать архив всех прикрепленных файлов

        getFiles() {
            const data = this.files;
            this.$emit('getFiles', data);
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            })
        }, // сообщение

        async uploadFile () {
            if (this.code === 'BIP-00000' && this.file !== null) {
                this.makeToast('danger', 'Предупреждение',
                    'Предварительно необходимо сохранить БИП!');
                this.$refs['file-input'].reset();
                return;
            }
            const that = this;
            let f = '';
            const newItem = {};
            const promise = new Promise(function (resolve, reject) {
                if (that.file != null) {
                    const formData = new FormData();
                    formData.append('file', that.file);
                    formData.append('filetype', that.file.type);
                    formData.append('filename', that.file.name);
                    formData.append('year', that.year);
                    formData.append('code', that.code);


                    that.$set(newItem, 'type', that.file.type);
                    that.$set(newItem, 'name', that.file.name);
                    that.$set(newItem, 'text', '');
                    Ax(
                        {
                            url: '/api-py/file-load/' + that.year + '/' + that.code + '/',
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'multipart/form-data'
                            },
                            data: formData,
                            responseType: 'json'
                        },
                        (data) => {
                            f = data.existed;
                            resolve({ f: f, newItem: newItem });
                        },
                        (error) => {
                            f = 'error';
                            resolve({ f: f, newItem: newItem });
                        }
                    );
                }
            });
            promise.then(
                result => {
                    switch (result.f) {
                        case true:
                            that.makeToast('danger', 'Предупреждение',
                                'Файл с таким наименованием уже существует в хранилище. Переименуйте файл для загрузки!');
                            break;
                        case false:
                            that.files.push(result.newItem);
                            that.getFiles();
                            that.makeToast('success', 'Сообщение', 'Файл успешно загружен');
                            break;
                        case 'error':
                            that.makeToast('danger', 'Ошибка скачивания файла', '');
                            break;
                        default:
                            break;
                    }
                    that.$refs['file-input'].reset();
                },
                error => {
                    that.makeToast('danger', 'Предупреждение', error.toString());
                }
            );
        } // закачать файл
    }
};
</script>