





































































































































































































































































































































































































import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import {loadVariants, IVariant, IVariantInfo} from '@/modules/budget/budgetVariantService';
import {Component, Vue} from 'vue-property-decorator';
import {BootstrapVueIcons} from 'bootstrap-vue';
import BudgetPreviewCost from '@/modules/budget/components/BudgetPreviewCost.vue';
import BudgetPreviewIncome from '@/modules/budget/components/BudgetPreviewIncome.vue';
import {genListOfYears} from '@/modules/budget/budgetCorrectHelper';
import VueElementLoading from 'vue-element-loading';
import alert from "@/components/c-icon/variants/alert";
import DatePicker from 'vue2-datepicker';

interface IDict {
    id: number;
    name_ru: string;
    name_kk?: string;
    name_en?: string;
    code: string;
}

interface VariantError {
    level: number;
    i18n?: string;
    text: string;
}

Vue.use(BootstrapVueIcons);

@Component({
    components: {
        BudgetPreviewCost,
        BudgetPreviewIncome,
        'loading': VueElementLoading,
        DatePicker
    }
})

export default class BudgetVariants extends Vue {
    $refs!: {
        budgetPreviewCost: BudgetPreviewCost;
        budgetPreviewIncome: BudgetPreviewIncome;
    };

    private filter_process: number | null = null;

    private changePeriod(variant: IVariant) {
        if (variant.year) {
            variant.date_time = variant.year.toString() + '-01-01';
        }
    }

    private get listOfYears() {
        return genListOfYears();
    }

    private rowClass(item: any, type: any) {
        if (!item || type !== 'row') return
        if (item.attribute) return 'table-success'
    }

    private alert_message(variant: IVariant): string {
        const variantErrors: VariantError[] = this.validate_errors(variant);
        let result = ""
        for (const ve of variantErrors) {
            result = result + " " + ve.text;
        }
        return result;
    }

    private alert_max_level(variant: IVariant): number {
        const variantErrors: VariantError[] = this.validate_errors(variant);
        let max = 0
        for (const ve of variantErrors) {
            if (max < ve.level) {
                max = ve.level
            }
        }
        return max
    }

    private alert_variant(variant: IVariant): string {
        const max = this.alert_max_level(variant)
        let result = "succces"
        if (max == 1) {
            result = "warning"
        }
        if (max == 2) {
            result = "danger"
        }
        return result;
    }


    private alert_show(variant: IVariant): boolean {
        const variantErrors: VariantError[] = this.validate_errors(variant);
        return variantErrors.length > 0;
    }

    private validate_errors(variant: IVariant): VariantError[] {
        const variantErrors: VariantError[] = []
        if (variant) {
            if ((variant.status) && (variant.date_ueb == null)) {
                const ve: VariantError = {
                    level: 2,
                    text: "Заполните дату дату утверждения!"
                }
                variantErrors.push(ve)
            }
            // if ((variant.status) && (variant.date_ueb < variant.date_time)) {
            //     const ve: VariantError = {
            //         level: 1,
            //         text: "«Дата утверждения» должна быть позже «Дата начала процесса»"
            //     }
            //     variantErrors.push(ve)
            // }
            if (variant.date_time) {
                const d = new Date(variant.date_time);
                if (d.getFullYear() != variant.year) {
                    const ve: VariantError = {
                        level: 2,
                        text: "«Дата начала процесса» должна быть в диапазоне " + variant.year
                    }
                    variantErrors.push(ve)
                }
            }
        }
        return variantErrors
    }

    private loading = false;
    private curr_year: number = 2023;
    private open = false;
    private filter_status: number | null = null;
    private status_list = [
        {
            value: null,
            text: 'Все'
        },
        {
            value: 1,
            text: 'Утвержденый'
        }
    ]
    private tableFields = [
        {
            key: 'collapse',
            label: ' ',
            class: 'toggle-show'
        },
        {
            key: 'icon',
            label: 'i',
            class: 'centered-symbol'
        },
        {
            key: 'id',
            label: '№',
            sortable: true
        },
        {
            key: 'name_ru',
            label: 'Наименование',
            sortable: true
        },
        {
            key: 'year',
            label: 'Плановый период',
            sortable: true
        },
        {
            key: 'date_time',
            label: 'Дата начала процесса',
            sortable: true
        },
        {
            key: 'date_ueb',
            label: 'Версия УЭБП/ОЭБП',
            sortable: true
        },
        {
            key: 'date_abp',
            label: 'Версия АБП'
        },
        {
            key: 'status',
            label: 'Статус'
        },
        {
            key: 'action',
            label: ''
        }
    ];
    private regionBase: IDict[] = [];
    private regionList: IDict[] = [];
    private period_start_year = 2022;
    private regionFilter = '';

    private getPrevVariantList(vrnt: IVariant) {
        let resList = this.variants.filter(v => (v.region_code == this.regionFilter) && (v.variant_uuid != vrnt.variant_uuid));
        if (vrnt.data_type == 1) {
            resList = resList.filter(v => (((v.status == true) && (v.year == this.curr_year - 1)) || ((v.data_type == 1 && v.year == this.curr_year))));
        }
        if (vrnt.data_type == 2) {
            resList = resList.filter(v => (v.data_type == 1) && (v.year == this.curr_year));
        }
        if ((vrnt.data_type == 3) || (vrnt.data_type == 4)) {
            resList = resList.filter(v => (v.status == true) && (v.year == this.curr_year));
        }
        return resList.sort((a, b) => {
            return a.id < b.id ? 1 : 0;
        });
    }

    private get variantListFilter() {
        let result = this.variants.filter(v => (v.region_code == this.regionFilter) && (v.year == this.curr_year));
        if (this.filter_process != null) {
            result = result.filter(v => v.data_type == this.filter_process);
        }
        if (this.filter_status != null) {
            result = result.filter(v => v.status);
        }
        return result
    }

    private getProcessTypeName(typeCode: string, lang: string) {
        const findProcess: any = this.process_types.find(o => o.code == typeCode);
        if (findProcess) {
            return findProcess['name_' + lang];
        }
        return '';
    }

    private setNames(vrntCom: IVariant) {
        this.setNameRu(vrntCom);
        this.setNameKk(vrntCom);
    }

    private change_prev_uiid(variant: IVariant) {
        const prev_var = this.variants.find(o => o.prev_variant == variant.prev_variant);
        if (prev_var) {
            variant.date_time = variant.year.toString() + '-01-01';
            // if (prev_var.date_time) {
            //   // variant.date_time = prev_var.date_time;
            // } else {
            //   if (variant.year) {
            //     variant.date_time = variant.year.toString() + '-01-01';
            //   }
            // }
        }
    }

    private setNameRu(vrntCom: IVariant) {
        vrntCom.name_ru = this.getProcessFullNameRu(vrntCom);
    }

    private setNameKk(vrntCom: IVariant) {
        vrntCom.name_kk = this.getProcessFullNameKk(vrntCom);
    }

    private getProcessFullNameRu(vrntCom: IVariant) {
        const name = this.getProcessTypeName(vrntCom.data_type, 'ru');
        let bud = ' № ';
        if (!name.includes('бюджет')) {
            bud = ' бюджета ' + bud;
        }
        return name + bud + vrntCom.variant_uuid.substr(0, 8);
        // let typeCnt = 1;
        // const list = [...this.variantListFilter];
        // for (const vrnt of list.reverse()) {
        //   if (vrnt.id == vrntCom.id) {
        //     return name + bud + typeCnt;
        //   }
        //   if (vrnt.data_type == vrntCom.data_type) {
        //     typeCnt++;
        //   }
        // }
        return 'Новая версия без процесса';
    }

    private getProcessFullNameKk(vrntCom: IVariant) {
        let name = this.getProcessTypeName(vrntCom.data_type, 'kk');
        if (name == null) {
            name = this.getProcessTypeName(vrntCom.data_type, 'ru')
        }
        let bud = '';
        if (!name.includes('Бюджет')) {
            bud = ' Бюджетті ' + bud;
        }
        return bud + name.toLowerCase().capitalized() + ' № ' + vrntCom.variant_uuid.substr(0, 8);
        let typeCnt = 1;
        const list = [...this.variantListFilter];
        for (const vrnt of list.reverse()) {
            if (vrnt.id == vrntCom.id) {
                return bud + name.toLowerCase().capitalized() + ' № ' + typeCnt;
            }
            if (vrnt.data_type == vrntCom.data_type) {
                typeCnt++;
            }
        }
        return 'Новая версия без процесса';
    }

    private openVariantData(variant: string) {
        this.$refs.budgetPreviewCost.openVariantData(variant);
        this.$bvModal.show('modal-1');
    }

    private openIncomeVariantData(variant: string) {
        this.$refs.budgetPreviewIncome.openVariantData(variant);
        this.$bvModal.show('modal-2');
    }


    private async showMsgBoxOne(question: string, options: object) {
        const res = await this.$bvModal.msgBoxConfirm(question, options);
        return res;
    }

    private async deleteVariant(variant: IVariant) {
        if (!variant.status) {
            let find = false;
            for (const vrn of this.variants) {
                if (vrn != variant) {
                    if ((vrn.prev_variant == variant.variant_uuid) && (vrn.region_code == variant.region_code)) {
                        this.makeToast('danger', 'Удаление невозможно! Данная версия является исходным для версии  ' + vrn.id, 'Ошибка');
                        find = true;
                    }
                }
            }
            if (find) {
                return
            }
            variant.is_deleted = true;
            const index = this.variants.indexOf(variant);
            const mesRes = await this.showMsgBoxOne('Удалить версию?', {
                okTitle: 'Удалить',
                cancelTitle: 'Не удалять',
            });
            if (mesRes) {
                await this.saveCurrentVariant(variant);
                this.variants.splice(index, 1);
            }
        }
    }

    private async saveVarinatWithQuestion(variant: IVariant) {
        await this.saveCurrentVariant(variant);
        if (variant.prev_variant) {
            const var_info: IVariantInfo = await this.get_variant_info(variant.variant_uuid);
            // if ((var_info.budget_cost_data == 0) || (var_info.budget_income_data == 0) || (var_info.budget_request_form_total == 0)) {
            if (true) {
                const mesRes = await this.showMsgBoxOne('Пересоздать из исходной версии?', {
                    okTitle: 'Пересоздать',
                    cancelTitle: 'Не пересоздавать',
                });
                if (mesRes) {
                    this.createNewVariantData(variant);
                }
            }
        }
    }

    private async openDetail(data: any) {
        data.toggleDetails();
        if (!data.detailsShowing) {
            const var_info = await this.get_variant_info(data.item.variant_uuid);
            if (data.item) {
                if (var_info) {
                    this.$set(data.item, "variant_info", var_info);
                }
            }
        }
    }

    private async get_variant_info(variant_uuid: string): Promise<any> {
        try {
            this.loading = true;
            const response = fetch('/api-py/get-variant-info/' + variant_uuid)
                .then(response => response.json());
            return response;
        } catch (e) {
            return e
        } finally {
            this.loading = false;
        }
    }

    private async createNewVariantData(variant: IVariant) {
        if (!variant.prev_variant) {
            this.makeToast('danger', 'Версия не имеет предыдущую версию  ', 'Ошибка');
            return;
        }
        const mesRes = await this.showMsgBoxOne('Данное действие приведет к удалению внесенных изменений и возврату к исходному состоянию данных. Продолжить?', {
            okTitle: 'Продолжить',
            cancelTitle: 'Отмена',
        });
        if (mesRes) {
            try {
                // await this.saveCurrentVariant(variant);
                this.loading = true;
                const response = await fetch('/api-py/create-variant-by-variant/' + variant?.prev_variant + '/' + variant?.variant_uuid + '/' + this.$store.getters.user_uuid);
                if (response.status == 200) {
                    this.makeToast('success', 'Версия создана. Проверьте данные в режимах Расходы/Доходы', 'Сообщение');
                    await this.createNewPeriodVariantData(variant);
                    this.loading = false;
                } else {
                    this.makeToast('danger', 'Ошибка создания версии', 'Ошибка');
                    this.loading = false;
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка создания версии', error.toString());
                this.loading = false;
            } finally {
            }
        }
    }

    private async createNewPeriodVariantData(variant: IVariant) {
        const prev_var = this.variants.find(o => o.variant_uuid == variant.prev_variant);
        if (prev_var && variant.year == prev_var.year) {
            return;
        }
        try {
            this.loading = true;
            const response = await fetch('/api-py/check-variant-by-variant/' + variant?.prev_variant + '/' + variant?.variant_uuid);
            if (response.status == 200) {
                this.makeToast('success', 'Версия нового периода создана. Проверьте данные в режимах Расходы/Доходы', 'Сообщение');
            } else {
                this.makeToast('danger', 'Ошибка создания версии нового периода', 'Ошибка');
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка создания версии нового периода', error.toString());
        } finally {
            this.loading = false;
        }
    }

    private get period_end_year() {
        return this.period_start_year + 2;
    }

    variants: IVariant[] = [];
    budget = [];
    edited = {};
    editable = true;
    is_new_item = false;
    process_types: IDict[] = [];

    private addNewVariant() {
        const vrnt: IVariant = {
            _showDetails: true,
            id: -1,
            date_time: new Date(),
            date_start: new Date().toISOString().substr(0, 10),
            variant_uuid: this.uuidv4(),
            name_ru: 'Новая версия',
            year: this.curr_year,
            region_code: this.regionFilter,
            status: false,
            attribute: false,
            is_deleted: false
        };
        vrnt.date_time.setDate(1);
        vrnt.date_time.setMonth(0);
        vrnt.date_time.setFullYear(this.curr_year)
        vrnt.date_time = vrnt.date_time.toISOString().slice(0, 10);
        if (vrnt.year) {
            vrnt.date_time = vrnt.year.toString() + '-01-01';
        }
        this.variants.push(vrnt);
    }

    async saveCurrentVariantStatus(variant: IVariant) {
        if (variant) {
            if (variant.attribute) {
                variant.attribute = false;
            }
        }
    }

    async saveCurrentVariantAttribute(variant: IVariant) {
        const response = await fetch('api-py/get-actual-variant/' + this.regionFilter + '/' + this.curr_year);
        const actual_base_variant = await response.json();
        console.debug(actual_base_variant);
        if (variant) {
            if (variant.attribute) {
                const vrnts = this.variants.filter(v => (v.region_code == this.regionFilter) && (v.year == this.curr_year))
                let is_changed = false;
                for (const vrn of vrnts) {
                    if (vrn.variant_uuid === actual_base_variant) {
                        if (vrn.attribute) {
                            is_changed = false
                        } else {
                            is_changed = true
                        }
                        break
                    }
                }
                if (is_changed) {
                    this.makeToast('danger', 'Сохранение', 'Не сохранено. В базе проставлена другая актуальная версия, перегрузите список версии');
                    return
                }
                for (const vrn of vrnts) {
                    if (vrn != variant) {
                        if ((vrn.attribute)) {
                            const mesRes = await this.showMsgBoxOne('Есть другая актуальная версия. Назначить новый актуальной?', {
                                okTitle: 'Назначить',
                                cancelTitle: 'Не назначать',
                            });
                            if (mesRes) {
                                variant.attribute = true;
                                vrn.attribute = false;
                                this.saveCurrentVariant(vrn);
                                this.saveCurrentVariant(variant);
                            } else {
                                variant.attribute = false;
                            }
                        }
                    }
                }
            }
        }
    }

    async saveCurrentVariant(variant: IVariant) {
        const that = this;
        variant.user_name = this.$store.getters.user_uuid;
        this.loading = true;
        try {
            const response = await fetch('/api-py/budget-variant-save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(variant as IVariant)
            });
            const result = await response.json();
            if (result.status == 'success') {
                that.makeToast('success', 'Сохранение', 'Данные сохранены');
                const savedVariant = result.data;
                this.setSavedData(variant, savedVariant);
            } else {
                that.makeToast('danger', 'Ошибка сохранения. Неверный формат данных', 'Ошибка');
            }
        } catch (e) {
            that.makeToast('danger', 'Ошибка сохранения / сетевая ошибка', 'Ошибка');
        } finally {
            this.loading = false;
        }
    }

    setSavedData(oldVar: IVariant, newVar: IVariant) {
        for (const vrn of this.variants) {
            if (vrn.id == oldVar.id) {
                for (const [key, value] of Object.entries(newVar)) {
                    this.$set(vrn, key, value);
                }
                break;
            }
        }
    }

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0;
            const v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    private async loadRegions() {
        const code: string = this.$store.state._instanceCode;
        let region = {
            obl: '45'
        };
        await fetch('/api-py/get-budget-obl/' + code)
            .then(response => response.json())
            .then(json => {
                region = json;
            });
        try {
            // const response = await fetch('/api-py/get-regions-by-obl/' + region.obl);
            const response = await fetch('/api-py/get-user-regions-by-obl/' + region.obl + '/' + this.$store.getters.user_uuid);
            this.regionBase = await response.json() as IDict[];
            this.regionList = [...this.regionBase];
            if (this.regionList[0]) {
                this.regionFilter = this.regionList[0].code;
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки регионов', error.toString());
        }
    }

    private async loadBudgetDataTypes() {
        try {
            const response = await fetch('/api-py/dict_budget_data_types/');
            this.process_types = await response.json() as IDict[];
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки регионов', error.toString());
        }
    }


    appendNew() {
        this.is_new_item = true;
        this.edited = {
            id: this.variants.length + 1,
            name_ru: 'Новый',
            name_kk: 'Новый',
            variant_uuid: this.uuidv4()
        };
        this.$bvModal.show('edit_form');
    }

    async loadVariants() {
        try {
            // const response = await fetch('/api-py/get-variants');
            const baseVariants = await loadVariants();
            this.variants = [];
            for (const vr of baseVariants) {
                vr._showDetails = false;
                if (vr.date_start === null) vr.date_start = new Date().toISOString().substr(0, 10);
                this.variants.push(vr);
            }
            this.variants = this.variants.sort((a, b) => {
                return a.id > b.id ? 1 : 0;
            });
        } catch (err) {
            this.makeToast('danger', err.toString(), 'Ошибка запроса');
        }
        return this.variants;
    }

    makeToast(variant: string, tostbody: string, title: string) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            autoHideDelay: 4000,
            solid: true
        });
    }

    async mounted() {
        await this.loadBudgetDataTypes();
        await this.loadRegions();
        this.loadVariants();
    }
}

