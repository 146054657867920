<template>
    <div class="h-100">
        <div class="filter-container">
            <div class="left-content">
                <b-dropdown
                    class="filter-dropdown"
                    variant="default"
                    ref="drop"
                >
                    <template #button-content>
                        <span class="lc"
                        ><i class="icon icon-filter"></i> Фильтр</span
                        ><span class="rc"
                    ><i class="icon icon-keyboard"></i
                    ></span>
                    </template>
                    <div>
                        <div class="top-content">
                            <span>Параметры фильтра</span>
                            <i
                                class="icon icon-close"
                                @click="$refs.drop.hide(true)"
                            ></i>
                        </div>
                        <!--------------- Плановый период ------------------->
                        <div class="filter-block">
                            <b-form-group label="Плановый период">
                                <multiselect
                                    ref="bcPlanYear"
                                    v-model="planPeriod"
                                    track-by="name"
                                    label="name"
                                    placeholder="Выбрать год"
                                    :options="years"
                                    :searchable="false"
                                    :allow-empty="false"
                                    :show-labels="false"
                                >
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Область/район ------------------->
                        <div class="filter-block">
                            <b-form-group label="Область/район">
                                <multiselect
                                    ref="bcRegionBudget"
                                    v-model="selRegion"
                                    track-by="text"
                                    label="text"
                                    :options="listReg"
                                    :searchable="true"
                                    :allow-empty="false"
                                    :show-labels="false"
                                >
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Вид данных ------------------->
                        <div class="filter-block">
                            <b-form-group label="Вид данных">
                                <multiselect
                                    ref="bcDataType"
                                    v-model="selDataType"
                                    track-by="name_ru"
                                    label="name_ru"
                                    placeholder="Все"
                                    :options="dataTypeList"
                                    :searchable="true"
                                    :allow-empty="false"
                                    :show-labels="false"
                                    @input="changeFields"
                                >
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Версия бюджета ------------------->
                        <div class="filter-block">
                            <b-form-group label="Версия бюджета">
                                <multiselect
                                    ref="bcVersion"
                                    v-model="selVersion"
                                    track-by="text"
                                    label="text"
                                    placeholder="Все"
                                    :options="filterVersion"
                                    :searchable="true"
                                    :allow-empty="false"
                                    :show-labels="false"
                                >
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- АБП ------------------->
                        <div class="filter-block">
                            <b-form-group label="АБП">
                                <b-form-select
                                    ref="bcAbp"
                                    v-model="selectedAbp"
                                    :options="dictTree"
                                    value-field="value"
                                    label-field="text"
                                    @input="addAbp"
                                    @change="chgData"
                                >
                                    <template #first>
                                        <b-form-select-option :value="null"
                                        >Выберите АБП
                                        </b-form-select-option
                                        >
                                    </template>
                                </b-form-select>
                            </b-form-group>
                        </div>
                    </div>
                </b-dropdown>
            </div>
            <div class="right-content" v-if="editing">
                <div class="filter-actions">
                    <b-button
                        variant="success"
                        v-if="
                            selVersion.data_type === 3 ||
                            selVersion.data_type === 4
                        "
                        @click="openCorrect"
                    >Причины
                    </b-button
                    >
                    <b-button
                        variant="success"
                        v-if="!firstVariant"
                        @click="saveDatas"
                    >Сохранить
                    </b-button
                    >
                    <b-button
                        v-if="operationCode.length > 0"
                        variant="success"
                        @click="agreementEvent"
                    >Действия
                    </b-button
                    >
                </div>
            </div>
        </div>
        <!--        хлебные крошки-->
        <div class="filter-breadcrumb">
            <span
                class="item-breadcrumb"
                v-if="!openFilterWindow && planPeriod"
                @click="openFilterByRef('bcPlanYear')"
            >
                {{ planPeriod.name }}
            </span>
            <span
                class="item-breadcrumb"
                v-if="!openFilterWindow && selRegion"
                @click="openFilterByRef('bcRegionBudget')"
            >
                {{ selRegion.name_ru }}
            </span>
            <span
                class="item-breadcrumb"
                v-if="!openFilterWindow && selDataType"
                @click="openFilterByRef('bcDataType')"
            >
                {{ selDataType.name_ru }}
            </span>
            <span
                class="item-breadcrumb"
                v-if="!openFilterWindow && selVersion"
                @click="openFilterByRef('bcVersion')"
            >
                {{ selVersion.name_ru }}
            </span>
            <span
                class="item-breadcrumb"
                v-if="!openFilterWindow && selectedAbp"
                @click="openFilterByRef('bcAbp')"
            >
                {{ selectedAbp.abp }}-АБП
            </span>
        </div>
        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetTable"
                :tbody-tr-class="rowClass"
                :filter="filter.search"
                :filter-included-fields="filter.on"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header="true"
                no-border-collapse
            >
                <template #thead-top="data">
                    <b-tr>
                        <b-th colspan="9"></b-th>
                        <b-th colspan="2" class="text-center">{{
                                planPeriod.year + " год"
                            }}
                        </b-th>
                        <b-th colspan="2" class="text-center">{{
                                parseInt(planPeriod.year) + 1 + " год"
                            }}
                        </b-th>
                        <b-th colspan="2" class="text-center">{{
                                parseInt(planPeriod.year) + 2 + " год"
                            }}
                        </b-th>
                        <b-th colspan="3"></b-th>
                    </b-tr>
                </template>

                <template #head(check)="scope">
                    <b-form-checkbox
                        v-model="check"
                        v-if="
                            selVersion &&
                            selVersion.attribute &&
                            !selVersion.status &&
                            operationCode.length > 0
                        "
                    >
                    </b-form-checkbox>
                </template>
                <template #head(action)="scope">
                    <b-button @click="openAll()">
                        <i
                            class="icon icon-chevron-circle icon-rotate-180"
                            v-if="open"
                        ></i>
                        <i class="icon icon-chevron-circle" v-if="!open"></i>
                    </b-button>
                </template>
                <template #head(abp)="scope">
                    <div>АБП</div>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.abp"
                        type="search"
                        class="mini"
                    ></b-form-input>
                </template>
                <template #head(prg)="scope">
                    <div>БП</div>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.prg"
                        type="search"
                        class="mini"
                    ></b-form-input>
                </template>
                <template #head(ppr)="scope">
                    <div>БПП</div>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.ppr"
                        type="search"
                        class="mini"
                    ></b-form-input>
                </template>
                <template #head(spf)="scope">
                    <div>СП</div>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.spf"
                        type="search"
                        class="mini"
                    ></b-form-input>
                </template>
                <template #head(name_ru)="scope">
                    <div>Наименование</div>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.name_ru"
                        type="search"
                    ></b-form-input>
                </template>
                <template #head(receipt_prev)="scope">
                    <div>
                        {{
                            "Исполнение за " +
                            (parseInt(planPeriod.year) - 2) +
                            " год"
                        }}
                    </div>
                </template>
                <template #head(plan)="scope">
                    <div>
                        {{
                            "Уточненный план на 1 апреля " +
                            (parseInt(planPeriod.year) - 1)
                        }}
                    </div>
                </template>

                <template #cell(check)="data">
                    <template
                        v-if="
                            selVersion &&
                            selVersion.attribute &&
                            operationCode.length > 0 &&
                            data.item.availableStep
                        "
                    >
                        <b-form-checkbox
                            v-if="
                                data.item.type === 5 ||
                                (data.item.type === 4 && !data.item.hasChild)
                            "
                            v-model="data.item.check"
                            :disabled="!newStatus(data.item)"
                        >
                        </b-form-checkbox>
                    </template>
                </template>
                <template #cell(action)="data">
                    <b-button
                        v-if="data.item.type === 3"
                        @click="openChilds(data.item)"
                    >
                        <i
                            class="icon icon-chevron-circle icon-rotate-180"
                            v-if="data.item.open"
                        ></i>
                        <i
                            class="icon icon-chevron-circle"
                            v-if="!data.item.open"
                        ></i>
                    </b-button>
                </template>
                <template #cell(abp)="data">
                    <div v-if="data.item.type === 3" class="text-right">
                        {{ data.value }}
                    </div>
                    <b-button
                        v-if="data.item.type === 4 && data.item.hasChild"
                        @click="openChilds(data.item)"
                    >
                        <i
                            class="icon icon-chevron-circle icon-rotate-180"
                            v-if="data.item.open"
                        ></i>
                        <i
                            class="icon icon-chevron-circle"
                            v-if="!data.item.open"
                        ></i>
                    </b-button>
                </template>
                <template #cell(prg)="data">
                    <div v-if="data.item.type === 4" class="text-right">
                        {{ data.value }}
                    </div>
                    <b-button
                        v-if="data.item.type === 5 && data.item.hasChild"
                        @click="openChilds(data.item)"
                    >
                        <i
                            class="icon icon-chevron-circle icon-rotate-180"
                            v-if="data.item.open"
                        ></i>
                        <i
                            class="icon icon-chevron-circle"
                            v-if="!data.item.open"
                        ></i>
                    </b-button>
                </template>
                <template #cell(ppr)="data">
                    <div v-if="data.item.type === 5" class="text-right">
                        {{ data.value }}
                    </div>
                </template>
                <template #cell(spf)="data">
                    <div v-if="data.item.type === 6" class="text-right">
                        {{ data.value }}
                    </div>
                </template>
                <template #cell(name_ru)="data">
                    <template
                        v-if="
                            data.item.id < 0 &&
                            [4, 5, 6].includes(data.item.type)
                        "
                    >
                        <template
                            v-if="data.item.id < 0 && data.item.type === 4"
                        >
                            <b-form-group
                                :class="{
                                    'with-warning': existElem(data.item),
                                }"
                            >
                                <multiselect
                                    v-model="data.item.code"
                                    track-by="text"
                                    label="text"
                                    :options="prgList"
                                    :searchable="true"
                                    :show-labels="false"
                                    @input="inputPrg(data.item)"
                                >
                                </multiselect>
                                <template v-if="existElem(data.item)">
                                    <div class="form-tooltip">
                                        <i class="icon icon-danger"></i>
                                        <div class="form-tooltip-content">
                                            <p class="red mb-2">
                                                Внимание! <br/>Данная программа
                                                уже присутствует в списке!
                                            </p>
                                        </div>
                                    </div>
                                </template>
                            </b-form-group>
                        </template>
                        <template
                            v-if="data.item.id < 0 && data.item.type === 5"
                        >
                            <b-form-group
                                :class="{
                                    'with-warning': existElem(data.item),
                                }"
                            >
                                <multiselect
                                    v-model="data.item.code"
                                    track-by="text"
                                    label="text"
                                    :options="pprList"
                                    :searchable="true"
                                    :show-labels="false"
                                    @input="inputPpr(data.item)"
                                >
                                </multiselect>
                                <template v-if="existElem(data.item)">
                                    <div class="form-tooltip">
                                        <i class="icon icon-danger"></i>
                                        <div class="form-tooltip-content">
                                            <p class="red mb-2">
                                                Внимание! <br/>Данная программа
                                                + подпрограмма уже присутствуют
                                                в списке!
                                            </p>
                                        </div>
                                    </div>
                                </template>
                            </b-form-group>
                        </template>
                        <template
                            v-if="data.item.id < 0 && data.item.type === 6"
                        >
                            <b-form-group
                                :class="{
                                    'with-warning': existElem(data.item),
                                }"
                            >
                                <multiselect
                                    v-model="data.item.code"
                                    track-by="text"
                                    label="text"
                                    :options="spfList"
                                    :searchable="true"
                                    :show-labels="false"
                                    @input="inputSpf(data.item)"
                                >
                                </multiselect>
                                <template v-if="existElem(data.item)">
                                    <div class="form-tooltip">
                                        <i class="icon icon-danger"></i>
                                        <div class="form-tooltip-content">
                                            <p class="red mb-2">
                                                Внимание! <br/>Данная программа
                                                + подпрограмма + специфика уже
                                                присутствуют в списке!
                                            </p>
                                        </div>
                                    </div>
                                </template>
                            </b-form-group>
                        </template>
                    </template>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell()="data">
                    <div class="text-right">
                        {{ $n(toNum(data.value)) }}
                    </div>
                </template>
                <template #cell(request1)="data">
                    <template
                        v-if="data.item.type === 6 && toNum(data.value) > 0"
                    >
                        <a href="#" @click="clkShowGu(data.item, 0)">{{
                                $n(toNum(data.value))
                            }}</a>
                    </template>
                    <template v-else>{{ $n(toNum(data.value)) }}</template>
                </template>
                <template #cell(request2)="data">
                    <template
                        v-if="data.item.type === 6 && toNum(data.value) > 0"
                    >
                        <a href="#" @click="clkShowGu(data.item, 1)">{{
                                $n(toNum(data.value))
                            }}</a>
                    </template>
                    <template v-else>{{ $n(toNum(data.value)) }}</template>
                </template>
                <template #cell(request3)="data">
                    <template
                        v-if="data.item.type === 6 && toNum(data.value) > 0"
                    >
                        <a href="#" @click="clkShowGu(data.item, 2)">{{
                                $n(toNum(data.value))
                            }}</a>
                    </template>
                    <template v-else>{{ $n(toNum(data.value)) }}</template>
                </template>
                <template #cell(plan1)="data">
                    <template
                        v-if="
                            editing &&
                            data.item.editable &&
                            data.item.type === 6
                        "
                    >
                        <b-form-input
                            class="text-right"
                            :value="$n(data.item.plan1)"
                            :disabled="!editing || firstVariant"
                            @change="(v) => (data.item.plan1 = v)"
                            @keyup.enter.exact="keyup13"
                            @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                            @blur="
                                inputFixed(
                                    data.item,
                                    'plan1',
                                    data.item.plan1,
                                    1
                                )
                            "
                        >
                        </b-form-input>
                    </template>
                    <template v-else>
                        <div
                            :class="{
                                error:
                                    toNum(data.item.plan1) !==
                                    toNum(data.item.request1),
                            }"
                        >
                            <div class="text-right">
                                {{ $n(toNum(data.value)) }}
                            </div>
                            <template
                                v-if="
                                    toNum(data.item.plan1) !==
                                        toNum(data.item.request1) &&
                                    (data.item.type === 5 ||
                                        (data.item.type === 4 &&
                                            !data.item.pprChild))
                                "
                            >
                                <i class="icon icon-danger"></i>
                                <div class="pop-up">
                                    <p class="red-text">Внимание!</p>
                                    <p>Расхождение с версией АБП</p>
                                </div>
                            </template>
                        </div>
                    </template>
                </template>
                <template #cell(plan2)="data">
                    <template
                        v-if="
                            editing &&
                            data.item.editable &&
                            data.item.type === 6 &&
                            selVersion.data_type < 4
                        "
                    >
                        <b-form-input
                            class="text-right"
                            :value="$n(data.item.plan2)"
                            :disabled="!editing || firstVariant"
                            @change="(v) => (data.item.plan2 = v)"
                            @keyup.enter.exact="keyup13"
                            @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                            @blur="
                                inputFixed(
                                    data.item,
                                    'plan2',
                                    data.item.plan2,
                                    1
                                )
                            "
                        >
                        </b-form-input>
                    </template>
                    <template v-else>
                        <div
                            :class="{
                                error:
                                    toNum(data.item.plan2) !==
                                    toNum(data.item.request2),
                            }"
                        >
                            <div class="text-right">
                                {{ $n(toNum(data.value)) }}
                            </div>
                            <template
                                v-if="
                                    toNum(data.item.plan2) !==
                                        toNum(data.item.request2) &&
                                    (data.item.type === 5 ||
                                        (data.item.type === 4 &&
                                            !data.item.pprChild))
                                "
                            >
                                <i class="icon icon-danger"></i>
                                <div class="pop-up">
                                    <p class="red-text">Внимание!</p>
                                    <p>Расхождение с версией АБП</p>
                                </div>
                            </template>
                        </div>
                    </template>
                </template>
                <template #cell(plan3)="data">
                    <template
                        v-if="
                            editing &&
                            data.item.editable &&
                            data.item.type === 6 &&
                            selVersion.data_type < 4
                        "
                    >
                        <b-form-input
                            class="text-right"
                            :value="$n(data.item.plan3)"
                            :disabled="!editing || firstVariant"
                            @change="(v) => (data.item.plan3 = v)"
                            @keyup.enter.exact="keyup13"
                            @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                            @blur="
                                inputFixed(
                                    data.item,
                                    'plan3',
                                    data.item.plan3,
                                    1
                                )
                            "
                        >
                        </b-form-input>
                    </template>
                    <template v-else>
                        <div
                            :class="{
                                error:
                                    toNum(data.item.plan3) !==
                                    toNum(data.item.request3),
                            }"
                        >
                            <div class="text-right">
                                {{ $n(toNum(data.value)) }}
                            </div>
                            <template
                                v-if="
                                    toNum(data.item.plan3) !==
                                        toNum(data.item.request3) &&
                                    (data.item.type === 5 ||
                                        (data.item.type === 4 &&
                                            !data.item.pprChild))
                                "
                            >
                                <i class="icon icon-danger"></i>
                                <div class="pop-up">
                                    <p class="red-text">Внимание!</p>
                                    <p>Расхождение с версией АБП</p>
                                </div>
                            </template>
                        </div>
                    </template>
                </template>
                <template #cell(status)="data">
                    <template
                        v-if="
                            data.item.type === 5 ||
                            (data.item.type === 4 && !data.item.hasChild)
                        "
                    >
                        <div>
                            <template v-if="data.item.status">{{
                                    data.item.status_name_ru
                                }}
                            </template>
                            <template
                                v-if="!data.item.status && newStatus(data.item)"
                            >
                                Заявка (Проект)
                            </template>
                        </div>
                    </template>
                </template>
                <template #cell(comments)="data">
                    <div
                        v-if="
                            data.item.type === 5 ||
                            (data.item.type === 4 && !data.item.hasChild)
                        "
                    >
                        {{ data.item.comment_txt }}
                    </div>
                </template>
                <template #cell(history)="data">
                    <template v-if="editing">
                        <b-dropdown dropleft class="more">
                            <template v-slot:button-content>
                                <i class="icon icon-more"></i>
                            </template>
                            <template
                                v-if="data.item.type === 3 && !firstVariant"
                            >
                                <b-dropdown-item @click="addPrg(data.item)">
                                    Добавить программу
                                </b-dropdown-item>
                            </template>
                            <template
                                v-if="
                                    data.item.type === 4 &&
                                    data.item.pprChild &&
                                    !firstVariant
                                "
                            >
                                <b-dropdown-item @click="addPpr(data.item)">
                                    Добавить подпрограмму
                                </b-dropdown-item>
                            </template>
                            <template
                                v-if="
                                    data.item.type === 5 ||
                                    (data.item.type === 4 &&
                                        !data.item.hasChild)
                                "
                            >
                                <b-dropdown-item
                                    @click="commentClk(data.item.agrObj)"
                                >
                                    Журнал изменений
                                </b-dropdown-item>
                                <b-dropdown-item
                                    v-if="
                                        (data.item.type === 5 ||
                                            (data.item.type === 4 &&
                                                !data.item.pprChild)) &&
                                        data.item.status !== 22 &&
                                        !firstVariant
                                    "
                                    @click="addSpf(data.item)"
                                >
                                    Добавить специфику
                                </b-dropdown-item>
                            </template>
                            <b-dropdown-item
                                v-if="
                                    data.item.status !== 22 &&
                                    checkStatusPpr(data.item) === 0 &&
                                    !firstVariant
                                "
                                @click="deleteDatas(data.item)"
                            >
                                Удалить
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </template>
                <template #bottom-row="data">
                    <td class="text-right" colspan="7">ИТОГО (тыс.тенге)</td>
                    <td class="text-right">{{ $n(total("receipt_prev")) }}</td>
                    <td class="text-right">{{ $n(total("plan")) }}</td>
                    <td class="text-right">{{ $n(total("request1")) }}</td>
                    <td class="text-right">{{ $n(total("plan1")) }}</td>
                    <td class="text-right">{{ $n(total("request2")) }}</td>
                    <td class="text-right">{{ $n(total("plan2")) }}</td>
                    <td class="text-right">{{ $n(total("request3")) }}</td>
                    <td class="text-right">{{ $n(total("plan3")) }}</td>
                </template>
            </b-table>
            <loading
                :active="loading"
                is-full-screen
                spinner="bar-fade-scale"
                color="#6495ED"
            />
        </div>
        <c-budg-agr-modal
            ref="refAgrModal"
            :agrBtnLst="agrBtnLst"
            @agrClk="agrClick"
        ></c-budg-agr-modal>
        <c-budg-agr-hist-modal ref="refHistModal"></c-budg-agr-hist-modal>
        <c-guelevel-modal ref="refGuLvlModal"></c-guelevel-modal>
    </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import store from "@/services/store";
import VueElementLoading from "vue-element-loading";
import CBudgetAgreementModal from "@/modules/budget-request/components/budget-sum-calc/budget-agreement-modal.vue";
import CBudgetAgreementHistModal
    from "@/modules/budget-request/components/budget-sum-calc/budget-agreement-hist-modal.vue";
import CBudgetSumCalcGuLevelModal from "../budget-request/components/budget-sum-calc/budget-sum-calc-gulevel-modal";
import {
    makeToast,
    years,
    setActualYear,
    sortByField,
    findItem,
    getRowKey,
} from "@/modules/budget/bip/bip-types";
import {CUR_YEAR} from "@/modules/budget-request/components/js/budgetCurYear";

export default {
    name: "CostDataForm",
    components: {
        loading: VueElementLoading,
        "c-budg-agr-modal": CBudgetAgreementModal,
        "c-budg-agr-hist-modal": CBudgetAgreementHistModal,
        "c-guelevel-modal": CBudgetSumCalcGuLevelModal,
    },
    props: {
        location: null,
        listReg: [],
        versionList: [],
        regionBudgetList: [],
    },
    data() {
        return {
            tableFields: [
                {
                    key: "check",
                    label: "",
                    class: "toggle-show",
                },
                {
                    key: "action",
                    label: "",
                    class: "toggle-show",
                },
                {
                    key: "abp",
                    label: "АБП",
                    class: "toggle-show",
                },
                {
                    key: "prg",
                    label: "БП",
                    class: "toggle-show",
                },
                {
                    key: "ppr",
                    label: "БПП",
                },
                {
                    key: "spf",
                    label: "СП",
                },
                {
                    key: "name_ru",
                    label: "Наименование",
                },
                {
                    key: "receipt_prev",
                    label: "",
                },
                {
                    key: "plan",
                    label: "",
                },
                {
                    key: "request1",
                    label: "БЗ АБП",
                    variant: "info",
                },
                {
                    key: "plan1",
                    label: "Согласовано УЭБП/ОЭБП",
                },
                {
                    key: "request2",
                    label: "БЗ АБП",
                    variant: "info",
                },
                {
                    key: "plan2",
                    label: "Согласовано УЭБП/ОЭБП",
                },
                {
                    key: "request3",
                    label: "БЗ АБП",
                    variant: "info",
                },
                {
                    key: "plan3",
                    label: "Согласовано УЭБП/ОЭБП",
                },
                {
                    key: "status",
                    label: "Статус",
                },
                {
                    key: "comments",
                    label: "Комментарий",
                },
                {
                    key: "history",
                    label: "",
                },
                {
                    key: "filter",
                    label: "filter",
                    thClass: "d-none",
                    tdClass: "d-none",
                },
            ],
            calcFlds: [
                "receipt_prev",
                "plan",
                "request1",
                "plan1",
                "request2",
                "plan2",
                "request3",
                "plan3",
                "request1_1",
                "plan1_1",
                "request2_1",
                "plan2_1",
                "request3_1",
                "plan3_1",
                "request1_2",
                "plan1_2",
                "request2_2",
                "plan2_2",
                "request3_2",
                "plan3_2",
            ],

            budgetForm: [],
            budgetTable: [],
            pprMap: new Map(),
            valMap: new Map(),

            dictTree: null,
            selectedAbp: null,

            selRegionBudget: null,

            dataTypeList: [],
            selDataType: null,

            selVersion: null,

            prgList: [],
            pprList: [],
            spfList: [],

            years: [],
            planPeriod: {year: 0, name: ""},

            selRegion: null,

            agrMap: new Map(),
            agrBtnLst: {back: [], forward: []},
            operationCode: [],

            idPrg: 0,
            idPpr: 0,
            idSpf: 0,
            filter: {
                abp: null,
                prg: null,
                ppr: null,
                spf: null,
                name_ru: null,
                search: null,
                on: ["filter", "abp", "prg", "ppr", "spf", "name_ru"],
            },
            open: false,
            check: false,
            loading: false,
            openFilterWindow: false,
            mode_code: "bp",
            userUiid: null,
            userLevel: 0,
            k: 0,
            firstVariant: false,
        };
    },

    async created() {
        this.$watch("filter.abp", this.filterUpdate);
        this.$watch("filter.prg", this.filterUpdate);
        this.$watch("filter.ppr", this.filterUpdate);
        this.$watch("filter.spf", this.filterUpdate);
        this.$watch("filter.name_ru", this.filterUpdate);
        this.$watch("planPeriod", () => {
            this.updateVersion();
            this.chgData();
            this.loadSpfList();
            this.setDataType();
        });
        this.$watch("selRegion", () => {
            this.updateVersion();
            this.chgData();
        });
        this.$watch("selDataType", () => {
            this.updateVersion();
            this.chgData();
        });
        this.$watch("selVersion", (value) => {
            if (value) {
                this.firstVariant = this.firstVersion;
                this.updateDatas();
            }
        });
        this.$watch("selectedAbp", () => {
            this.loadTotalAgreement();
            this.chgData();
        });
        this.$watch("check", this.checkAll);

        for (const item of this.listReg) {
            if (item.code === this.location.region) {
                this.selRegion = item;
                break;
            }
        }
    },

    async mounted() {
        years(this, this.years);
        setActualYear(this, this.versionList, this.location.region, this.years);

        this.userUiid = store.state.user.sub;
        this.selRegionBudget = this.regionBudgetList[0];
        await this.loadDataTypeList();
        await this.loadOperations();
        await this.loadUserLevels();
        // setTimeout(() => {
        //     this.updateDatas();
        // }, 3000);
    },

    beforeUpdate() {
        for (const row of this.budgetForm) {
            if ([0, 3, 4, 5].includes(row.type) && row.hasChild) {
                row._rowVariant = "info";
            }
            if ([6].includes(row.type) && !row.hasChild) {
                this.$set(row, "editable", true);
                if (row.status === 22) {
                    this.$set(row, "editable", false);
                }
            }
        }
    },

    computed: {
        editing() {
            return (
                [2, 3].includes(this.userLevel) &&
                this.selVersion &&
                this.selVersion !== null &&
                this.selVersion.attribute
            );
        },

        filterVersion() {
            let result = [];
            if (
                this.selRegion &&
                this.selDataType &&
                this.selDataType !== null
            ) {
                result = this.versionList.filter(
                    (row) =>
                        !row.is_deleted &&
                        parseInt(row.year) === parseInt(this.planPeriod.year) &&
                        row.region_code === this.selRegion.code &&
                        parseInt(row.data_type) ===
                        parseInt(this.selDataType.code)
                );
            }
            for (const row of result) {
                if (row.date_start === null) {
                    this.$set(row, "ds", row.year + "-12-31");
                } else {
                    this.$set(row, "ds", row.date_start.toString());
                }
            }
            return result;
        },

        firstVersion() {
            if (
                this.selDataType.code !== null &&
                parseInt(this.selDataType.code) !== 1
            ) {
                return false;
            }
            if (this.filterVersion) {
                let list = this.filterVersion.filter(
                    (row) => !row.is_deleted && row.data_type === 1
                );
                if (list.length > 0) {
                    list = list.sort(sortByField("ds"));
                    let first = list[0];
                    list = list.filter((row) => row.ds === first.ds);
                    if (list.length > 1) {
                        list = list.sort(sortByField("id"));
                        first = list[0];
                    }
                    return this.selVersion.variant_uuid === first.variant_uuid;
                }
            }
            return false;
        },
    },

    methods: {
        async addAbp() {
            await this.loadTotalAgreement();
            await this.prepareForm();
            await this.rememberDatas();
            await this.changeFields();
            this.chgData();
        },

        addPrg(data) {
            const item = {
                id: --this.idPrg,
                parent_id: data.id,
                gr: data.gr,
                pgr: data.pgr,
                abp: data.abp,
                prg: null,
                ppr: null,
                spf: null,
                code: null,
                type: 4,
                visible: true,
                edited: false,
                open: true,
            };
            this.updateIndex(data, item);

            for (const abp of this.dictTree) {
                if (abp.abp === item.abp) {
                    this.prgList = abp.value.child;
                } else {
                    continue;
                }
            }
        },
        sortByField(field) {
            return (a, b) => (a[field] > b[field] ? 1 : -1);
        }, // сортирует по заданному полю

        addPpr(data) {
            const item = {
                id: --this.idPpr,
                parent_id: data.id,
                gr: data.gr,
                pgr: data.pgr,
                abp: data.abp,
                prg: data.prg,
                ppr: null,
                spf: null,
                code: null,
                type: 5,
                visible: true,
                edited: false,
                open: true,
            };
            this.updateIndex(data, item);

            for (const abp of this.dictTree) {
                if (abp.abp === item.abp) {
                    const prgList = abp.value.child;
                    for (const prg of prgList) {
                        if (prg.prg === item.prg) {
                            this.pprList = prg.value.child;
                        } else {
                            continue;
                        }
                    }
                } else {
                    continue;
                }
            }
        },

        addSpf(data) {
            const item = {
                id: --this.idSpf,
                parent_id: data.id,
                gr: data.gr,
                pgr: data.pgr,
                abp: data.abp,
                prg: data.prg,
                ppr: data.ppr,
                spf: null,
                code: null,
                type: 6,
                visible: true,
                edited: false,
            };
            this.updateIndex(data, item);
        },

        async agrClick(data) {
            await this.saveDatas();
            data.userId = store.state.user.sub;
            data.variant = this.selVersion.variant_uuid;
            data.region = this.selRegion.code;
            data.data_type = this.selDataType.code;

            const saveObj = [];
            for (const el of this.selectArr) {
                saveObj.push(
                    Object.assign(
                        {id: el.agrObj.id, consExp: el.consExp},
                        data
                    )
                );
            }
            let result = await fetch("/api-py/set_brft_agreement_step_abp", {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",
                body: JSON.stringify(saveObj),
            });
            if (result.status === 200) {
                result = await result.json();
                for (const res of result) {
                    if (res.result === "error") {
                        makeToast(
                            this,
                            "danger",
                            "Сохранение status",
                            `Ошибка ${result.data}`
                        );
                        return;
                    }
                }
                this.updateDatas();
            } else {
                makeToast(
                    this,
                    "danger",
                    "Сохранение status",
                    `Ошибка ${result.status} ${result.statusText}`
                );
                return;
            }
        },

        // кнопка действия
        async agreementEvent() {
            const selectArr = [];
            this.selectArr = [];
            let firstStatus = null;
            this.agrBtnLst = {back: [], forward: []};
            for (const row of this.budgetForm) {
                if (
                    row.check &&
                    row.type === 4 &&
                    !row.hasChild &&
                    row.status
                ) {
                    // без подпрограммы
                    if (firstStatus === null) {
                        firstStatus = row.status;
                    }
                    if (firstStatus !== row.status) {
                        makeToast(
                            this,
                            "danger",
                            "Согласование",
                            "Выберите подпрограммы с одинаковым статусом!"
                        );
                        return;
                    }
                    selectArr.push({
                        consExp: row.consExp,
                        agrObj: row.agrObj,
                    });
                }
                if (row.check && row.type === 5 && row.status) {
                    if (firstStatus === null) {
                        firstStatus = row.status;
                    }
                    if (firstStatus !== row.status) {
                        makeToast(
                            this,
                            "danger",
                            "Согласование",
                            "Выберите подпрограммы с одинаковым статусом!"
                        );
                        return;
                    }
                    selectArr.push({
                        consExp: row.consExp,
                        agrObj: row.agrObj,
                    });
                }
            }
            if (selectArr.length === 0) {
                makeToast(
                    this,
                    "warning",
                    "Согласование",
                    "Не выбрано ни одной программы/подпрограммы!"
                );
                return;
            }

            this.selectArr = selectArr;
            const params = {
                modeCode: this.mode_code,
                operationCode: this.operationCode,
                agrCode: firstStatus,
            };
            let result = null;
            try {
                const response = await fetch(
                    `/api-py/get-agreement-step-next-back/${encodeURI(
                        JSON.stringify(params)
                    )}`
                );
                result = await response.json();
            } catch (error) {
                makeToast(
                    this,
                    "danger",
                    "Ошибка get-agreement-step-next-back",
                    error.toString()
                );
                return;
            }
            if (result.length === 0) {
                makeToast(
                    this,
                    "warning",
                    "Согласование",
                    "Нет доступных шагов согласования!"
                );
                return;
            }
            for (const el of result) {
                if (el.stepType === 1) {
                    this.agrBtnLst.back.push(el);
                } else {
                    this.agrBtnLst.forward.push(el);
                }
            }

            this.$refs.refAgrModal.showEvent();
        },

        // отмечает все ветки
        checkAll() {
            for (const row of this.budgetForm) {
                row.check = this.check;
            }
        },

        // очищает данные таблицы
        clearDatas() {
            for (const ppr of this.pprMap.values()) {
                this.calcFlds.forEach((field) => {
                    this.$set(ppr, field, 0);
                });
            }
        },

        // показать ГУ
        clkShowGu(obj, prog) {
            const param = {
                year: this.planPeriod.year + prog,
                variant: this.selVersion.variant_uuid,
                abp: this.selectedAbp.abp,
                curYear: this.planPeriod.year,
                dataType: this.selDataType.code,
                prg: obj.prg,
                ppr: obj.ppr,
                spf: obj.spf,
            };
            // eslint-disable-next-line
            // @ts-ignore
            this.$refs.refGuLvlModal.showEvent(param);
        },

        // древовидную выборку преобразовывает в таблицу (для отображения)
        createTable(elem, parentId) {
            // создание таблицы на основе дерева
            const that = this;

            const item = Object.assign({}, elem);
            that.$set(item, "parent_id", parentId); // установка id родителя
            that.$set(item, "check", false); // флажок для кнопок "Действия"
            that.$set(item, "index", that.budgetForm.length); // установка индекса строки
            that.$set(that.budgetForm, that.budgetForm.length, item); // добавление элемента в таблицу
            Object.defineProperty(item, "parent", {
                get: function () {
                    for (const row of that.budgetForm) {
                        if (item.parent_id === row.id) {
                            return row;
                        }
                    }
                    return null;
                },
            }); // поиск родителя
            if (item.hasOwnProperty("child") && item.child.length > 0) {
                that.$set(item, "hasChild", true);
            } // hasChild - признак наличия подветок
            if ([4, 5].includes(item.type) && !item.pprChild) {
                that.$set(item, "open", false);
            } else {
                that.$set(item, "open", true);
            } // открыть все ветки кроме уровня SPF
            if (item.type === 6) {
                that.$set(item, "visible", false);
            } else {
                that.$set(item, "visible", true);
            } // показать все ветки кроме уровня SPF

            if ([4, 5].includes(item.type) && !item.pprChild) {
                const consExp = {
                    cur_year: this.planPeriod.year,
                    data_type: this.selDataType,
                    abp: item.abp,
                    prg: item.prg,
                    ppr: item.ppr,
                };
                that.$set(item, "consExp", consExp);

                const agr = this.agrMap.get(
                    getRowKey(item, ["abp", "prg", "ppr"])
                );
                if (agr) {
                    this.$set(item, "availableStep", agr.availableStep);
                }
                const status_lang = {
                    name_kk: item.status_name_kk,
                    name_en: item.status_name_en,
                    name_ru: item.status_name_ru,
                    availableStep: agr ? agr.availableStep : false,
                };
                const agrObj = {
                    id: item.agr_id,
                    status: item.status,
                    updateDate: item.update_date,
                    userId: item.user_id,
                    commentTxt: item.comment_txt,
                    statusName: item.status_name_ru,
                    status_lang: status_lang,
                };
                that.$set(item, "agrObj", agrObj);
            }

            if (!item.hasChild) {
                that.calcFlds.forEach((field) => {
                    if (item.hasOwnProperty(field)) {
                        that.$set(item, field, parseFloat(item[field]));
                    } else {
                        that.$set(item, field, 0);
                    }
                });

                if ([4, 5, 6].includes(item.type)) {
                    that.pprMap.set(
                        getRowKey(item, ["abp", "prg", "ppr", "spf"]),
                        item
                    );
                    // that.selPprMap.set(getRowKey(item, ['abp', 'prg', 'ppr', 'spf']), elem);
                }
            } else {
                that.calcFlds.forEach((field) => {
                    Object.defineProperty(item, field, {
                        get: function () {
                            return that.reSum(item, field);
                        },
                    });
                });

                for (const ch of item.child) {
                    if ([4, 5].includes(item.type)) {
                        this.$set(ch.value, "develop_type", item.develop_type);
                    }
                    that.createTable(ch.value, item.id);
                }
                delete item.child;
            }
        },

        // сравнивает введенные данные с ранее сохраненные и формирует массив новых записей для сохранения в БД
        compareDatas() {
            const user = store.state.user.sub;

            const saveDatas = [];
            for (const row of this.pprMap.values()) {
                if (row.edited) {
                    const val = this.valMap.get(
                        getRowKey(row, ["abp", "prg", "ppr", "spf"])
                    );

                    if (!val || JSON.stringify(row) !== val) {
                        saveDatas.push(
                            this.newCost(
                                row.type,
                                row.gr ? row.gr : null,
                                row.pgr ? row.pgr : null,
                                row.abp,
                                row.prg,
                                row.ppr,
                                row.spf,
                                parseFloat(row.plan1),
                                parseInt(this.planPeriod.year),
                                parseInt(this.selDataType.code),
                                this.selRegion.code,
                                this.selVersion.variant_uuid,
                                user
                            )
                        );
                        saveDatas.push(
                            this.newCost(
                                row.type,
                                row.gr ? row.gr : null,
                                row.pgr ? row.pgr : null,
                                row.abp,
                                row.prg,
                                row.ppr,
                                row.spf,
                                parseFloat(row.plan2),
                                parseInt(this.planPeriod.year) + 1,
                                parseInt(this.selDataType.code),
                                this.selRegion.code,
                                this.selVersion.variant_uuid,
                                user
                            )
                        );
                        saveDatas.push(
                            this.newCost(
                                row.type,
                                row.gr ? row.gr : null,
                                row.pgr ? row.pgr : null,
                                row.abp,
                                row.prg,
                                row.ppr,
                                row.spf,
                                parseFloat(row.plan3),
                                parseInt(this.planPeriod.year) + 2,
                                parseInt(this.selDataType.code),
                                this.selRegion.code,
                                this.selVersion.variant_uuid,
                                user
                            )
                        );

                        if (row.id < 0) {
                            this.$set(row, "id", 0);
                        }
                    }
                }
            }
            return saveDatas;
        },

        commentClk(agrObj) {
            if (agrObj && agrObj.id) {
                this.$refs.refHistModal.showEvent(agrObj);
            }
        },

        changeFields() {
            for (const row of this.budgetForm) {
                if ([3, 4, 5].includes(row.type)) {
                    row.visible = true;
                }
                if (!row.hasChild) {
                    [
                        "plan1",
                        "plan2",
                        "plan3",
                        "request1",
                        "request2",
                        "request3",
                    ].forEach((field) => {
                        const f = field + "_" + this.selDataType.code;
                        if (row.hasOwnProperty(f)) {
                            this.$set(row, field, parseFloat(row[f]));
                        } else {
                            this.$set(row, field, 0);
                        }
                    });
                }
            }
            this.deleteNulls();
        },

        chgData() {
            if (
                this.selVersion &&
                this.selVersion !== null &&
                this.selDataType &&
                this.selDataType !== null
            ) {
                const data = {
                    year: this.planPeriod.year,
                    region: this.selRegion.code,
                    variant: this.selVersion.variant_uuid,
                    selABP: this.selectedAbp,
                    dataType: parseInt(this.selDataType.code),
                };
                this.$emit("chgData", data);
            }
        },

        checkStatusPpr(item) {
            let status = 0;
            if (item.type === 4) {
                for (const row of this.budgetForm) {
                    if (
                        item.id === row.parent_id &&
                        [22].includes(row.status)
                    ) {
                        status++;
                    }
                }
            }

            if (item.type === 6) {
                for (const row of this.budgetForm) {
                    if (
                        item.parent_id === row.id &&
                        [22].includes(row.status)
                    ) {
                        status++;
                    }
                }
            }
            return status;
        },

        async deleteDatas(item) {
            let sms = "";
            switch (item.type) {
                case 3:
                    sms =
                        '"Согласовано УЭБП/ОЭБП" АБП=' +
                        item.abp +
                        ", удаленные данные восстановлению не подлежат. Удалить данные?";
                    break;
                case 4:
                    sms =
                        '"Согласовано УЭБП/ОЭБП" АБП=' +
                        item.abp +
                        " БП=" +
                        item.prg +
                        ", удаленные данные восстановлению не подлежат. Удалить данные?";
                    break;
                case 5:
                    sms =
                        '"Согласовано УЭБП/ОЭБП" АБП=' +
                        item.abp +
                        " БП=" +
                        item.prg +
                        " БПП=" +
                        item.ppr +
                        ", удаленные данные восстановлению не подлежат. Удалить данные?";
                    break;
                case 6:
                    sms =
                        '"Согласовано УЭБП/ОЭБП" АБП=' +
                        item.abp +
                        " БП=" +
                        item.prg +
                        " БПП=" +
                        item.ppr +
                        " СПФ=" +
                        item.spf +
                        ", удаленные данные восстановлению не подлежат. Удалить данные?";
                    break;
                default:
                    break;
            }
            await this.$bvModal
                .msgBoxConfirm(sms, {
                    title: "Подтверждение",
                    size: "lg",
                    buttonSize: "sm",
                    okVariant: "success",
                    okTitle: "ДА",
                    cancelTitle: "Отмена",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        if (item.type == 3) {
                            for (const row of this.budgetForm) {
                                if (row.editable && row.abp === item.abp) {
                                    ["plan1", "plan2", "plan3"].forEach(
                                        (field) => {
                                            this.$set(row, field, 0);
                                        }
                                    );
                                    this.$set(row, "edited", true);
                                    this.$set(row, "edit");
                                }
                            }
                        }
                        if (item.type == 4) {
                            for (const row of this.budgetForm) {
                                if (
                                    row.editable &&
                                    getRowKey(row, ["abp", "prg"]) ===
                                    getRowKey(item, ["abp", "prg"])
                                ) {
                                    ["plan1", "plan2", "plan3"].forEach(
                                        (field) => {
                                            this.$set(row, field, 0);
                                        }
                                    );
                                    this.$set(row, "edited", true);
                                }
                            }
                        }
                        if (item.type == 5) {
                            for (const row of this.budgetForm) {
                                if (
                                    row.editable &&
                                    getRowKey(row, ["abp", "prg", "ppr"]) ===
                                    getRowKey(item, ["abp", "prg", "ppr"])
                                ) {
                                    ["plan1", "plan2", "plan3"].forEach(
                                        (field) => {
                                            this.$set(row, field, 0);
                                        }
                                    );
                                    this.$set(row, "edited", true);
                                }
                            }
                        }
                        if (item.type == 6) {
                            ["plan1", "plan2", "plan3"].forEach((field) => {
                                this.$set(item, field, 0);
                            });
                            this.$set(item, "edited", true);
                        }
                        this.saveDatas();
                    }
                })
                .catch((error) => {
                    makeToast(
                        this,
                        "danger",
                        "Ошибка сохранения",
                        error.toString()
                    );
                });
        },

        deleteNulls() {
            for (const row of this.budgetForm) {
                let sum = 0;

                [
                    "receipt_prev",
                    "plan",
                    "plan1",
                    "plan2",
                    "plan3",
                    "request1",
                    "request2",
                    "request3",
                ].forEach((field) => {
                    sum += parseFloat(row[field]);
                });
                if (sum === 0) {
                    row.visible = false;
                }
            }
        },

        existElem(item) {
            let count = 0;

            for (const row of this.budgetForm) {
                ["abp", "prg", "ppr", "spf"].forEach((field) => {
                    if (row[field] === undefined || row[field] === "None") {
                        this.$set(row, field, null);
                    }
                });
                if (
                    getRowKey(row, ["abp", "prg", "ppr", "spf"]) ===
                    getRowKey(item, ["abp", "prg", "ppr", "spf"])
                ) {
                    if (item.spf) {
                        count++;
                        continue;
                    }

                    if (row.visible === true) {
                        count++;
                    }
                }
            }
            if (count > 1) {
                return true;
            }
            return false;
        },

        // формирует строку поиска
        filterUpdate() {
            this.filter.search =
                this.isStr(this.filter.abp) +
                this.isStr(this.filter.prg) +
                this.isStr(this.filter.ppr) +
                this.isStr(this.filter.spf) +
                this.isStr(this.filter.name_ru);
            if (this.filter.search.length === 0) {
                this.filter.search = null;
            }
        },

        // форматирует введенное значение до digit цифр после запятой
        inputFixed(item, field, value, digit) {
            this.$set(item, field, this.number(value).toFixed(digit));
            this.$set(item, "edited", true);
        },

        inputPrg(item) {
            for (const [key, value] of Object.entries(item.code.value)) {
                this.$set(item, key, value);
            }
            this.$set(item, "name_ru", item.code.name);

            if (!item.code.value.pprChild) {
                this.$set(item, "edited", true);
                this.calcFlds.forEach((field) => {
                    this.$set(item, field, 0);
                });
            } else {
                this.$set(item, "edited", true);
            }

            if (this.existElem(item)) {
                makeToast(
                    this,
                    "danger",
                    "Добавление новой записи",
                    `Данная программа уже присутствует в списке!`
                );
                return;
            }
            this.pprMap.set(
                getRowKey(item, ["abp", "prg", "ppr", "spf"]),
                item
            );
        },

        inputPpr(item) {
            for (const [key, value] of Object.entries(item.code.value)) {
                this.$set(item, key, value);
            }
            this.$set(item, "name_ru", item.code.name);
            this.$set(item, "edited", true);
            this.calcFlds.forEach((field) => {
                this.$set(item, field, 0);
            });

            if (this.existElem(item)) {
                makeToast(
                    this,
                    "danger",
                    "Добавление новой записи",
                    `Данная программа + подпрограмма уже присутствуют в списке!`
                );
                return;
            }
            this.pprMap.set(
                getRowKey(item, ["abp", "prg", "ppr", "spf"]),
                item
            );
        },


        inputSpf(item) {
            console.log('Item (inputSpf): ');
            console.log(item);

            // "receipt_prev",
            //     "plan",
            //     "request1",
            //     "plan1",
            //     "request2",
            //     "plan2",
            //     "request3",
            //     "plan3",
            //     "request1_1",
            //     "plan1_1",
            //     "request2_1",
            //     "plan2_1",
            //     "request3_1",
            //     "plan3_1",
            //     "request1_2",
            //     "plan1_2",
            //     "request2_2",
            //     "plan2_2",
            //     "request3_2",
            //     "plan3_2",

            this.$set(item, "spf", item.code.spf);
            this.$set(item, "name_ru", item.code.name_ru);
            this.$set(item, "edited", true);
            this.calcFlds.forEach((field) => {
                this.$set(item, field, 0);
            });


            if (this.existElem(item)) {
                makeToast(
                    this,
                    "danger",
                    "Добавление новой записи",
                    `Данная программа + подпрограмма + специфика уже присутствуют в списке!`
                );
                return;
            }
            this.pprMap.set(
                getRowKey(item, ["abp", "prg", "ppr", "spf"]),
                item
            );
        },

        isStr(value) {
            return value === null || value === "None" ? "" : value;
        },

        // enter работает как tab
        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll("input"); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex((el) =>
                currentNode.isEqualNode(el)
            );
            // focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        },

        async loadTotalAgreement() {
            if (!this.operationCode.length) {
                return new Map();
            }
            const params = JSON.stringify({
                curYear: this.planPeriod.year,
                abp: this.selectedAbp.abp,
                dataType: this.selDataType.code,
                region: this.selRegion.code,
                variant: this.selVersion.variant_uuid,
                mode_code: this.mode_code,
                operation_code: this.operationCode,
            });
            let result = null;
            try {
                const response = await fetch(
                    `/api-py/get-budget-total-agreement-abp/${encodeURI(
                        params
                    )}`
                );
                result = await response.json();

                for (const row of result) {
                    this.$set(row, "abp", this.selectedAbp.abp);
                    this.agrMap.set(getRowKey(row, ["abp", "prg", "ppr"]), row);
                }
            } catch (error) {
                makeToast(
                    this,
                    "danger",
                    "Ошибка get-budget-total-agreement",
                    error.toString()
                );
                this.progress = 0;
                return new Map();
            }
            // return this.createTreeMap(result, true, true);
        },

        async loadDataTypeList() {
            this.dataTypeList = [];
            try {
                const response = await fetch(
                    "/api-py/dictionary/budget_data_types/"
                ).then((response) => response.json());
                for (const dt of response) {
                    if (["1", "2", "3", "4"].includes(dt.code)) {
                        // this.$set(dt, '$isDisabled', true);
                        this.dataTypeList.push(dt);
                    }
                }
                this.setDataType();
            } catch (error) {
                makeToast(
                    this,
                    "danger",
                    "Ошибка загрузки loadDataTypeList",
                    error.toString()
                );
            }
        },

        setDataType() {
            const vers = this.versionList.filter(
                (row) =>
                    row.is_deleted === null ||
                    (!row.is_deleted &&
                        row.year === this.planPeriod.year &&
                        this.selRegionBudget &&
                        this.selRegionBudget !== null &&
                        row.region_code === this.selRegionBudget.code)
            );
            vers.sort(sortByField("date_ueb", "desc"));

            for (const ver of vers) {
                if (ver.attribute) {
                    this.selVersion = ver;
                    this.selDataType = findItem(
                        this.selVersion.data_type,
                        "code",
                        this.dataTypeList
                    );
                    break;
                }
            }
        },

        async loadDictTree() {
            try {
                if (this.selVersion) {
                    const response = await fetch(
                        "/api-py/cost-data-form/0/" +
                        this.planPeriod.year +
                        "/" +
                        this.selRegion.code +
                        "/" +
                        this.selVersion.variant_uuid
                    );
                    this.dictTree = await response.json();
                    console.log(this.selVersion.variant_uuid, this.dictTree);
                }
            } catch (error) {
                makeToast(
                    this,
                    "danger",
                    "Ошибка запроса loadDictTree",
                    error.toString()
                );
            }
        },

        async loadSpfList() {
            try {
                const response = await fetch(
                    "/api-py/get-spf-list/" + this.planPeriod.year
                ).then((response) => response.json());
                this.spfList = response.list;
            } catch (error) {
                makeToast(
                    this,
                    "danger",
                    "Ошибка загрузки loadSpfList",
                    error.toString()
                );
            }
        },

        async loadOperations() {
            this.operationCode = [];
            if (store.state.user.sub === null) {
                return;
            }
            let result = null;
            try {
                result = await fetch(
                    encodeURI(
                        `/api/um/module/link?user=${store.state.user.sub}&modulecode=004.003.003`
                    )
                ).then((response) => response.json());
                console.log(store.state.user.sub, result);
            } catch (error) {
                makeToast(
                    this,
                    "danger",
                    "Ошибка загрузки операций пользователя",
                    error.toString()
                );
                return;
            }
            if (result.operations) {
                this.operationCode = result.operations;
            }
        },

        async loadUserLevels() {
            let response = [];
            try {
                if (this.userUiid !== null && this.userUiid.length > 0) {
                    response = await fetch(
                        "/api-py/user-modules/" + this.userUiid
                    );
                    response = await response.json();
                    for (const row of response) {
                        if (row.modules === "004.003.003") {
                            this.userLevel = row.access_level;
                            return;
                        }
                    }
                }
            } catch (error) {
                makeToast(
                    this,
                    "danger",
                    "Ошибка loadUserLevels",
                    error.toString()
                );
            }
        },

        // формирует элемент для сохранение в БД
        newCost(
            type,
            gr,
            pgr,
            abp,
            prg,
            ppr,
            spf,
            value,
            year,
            data_type,
            region,
            variant,
            user_name
        ) {
            return {
                type: type,
                gr: gr,
                pgr: pgr,
                abp: abp,
                prg: prg,
                ppr: ppr,
                spf: spf,
                value: value,
                year: year,
                data_type: data_type,
                region: region,
                variant: variant,
                user_name: user_name,
            };
        },

        newStatus(row) {
            if (row.request1 + row.request2 + row.request3 > 0) {
                return true;
            }
            return false;
        },

        number(value) {
            return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
        },

        // вводит по заданному паттерну
        keyPress: function (event, pattern) {
            // const regex = new RegExp('^[0-9]+$');
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            // const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(
                !event.charCode ? event.which : event.charCode
            );
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        },

        // открывает.закрывает все ветки
        openAll() {
            this.open = !this.open;

            for (const row of this.budgetForm) {
                row.visible = this.open;
                if ([3, 4].includes(row.type)) {
                    row.open = this.open;
                }
                if (!this.open && row.type === 5) {
                    row.open = this.open;
                }
                if ([3].includes(row.type)) {
                    row.visible = true;
                }
            }
            this.deleteNulls();
        },

        // открывает/закрывает ветку до конечного элемента
        openChilds(parent, bool) {
            parent.open = bool !== undefined ? bool : !parent.open;

            for (const row of this.budgetForm) {
                if (parent.id === row.parent_id) {
                    if (
                        [3, 4].includes(row.type) ||
                        (!parent.open && row.type === 5)
                    ) {
                        this.openChilds(row, parent.open);
                    }
                    row.visible = parent.open;
                }
            }
            this.deleteNulls();
        },

        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            const refItem = this.$refs[refName];
            setTimeout(() => refItem.$el.focus(), 100);
        },

        // подготовка отображения данных
        async prepareForm() {
            await this.budgetForm.splice(0);
            await this.createTable(this.selectedAbp, 0);
        },

        // расставляет сохранненные данные по полям
        async rememberDatas() {
            this.valMap.clear();
            for (const ppr of this.pprMap.values()) {
                this.$set(ppr, "edited", false);
                this.valMap.set(
                    getRowKey(ppr, ["abp", "prg", "ppr", "spf"]),
                    JSON.stringify(ppr)
                );
            }
        },

        // пересчет суммы
        reSum(parent, field) {
            let sum = 0;
            this.budgetForm.forEach((row) => {
                if (
                    row.parent_id === parent.id &&
                    [row.type - 1, row.type - 2].includes(parent.type)
                ) {
                    sum += parseFloat(row[field]);
                }
            });
            return parseFloat(sum.toFixed(2));
        },

        // задает класс 'is-hidden' заданной строке
        rowClass(item, type) {
            if (!item || type !== "row") {
                return;
            }
            if (!item.visible) {
                return "is-hidden";
            }
        },

        openCorrect() {
            let url_name = "";
            if (this.selVersion.data_type) {
                if (this.selVersion.data_type === 4) {
                    url_name = "correct";
                    window.open(
                        "/#/budget-" +
                        url_name +
                        "?tab=1&version=" +
                        this.selVersion.variant_uuid,
                        "_blank"
                    );
                } else if (this.selVersion.data_type === 3) {
                    url_name = "clarify";
                    window.open(
                        "/#/budget-" +
                        url_name +
                        "?tab=1&version=" +
                        this.selVersion.variant_uuid,
                        "_blank"
                    );
                }
            }
        },

        // вызывает сохранение записей
        async saveDatas() {
            const res = this.compareDatas();
            if (res.length > 0) {
                this.$bvModal
                    .msgBoxConfirm(
                        "Вы действительно хотите сохранить данные?",
                        {
                            title: "Подтверждение",
                            size: "lg",
                            buttonSize: "sm",
                            okVariant: "success",
                            okTitle: "ДА",
                            cancelTitle: "Отмена",
                            footerClass: "p-2",
                            hideHeaderClose: false,
                            centered: true,
                        }
                    )
                    .then((value) => {
                        if (value) {
                            this.saveVariant(res);
                        }
                    })
                    .catch((error) => {
                        makeToast(
                            this,
                            "danger",
                            "Ошибка сохранения",
                            error.toString()
                        );
                    });
            }
        },

        // сохранение записей в БД
        async saveVariant(values) {
            const items = [values[0]];
            for (const el of values) {
                if (el.ppr !== items.at(-1).ppr || el.spf !== items.at(-1).spf) {
                    items.push(el);
                }
            }

            for (let i = 0; i < items.length; i++) {
                if (items[i].spf === null && items[i + 1]?.ppr === null) {
                    items[i].spf = items[i + 1].spf;
                    items.splice(i + 1, 1);
                }
            }


            for (const x of items) {
                const prg = this.budgetTable.find(y => y.prg === x.prg);
                if (x.spf === null || (x.ppr === null && prg.pprChild === true)) {
                    makeToast(this, 'danger', 'Предупреждение', 'Необходимо заполнить программу/подпрограмму/специфику');
                    return;
                }
            }

            const data = [];
            for (const el of items) {
                for (const x of values.filter(v => v.spf === el.spf && v.type === 6)) {
                    // eslint-disable-next-line standard/object-curly-even-spacing
                    data.push({...x, ppr: el.ppr })
                }
            }

            try {
                const response = await fetch(
                    "/api-py/save-cost-form/" + this.planPeriod.year,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json;charset=utf-8",
                        },
                        body: JSON.stringify(data),
                    }
                );
                const result = await response.json();

                if (response.status === 200 && result.result === "success") {
                    await makeToast(
                        this,
                        "success",
                        "Сообщение",
                        "Данные сохранены"
                    );
                    await this.rememberDatas();
                    await this.updateDatas();
                }
            } catch {
                makeToast(
                    this,
                    "danger",
                    "Предупреждение",
                    "Ошибка сохранения данных"
                );
            }
        },

        toNum(value, fix = 1) {
            if (typeof value === "string") {
                return parseFloat(parseFloat(value).toFixed(fix));
            }
            if (typeof value === "number") {
                return parseFloat(value.toFixed(fix));
            }
            if (typeof value === "boolean") {
                return value === true ? 1 : 0;
            }
            if (
                value === "undefined" ||
                isNaN(value) ||
                isFinite(value) ||
                value === null
            ) {
                return 0;
            }
        },

        // итого по заданному полю
        total(field) {
            let sum = 0;

            for (const row of this.budgetTable) {
                if (row.type === 4) {
                    sum += this.number(row[field]);
                }
            }
            return parseFloat(sum.toFixed(1));
        },

        // расставляет сохраненные данные по полям
        async updateDatas() {
            this.budgetTable = [];
            try {
                this.loading = true;
                this.pprMap.clear();
                await this.loadDictTree();
                if (this.selectedAbp) {
                    await this.prepareForm();
                    await this.rememberDatas();

                    for (const item of this.dictTree) {
                        if (item.abp === this.selectedAbp.abp) {
                            this.selectedAbp = item.value;
                            break;
                        }
                    }
                }
                this.changeFields();
                this.chgData();
            } finally {
                this.loading = false;
                this.budgetTable = this.budgetForm;
                if (this.firstVariant) {
                    makeToast(
                        this,
                        "warning",
                        "Внимание",
                        `Первая Прогнозная версия редактируется в режиме Лимиты`
                    );
                }
            }
        },

        updateIndex(data, item) {
            const that = this;
            this.$set(data, "edited", false);
            this.budgetForm.splice(data.index + 1, 0, item);
            this.calcFlds.forEach((field) => {
                this.$set(item, field, 0);
            });

            let ind = 0;
            for (const row of this.budgetForm) {
                this.$set(row, "index", ind++);
            }
            if (data.editable) {
                this.calcFlds.forEach((field) => {
                    Object.defineProperty(data, field, {
                        get: function () {
                            return that.reSum(data, field);
                        },
                    });
                });
            }
            this.$set(data, "editable", false);
            this.$set(data, "hasChild", true);
        },

        updateVersion() {
            if (
                this.selVersion === null ||
                !this.filterVersion.includes(this.selVersion)
            ) {
                for (const ver of this.filterVersion) {
                    if (ver.attribute) {
                        this.selVersion = ver;
                        break;
                    }
                }
                if (!this.filterVersion.includes(this.selVersion)) {
                    this.selVersion = this.filterVersion[0];
                }
            }
        },
    },
};
</script>
