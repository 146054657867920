





































































































import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import {Component, Vue, Prop} from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import store from '@/services/store';
import {makeToast, sortByField, findItem, IAbp, IGu} from './bip-types';

@Component({
  name: 'c-budget-req-head',
  components: {
    'multiselect': Multiselect
  }
})
export default class CBudgHeadReq extends Vue {
  @Prop({
    required: false,
    default: true
  })
  private backList: boolean | undefined;

  @Prop({
    required: false,
    default: true
  })
  private showForm: boolean | undefined;

  @Prop({
    required: true,
    default: true
  })
  private reading: boolean | undefined;

  @Prop({
    required: false,
    default: true
  })
  private typeReq!: boolean;

  @Prop({
    required: false,
    default: null
  })
  private form!: any | null;

  @Prop({
    required: true,
    default: '450101'
  })
  private region: string | undefined;

  public isSetValueState = false;
  private levels: any = [];
  private regLevels: any = [];
  private finalAbp: number = 1;
  private k: number = 0;

  public async created() {
    this.$watch('form', () => {
      this.setValues(this.form.header);
    });
    this.$watch('finalAbp', (value) => {
      this.msgRegional();
      if (value === 1 && this.selGu !== null) {
        this.changeHeader();
      }
      if (value === 0 && this.regGu !== null) {
        this.changeHeader();
      }
    });
    this.$watch('selGu', (value) => {
      if (this.finalAbp === 1 && value !== null) {
        this.changeHeader();
      }
    });
    this.$watch('regGu', (value) => {
      if (this.finalAbp === 0 && value !== null) {
        this.changeHeader();
      }
    });
  }

  public async mounted() {
    await this.loadBudgetLevel();
    if (this.form) {
      await this.setValues(this.form.header);
    }
  }

  private get userUiid() {
    if (store.state.user.sub) {
      return store.state.user.sub;
    }
    return null;
  } // get global user_id

  // ------------------ user's budget_level ------------------
  private budgetLevel: number[] = [];

  private async loadBudgetLevel() {
    if (!this.userUiid) {
      return;
    }
    const userUiid = await this.userUiid;
    try {
      const result = await fetch(encodeURI('/api-py/get-user-budget-level/' + userUiid))
          .then(response => response.json());
      for (const el of result) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        const indx = el.budget_level.lastIndexOf('_');
        if (indx >= 0) {
          this.budgetLevel.push(parseInt(el.budget_level.substr(indx + 1)));
        }
      }
      this.levels = [];
      this.regLevels = [];
      if (this.budgetLevel.includes(2)) {
        this.levels.push(2);
        this.regLevels.push(3);
      }
      if (this.budgetLevel.includes(3)) {
        this.levels.push(2);
        this.levels.push(3)
        this.regLevels.push(3);
        this.regLevels.push(4);
      }
      if (this.budgetLevel.includes(4)) {
        this.levels.push(4);
        this.levels.push(3);
        this.regLevels.push(4);
      }
      await this.loadAbp();
      await this.loadRegAbp();
    } catch (error) {
      makeToast(this, 'danger', 'Ошибка загрузки уровня бюджета', error.toString());
      return;
    }
    if (this.budgetLevel.length === 0) {
      makeToast(this, 'warning', 'Внимание!', 'Нет доступных уровней бюджета у пользователя!');
    }
  }

  // ------------------ администратор программ ------------------
  private selAbp: IAbp | null = null;
  private abpBase: any[] = [];

  private async loadAbp() {
    try {
      if (this.levels.length > 0) {
        let response: any = [];
        response = await fetch('/api-py/actual-abp-by-level/' + this.levels);
        response = await response.json();
        if (response) {
          response.sort(sortByField('abp'));
          this.abpBase = [];
          for (const el of response) {
            this.abpBase.push(this.setNameLang(el, 'abp'));
          }
        }
        console.log(this.k++, ' = load ABP = ', this.abpBase.length);
      } else {
        makeToast(this, 'danger', 'Отсутствуют уровни доступа по Инициатору', '');
      }
    } catch (error) {
      makeToast(this, 'danger', 'Ошибка загрузки адм. программ', error.toString());
    }
  }

  private get abpList(): any[] {
    return (this.regAbp === null ? this.abpBase :
        this.abpBase.filter(row => (this.regAbp !== null && row.abp !== this.regAbp.abp)));
  }

  private async chgAbp() {
    console.log(this.k++, ' = chg ABP');
    if (this.selAbp !== null) {
      this.selGu = null;
      await this.loadGu();
    }
  }

  // --------- гос учреждение ------------
  private selGu: IGu | null = null
  private guBase: any[] = [];

  private async loadGu() {
    if (this.selAbp) {
      try {
        const result = await fetch(`/api-py/dict-gu-by-reg-abp/${this.region}/${this.selAbp.abp}`)
            .then(response => response.json());
        if (result) {
          this.guBase = [];
          for (const el of result) {
            this.guBase.push(this.setNameLang(el, 'code'));
          }
        }
        console.log(this.k++, ' = load GU = ', this.guBase.length);
      } catch (error) {
        makeToast(this, 'danger', 'Ошибка загрузки гос. учреждений', error.toString());
      }
    }
  }

  private get guList(): any[] {
    if (this.selAbp === null) {
      return [];
    } else {
      this.guBase.sort((a, b) => (a.code > b.code) ? 1 : -1);
      return this.guBase;
    }
  }

  // ------------------ рег.администратор программ --------
  private regAbpBase: any[] = [];
  private regAbp: IAbp | null = null;

  private async loadRegAbp() {
    try {
      if (this.regLevels.length > 0) {
        let response: any = [];
        response = await fetch('/api-py/actual-abp-by-level/' + this.regLevels);
        response = await response.json();
        if (response) {
          await response.sort(sortByField('abp'));
          this.regAbpBase = [];
          for (const el of response) {
            this.regAbpBase.push(this.setNameLang(el, 'abp'));
          }
        }
        console.log(this.k++, ' = load regABP = ', this.regAbpBase.length);
      } else {
        makeToast(this, 'danger', 'Отсутствуют уровни доступа по Получателю', '');
      }
    } catch (error) {
      makeToast(this, 'danger', 'Ошибка загрузки адм. программ', error.toString());
    }
  }

  private get regAbpList(): any[] {
    let result = [];
    if (this.selAbp) {
      result = this.regAbpBase.filter(row => (this.selAbp !== null && row.abp !== this.selAbp.abp));
      if (this.selAbp.budget_level_id === 2) {
        result = result.filter(row => row.budget_level_id === 3);
      }
      if (this.selAbp.budget_level_id === 3) {
        result = result.filter(row => row.budget_level_id === 4);
      }
    }
    return result;
  }

  private chgRegAbp() {
    console.log(this.k++, ' = chg regABP');
    if (this.regAbp !== null) {
      this.regGu = null;
      this.loadRegGu();
    }
  }

  // --------- рег.гос учреждение ------------
  private regGu: IGu | null = null;
  private regGuBase: any[] = [];

  private async loadRegGu() {
    this.regGu = null;
    if (this.regAbp) {
      try {
        const result = await fetch(`/api-py/dict-gu-by-reg-abp/${this.region}/${this.regAbp.abp}`)
            .then(response => response.json());
        if (result) {
          this.regGuBase = [];
          for (const el of result) {
            this.regGuBase.push(this.setNameLang(el, 'code'));
          }
        }
        console.log(this.k++, ' = chg regGU = ', this.regGuBase.length);
      } catch (error) {
        makeToast(this, 'danger', 'Ошибка загрузки гос. учреждений', error.toString());
      }
    }
  }

  private get regGuList(): any[] {
    if (this.regAbp === null) {
      return [];
    } else {
      this.regGuBase.sort((a, b) => (a.code > b.code) ? 1 : -1);
      if (this.selGu?.id_budget_type === '03') {
        return this.regGuBase.filter(row => this.selGu
            && row.id_region.substr(0, 4) === this.selGu.id_region.toString().substr(0, 4));
      }
      return this.regGuBase;
    }
  }

  private changeHeader() {
    if (this.finalAbp === 1) {
      this.isSetValueState = (this.selAbp !== null && this.selGu !== null);
    } else {
      this.isSetValueState = (this.selAbp !== null && this.selGu !== null
          && this.regAbp !== null && this.regGu !== null);
    }
    const data = {
      selAbp: this.selAbp,
      selGu: this.selGu,
      finalAbp: this.finalAbp,
      regAbp: this.regAbp,
      regGu: this.regGu
    }
    this.$emit('changeHeader', data);
  }

  private msgRegional() {
    if (this.isSetValueState && this.finalAbp === 1) {
      if (this.regAbp && this.regGu) {
        this.$bvModal.msgBoxConfirm(
            'Данные АБП получателя удалятся. Сменить признак Конечный АБП на "Да"?',
            {
              title: 'Подтверждение',
              size: 'lg',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: 'ДА',
              cancelTitle: 'Отмена',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true
            })
            .then(value => {
              if (value) {
                this.regAbp = null;
                this.regGu = null;
              } else {
                this.finalAbp = 0;
              }
              this.changeHeader();
            })
            .catch(error => {
              makeToast(this, 'danger', 'Ошибка сохранения', error.toString());
            });
      }
    }
  }

  // header -> текущие значения
  public async setValues(data: any) {
    console.log('setValues', data);
    this.isSetValueState = false;
    this.selAbp = null;
    this.selGu = null;
    this.regAbp = null;
    this.regGu = null;
    if (data) {
      this.$set(this, "finalAbp", data.finalAbp);
      if (data.abp) {
        this.selAbp = await findItem(data?.abp, 'abp', this.abpList);
        await this.chgAbp();
      }
      if (data.gu) {
        this.selGu = data.gu;
      }
      if (data.regional && data.regional.abp) {
        this.regAbp = findItem(data?.regional.abp, 'abp', this.regAbpList);
        await this.chgRegAbp();
        if (data?.regional.gu) {
          this.regGu = data?.regional.gu;
        }
      }
      this.changeHeader();
    }
  }

  private setNameLang(obj: any, codeName?: any | null): any {
    let txt = 'Empty name';
    if (obj) {
      txt = obj['name_' + this.$i18n.locale];
      if (txt === undefined) {
        txt = obj.name_ru;
      }
      if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
        txt = obj[codeName] + ' - ' + txt;
      }
    }
    const el = Object.assign({}, obj);
    el.name = txt;
    return el;
  }
}
